
import { defineComponent, ref } from 'vue';
import TelaPadraoCrud from '@/core/components/TelaPadraoCrud.vue';
import { useCrudBase } from '@/core/composables/CrudBase';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import ServidorEmailNotificacaoModal from './ServidorEmailNotificacaoModal.vue';
import ServicoServidorEmailNotificacao from '@/servicos/MeuSistema/ServicoServidorEmailNotificacao';

export default defineComponent({
  name: 'ServidorEmailNotificacao',
  components: {
    TelaPadraoCrud,
    ServidorEmailNotificacaoModal,
  },
  setup() {
    const {
      crudBase, preencherEstrutura, exibirModal, sincronizaTelaCrud, preencherCamposOrdenacao,
    } = useCrudBase();
    const servicoServidorEmailNotificacao = new ServicoServidorEmailNotificacao();
    const refCrud = ref<InstanceType<typeof TelaPadraoCrud>>();

    crudBase.estrutura = preencherEstrutura();
    crudBase.estrutura.apresentarEmpresas = true;
    crudBase.estrutura.verificarCadastroCompartilhado = true;
    crudBase.estrutura.botaoPrincipalAcao = 'Novo';
    crudBase.estrutura.mensagemExclusao = 'Você confirma a exclusão do servidor:';
    crudBase.estrutura.colunasGrade = [
      {
        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 0, visible: false, ordering: true,
      },
      {
        title: 'Descrição', dataIndex: 'descricao', key: 'DescricaoMarca', position: 1, visible: true, ordering: true, fixed: 'left', align: 'left', customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Servidor', dataIndex: 'servidor', key: 'Servidor', position: 2, visible: true, align: 'left', width: 300,
      },
      {
        title: 'E-mail', dataIndex: 'email', key: 'Email', position: 2, visible: true, align: 'left', width: 300,
      },
      {
        title: 'Ações', key: 'acoes', position: 3, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
      },
    ];
    crudBase.estrutura.ordenacaoPadraoGrade = preencherCamposOrdenacao();

    async function sincronizarRegistro(acao: EPermissaoDados, codigoRegistro: number) {
      if (refCrud.value !== undefined) {
        await refCrud.value.sincronizarRegistro(acao, codigoRegistro);
      }
    }

    return {
      crudBase,
      servicoServidorEmailNotificacao,
      refCrud,
      exibirModal,
      sincronizaTelaCrud,
      sincronizarRegistro,
    };
  },
});
