
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import ServicoServidorEmailNotificacao from '@/servicos/MeuSistema/ServicoServidorEmailNotificacao';
import { IServidorEmailNotificacao } from '@/models/Entidades/MeuSistema/Notificacoes/IServidorEmailNotificacao';
import storeSistema from '@/store/storeSistema';
import SelecionarUtilizacaoEmail from '@/components/MeuSistema/SelecionarUtilizacaoEmail.vue';
import { EUtilizacaoServidorEmailNotificacao } from '@/models/Enumeradores/MeuSistema/Notificacoes/EUtilizacaoServidorEmailNotificacao';

export default defineComponent({
  name: 'ServidorEmailNotificacaoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    Icone,
    RequisicaoModal,
    SelecionarEmpresa,
    SelecionarUtilizacaoEmail,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes,
      preencherPermissoesDados, preencherEmpresasComEstrategiaPermissaoDados, apresentarMensagemSucesso, defineEmpresasSelecionadasCadastroCompartilhado,
      filtrarPermissaoDadosUsuarioMultiEmpresas, verificaConceitoParaApresentarEmpresas,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
      defineNovaOperacao, defineTextoPadraoBotoes, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoServidorEmailNotificacao = new ServicoServidorEmailNotificacao();
    telaBase.identificadorRecurso = 'CADASTRO_SERVIDORES_EMAIL_NOTIFICACAO';
    telaBase.identificadorPermissao = 'PER_CADASTRO_SERVIDORES_EMAIL_NOTIFICACAO';

    const state = reactive({
      servidor: {} as IServidorEmailNotificacao,
    });

    function limparTela() {
      state.servidor = {} as IServidorEmailNotificacao;
      state.servidor.codigo = 0;
      state.servidor.codigoEmpresa = storeSistema.getters.codigoEmpresaOperacao();
      state.servidor.utilizacao = EUtilizacaoServidorEmailNotificacao.NotificacoesGerais;
      state.servidor.ssl = true;
      telaBase.empresasSelecionadas = [state.servidor.codigoEmpresa];
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      apresentarBarraProgresso();
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoServidorEmailNotificacao.incluir(state.servidor);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoServidorEmailNotificacao.alterar(state.servidor);
      }

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          limparTela();
          defineNovaOperacao(props.operacao);
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);

        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados);
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          state.servidor = await servicoServidorEmailNotificacao.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
          telaBase.empresasSelecionadas.push(state.servidor.codigoEmpresa);
        } else {
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
        }

        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        verificaConceitoParaApresentarEmpresas();
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      EPermissaoDados,
      salvar,
    };
  },
});
