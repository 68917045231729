import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_widget_usuario = _resolveComponent("widget-usuario")!
  const _component_widgets_usuario_linha = _resolveComponent("widgets-usuario-linha")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ((_ctx.state.qtdWidgets === 1 && _ctx.props.widgets[_ctx.obterIndiceWidget()].linha == 0) || _ctx.state.qtdWidgets === 0)
      ? (_openBlock(), _createBlock(_component_widget_usuario, {
          key: 0,
          coluna: _ctx.props.coluna,
          linha: 0,
          subcoluna: 0,
          sublinha: 0,
          widgets: _ctx.props.widgets
        }, null, 8, ["coluna", "widgets"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_widgets_usuario_linha, {
            widgets: _ctx.props.widgets,
            linha: 1,
            coluna: _ctx.props.coluna,
            style: {"height":"50%"}
          }, null, 8, ["widgets", "coluna"]),
          _createVNode(_component_widgets_usuario_linha, {
            widgets: _ctx.props.widgets,
            linha: 2,
            coluna: _ctx.props.coluna,
            style: {"height":"50%"}
          }, null, 8, ["widgets", "coluna"])
        ], 64))
  ]))
}