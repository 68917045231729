import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-6 ant-col-xl-12" }
const _hoisted_3 = { class: "ant-col ant-col-xs-6 ant-col-xl-12" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "ant-row" }
const _hoisted_6 = { class: "ant-col ant-col-xs-12 ant-col-xl-12" }
const _hoisted_7 = { class: "ant-col ant-col-xs-6 ant-col-xl-6" }
const _hoisted_8 = { class: "ant-col ant-col-xs-6 ant-col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_selecionar_tipo_plano_assinatura = _resolveComponent("selecionar-tipo-plano-assinatura")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_nivel_atendimento_assinatura = _resolveComponent("selecionar-nivel-atendimento-assinatura")!
  const _component_selecionar_data = _resolveComponent("selecionar-data")!
  const _component_selecionar_status_assinatura = _resolveComponent("selecionar-status-assinatura")!
  const _component_card = _resolveComponent("card")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    width: "40%",
    centered: "",
    visible: _ctx.modalBase.computedVisivel,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalBase.computedVisivel) = $event)),
    footer: null
  }, {
    title: _withCtx(() => [
      _createTextVNode("Meu Plano")
    ]),
    closeIcon: _withCtx(() => [
      _createVNode(_component_icone, { nome: "voltar" })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_form, { layout: "vertical" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_skeleton, {
            active: "",
            loading: _ctx.telaBase.carregando,
            paragraph: { rows: 15 }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_card, { titulo: "DADOS PRINCIPAIS" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_a_form_item, {
                        label: "Tipo",
                        class: "ss-margin-campos"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_selecionar_tipo_plano_assinatura, {
                            valor: _ctx.state.planoAssinatura.tipo,
                            disabled: true
                          }, null, 8, ["valor"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_a_form_item, {
                        label: "Nome",
                        class: "ss-margin-campos"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("input", {
                            type: "text",
                            class: "ant-input",
                            value: _ctx.state.planoAssinatura.nome,
                            disabled: true
                          }, null, 8, _hoisted_4)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_a_form_item, {
                        label: "Nível de Atendimento",
                        class: "ss-margin-campos"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_selecionar_nivel_atendimento_assinatura, {
                            valor: _ctx.state.planoAssinatura.nivelAtendimento,
                            disabled: true
                          }, null, 8, ["valor"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_a_form_item, {
                        label: "Vencimento",
                        class: "ss-margin-campos"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_selecionar_data, {
                            dataSelecionada: _ctx.state.planoAssinatura.dataVencimento,
                            disabled: true
                          }, null, 8, ["dataSelecionada"])
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_a_form_item, {
                        label: "Status",
                        class: "ss-margin-campos"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_selecionar_status_assinatura, {
                            valor: _ctx.state.planoAssinatura.status,
                            disabled: true
                          }, null, 8, ["valor"])
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_a_tabs, { defaultActiveKey: "1" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_tab_pane, {
                    key: "1",
                    tab: "MÓDULOS CONTRATADOS"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_table, {
                            columns: _ctx.state.colunasModulos,
                            "data-source": _ctx.state.planoAssinatura.modulos,
                            pagination: false,
                            scroll: { y: 100},
                            size: "small"
                          }, null, 8, ["columns", "data-source"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_tab_pane, {
                    key: "2",
                    tab: "LIMITES"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_card, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_table, {
                            columns: _ctx.state.colunasLimites,
                            "data-source": _ctx.state.planoAssinatura.limites,
                            pagination: false,
                            scroll: { y: 100},
                            size: "small"
                          }, null, 8, ["columns", "data-source"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["loading"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible"]))
}