
import { defineComponent, reactive, watch } from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoControleAutorizacoes from '@/servicos/MeuSistema/ServicoControleAutorizacoes';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { EStatusRequisicaoAutorizacao } from '@/models/Enumeradores/MeuSistema/EStatusRequisicaoAutorizacao';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import Icone from '@/core/components/Icone.vue';

export default defineComponent({
  name: 'RequisicaoAutorizacaoRespostaModal',
  props: {
    visivel: {
      type: Boolean,
    },
    codigos: {
      type: Array as () => number[],
      required: true,
    },
  },
  components: {
    Icone,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemErro } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const servicoControleAutorizacoes = new ServicoControleAutorizacoes();
    telaBase.identificadorRecurso = 'CONTROLE_AUTORIZACOES';
    telaBase.identificadorPermissao = 'AUT_CONTROLE_AUTORIZACOES';

    const state = reactive({
      resposta: '',
      autorizando: false,
      negando: false,
    });

    function cancelar() {
      if (state.autorizando || state.negando) {
        return;
      }
      modalBase.computedVisivel = false;
    }

    async function autorizar() {
      if (state.autorizando || state.negando) {
        return;
      }
      state.autorizando = true;
      const retorno = await servicoControleAutorizacoes.responderRequisicoes(props.codigos, EStatusRequisicaoAutorizacao.Autorizada, state.resposta);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        emit('confirmacao');
        modalBase.computedVisivel = false;
      } else {
        apresentarMensagemErro(retorno.mensagem);
      }
      state.autorizando = false;
    }

    async function negar() {
      if (state.autorizando || state.negando) {
        return;
      }
      state.negando = true;
      const retorno = await servicoControleAutorizacoes.responderRequisicoes(props.codigos, EStatusRequisicaoAutorizacao.Negada, state.resposta);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        emit('confirmacao');
        modalBase.computedVisivel = false;
      } else {
        apresentarMensagemErro(retorno.mensagem);
      }
      state.negando = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      if (modalBase.computedVisivel) {
        state.resposta = '';
        state.autorizando = false;
        state.negando = false;
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      UtilitarioData,
      EStatusRequisicaoAutorizacao,
      servicoControleAutorizacoes,
      cancelar,
      autorizar,
      negar,
    };
  },
});
