export enum ETipoControleNumeracao {
    SolcitacoesCompras = 1,
    Cotacoes = 2,
    PedidosCompras = 3,
    Recebimentos = 4,
    DevolucoesCompras = 5,
    Vendas = 6,
    DevolucoesVendas = 7,
    NumeroNFe = 8,
    NumeroNFCe = 9,
    NumeroMDFe = 10,
    NumeroNFSe = 11,
    Produtos = 12,
}
