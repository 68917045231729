
import {
  defineComponent, onBeforeMount, reactive, watch,
} from 'vue';
import { useRoute } from 'vue-router';
import Icone from '@/core/components/Icone.vue';
import EmpresaDadosCadastraisModal from '@/views/MeuSistema/Empresas/EmpresaDadosCadastraisModal.vue';
import AdicionarEmpresaGrupoModal from '@/views/MeuSistema/Empresas/AdicionarEmpresaGrupoModal.vue';
import ServicoEmpresa from '@/servicos/MeuSistema/ServicoEmpresa';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { IEmpresa } from '@/models/Entidades/MeuSistema/Empresas/IEmpresa';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import ConfiguracaoDocumentosFiscaisEletronicosModal from '../ConfiguracaoDocumentosFiscaisEletronicos/ConfiguracaoDocumentosFiscaisEletronicosModal.vue';
import PlanoAssinaturaEmpresaModal from './PlanoAssinaturaEmpresaModal.vue';
import ServicoConfiguracaoDocumentoFiscalEletronico from '@/servicos/MeuSistema/ServicoConfiguracaoDocumentoFiscalEletronico';
import { IDTOEmpresaLimiteDocumentoFiscalEletronico } from '@/models/DTO/MeuSistema/IDTOEmpresaLimiteDocumentoFiscalEletronico';
import { IGrupoEmpresa } from '@/models/Entidades/MeuSistema/GrupoEmpresas/IGrupoEmpresa';
import storeSistema from '@/store/storeSistema';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { useTelaBase } from '@/core/composables/TelaBase';
import CampoCpfCnpj from '@/core/components/Tela/CampoCpfCnpj.vue';

export default defineComponent({
  name: 'Empresa',
  components: {
    EmpresaDadosCadastraisModal,
    AdicionarEmpresaGrupoModal,
    ConfiguracaoDocumentosFiscaisEletronicosModal,
    PlanoAssinaturaEmpresaModal,
    Icone,
    CampoCpfCnpj,
  },
  setup() {
    const route = useRoute();
    const servicoEmpresa = new ServicoEmpresa();
    const servicoConfiguracaoDocumentoFiscalEletronico = new ServicoConfiguracaoDocumentoFiscalEletronico();

    const { apresentarMensagemSucesso, apresentarMensagemAlerta } = useTelaBase();
    let debounce = 0;
    const state = reactive({
      buscandoDados: true,
      grupoEmpresa: {} as IGrupoEmpresa,
      apresentarModalGrupoEmpresa: false,
      editandoNomeGrupo: false,
      atualizandoNomeGrupo: false,
      digitandoConviteMultiEmpresas: false,
      enviandoConviteMultiEmpresas: false,
      nomeGrupoAtual: '',
      apresentarModalAdicionarEmpresa: false,
      exibirEditarDadosCadastrais: false,
      exibirModalCondiguracoesDocumentoFiscalEletronico: false,
      exibirPlanoAssinatura: false,
      editarDadosCadastrais: {} as ITelaOperacao,
      listaEmpresas: [] as IEmpresa[],
      empresaSelecionada: {} as IEmpresa,
      limitesDocumentosFiscaisEmpresa: {} as IDTOEmpresaLimiteDocumentoFiscalEletronico,
      limitesDocumentosFiscaisEletronicos: [] as IDTOEmpresaLimiteDocumentoFiscalEletronico[],
      tokenGrupoMultiEmpresas: '',
      cpfCnjConviteMultiEmpresas: '',
    });

    async function buscarEmpresas() {
      state.buscandoDados = true;

      state.apresentarModalGrupoEmpresa = false;
      state.editandoNomeGrupo = false;
      state.digitandoConviteMultiEmpresas = false;
      state.nomeGrupoAtual = '';
      state.grupoEmpresa = await servicoEmpresa.obterGrupoEmpresa();
      if (!UtilitarioGeral.objetoValido(state.grupoEmpresa) && !UtilitarioGeral.validaCodigo(state.grupoEmpresa.codigo)) {
        state.grupoEmpresa = {} as IGrupoEmpresa;
        state.grupoEmpresa.codigo = 0;
      }
      state.limitesDocumentosFiscaisEletronicos = await servicoConfiguracaoDocumentoFiscalEletronico.obterEmpresasLimitesDocumentosFiscaisEletronicos();
      state.listaEmpresas = await servicoEmpresa.obterEmpresas();
      state.buscandoDados = false;
    }

    function abrirPlanoAssinatura() {
      state.exibirPlanoAssinatura = true;
    }

    function editarConfiguracoes(codigoEmpresa: number) {
      const empresa = state.listaEmpresas.find((c) => c.codigo === codigoEmpresa);
      if (empresa !== undefined) {
        state.empresaSelecionada = empresa;
      }

      const empresaLimiteDocumentoFiscalEletronico = state.limitesDocumentosFiscaisEletronicos.find((c) => c.codigoEmpresa === codigoEmpresa);
      if (empresaLimiteDocumentoFiscalEletronico !== undefined) {
        state.limitesDocumentosFiscaisEmpresa = empresaLimiteDocumentoFiscalEletronico;
      }

      state.exibirModalCondiguracoesDocumentoFiscalEletronico = true;
    }
    function editarEmpresa(codigoEmpresa: number) {
      state.editarDadosCadastrais = {} as ITelaOperacao;
      state.editarDadosCadastrais.tipoPermissaoDados = EPermissaoDados.Visualizar;
      state.editarDadosCadastrais.codigoRegistro = codigoEmpresa;
      state.exibirEditarDadosCadastrais = true;
    }

    function apresentarConfiguracoesDocumentosFiscaisEletronicos(codigoEmpresa: number): boolean {
      const empresaLimiteDocumentoFiscalEletronico = state.limitesDocumentosFiscaisEletronicos.find((c) => c.codigoEmpresa === codigoEmpresa);
      if (empresaLimiteDocumentoFiscalEletronico !== undefined) {
        if (empresaLimiteDocumentoFiscalEletronico.limiteQuantidadeNFe > 0 || empresaLimiteDocumentoFiscalEletronico.limiteQuantidadeNFCe > 0 || empresaLimiteDocumentoFiscalEletronico.limiteQuantidadeMDFe > 0) {
          return true;
        }
      }
      return false;
    }

    async function atualizarTokenFiscal() {
      state.limitesDocumentosFiscaisEletronicos = await servicoConfiguracaoDocumentoFiscalEletronico.obterEmpresasLimitesDocumentosFiscaisEletronicos();
    }

    async function criarGrupo() {
      if (!UtilitarioGeral.valorValido(state.grupoEmpresa.nome)) {
        apresentarMensagemAlerta('É necessário informar o nome do grupo!');
        return;
      }
      state.grupoEmpresa.codigo = 0;
      state.grupoEmpresa.codigoEmpresaCriacao = storeSistema.getters.codigoEmpresaOperacao();
      const retorno = await servicoEmpresa.criarGrupoEmpresa(state.grupoEmpresa);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        state.apresentarModalGrupoEmpresa = false;
        await buscarEmpresas();
      } else {
        apresentarMensagemAlerta(retorno.mensagem);
      }
    }

    async function copiarTokenGrupoEmpresa() {
      if (UtilitarioGeral.valorValido(state.grupoEmpresa.token)) {
        // Verifica se a API Clipboard está disponível
        if (navigator.clipboard && navigator.clipboard.writeText) {
          navigator.clipboard.writeText(state.grupoEmpresa.token).then(() => {
            apresentarMensagemSucesso('Token copiado para área de transferência.');
          }).catch((err) => {
            console.error('Erro ao copiar texto: ', err);
          });
        } else {
        // Fallback para navegadores que não suportam a API Clipboard
          const textArea = document.createElement('textarea');
          textArea.value = state.grupoEmpresa.token;
          document.body.appendChild(textArea);
          textArea.select();
          try {
            document.execCommand('copy');
            apresentarMensagemSucesso('Token copiado para área de transferência.');
          } catch (err) {
            console.error('Erro ao copiar texto: ', err);
          }
          document.body.removeChild(textArea);
        }
      } else {
        apresentarMensagemAlerta('Não foi possível obter o Token no momento, tente novamente mais tarde.');
      }
    }

    function iniciarEdicaoNomeGrupo() {
      state.nomeGrupoAtual = state.grupoEmpresa.nome;
      state.editandoNomeGrupo = true;

      clearTimeout(debounce);
      debounce = setTimeout(() => {
        const campoNomeGrupo = document.getElementById('NomeGrupoEmpresa') as HTMLInputElement;
        if (campoNomeGrupo) {
          campoNomeGrupo.focus();
          campoNomeGrupo.select();
        }
      }, 300);
    }

    function cancelarEdicaoNomeGrupo() {
      state.grupoEmpresa.nome = state.nomeGrupoAtual;
      state.editandoNomeGrupo = false;
    }

    async function confirmarEdicaoNomeGrupo() {
      if (!UtilitarioGeral.valorValido(state.grupoEmpresa.nome)) {
        apresentarMensagemAlerta('É necessário informar o nome do grupo!');
        return;
      }
      state.atualizandoNomeGrupo = true;
      const retorno = await servicoEmpresa.atualizarNomeGrupo(storeSistema.getters.codigoEmpresaOperacao(), state.grupoEmpresa.nome, state.grupoEmpresa.codigo);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        state.nomeGrupoAtual = state.grupoEmpresa.nome;
        state.editandoNomeGrupo = false;
      } else {
        apresentarMensagemAlerta(retorno.mensagem);
      }
      state.atualizandoNomeGrupo = false;
    }

    function iniciarConviteMultiEmpresa() {
      state.cpfCnjConviteMultiEmpresas = '';
      state.digitandoConviteMultiEmpresas = true;
    }

    function cancelarConviteMultiEmpresa() {
      state.cpfCnjConviteMultiEmpresas = '';
      state.digitandoConviteMultiEmpresas = false;
    }

    async function enviarConviteMultiEmpresas() {
      if (!UtilitarioGeral.valorValido(state.cpfCnjConviteMultiEmpresas)) {
        apresentarMensagemAlerta('É necessário o CPF/CNPJ da empresa que deseja convidar para o grupo!');
        return;
      }
      state.enviandoConviteMultiEmpresas = true;
      const retorno = await servicoEmpresa.enviarConviteMultiEmpresas(state.cpfCnjConviteMultiEmpresas);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        state.cpfCnjConviteMultiEmpresas = '';
        state.digitandoConviteMultiEmpresas = false;
      } else {
        apresentarMensagemAlerta(retorno.mensagem);
      }
      state.enviandoConviteMultiEmpresas = false;
    }

    function adicionarEmpresaNoGrupo() {
      state.apresentarModalAdicionarEmpresa = true;
    }

    function empresaAdicionadaComSucesso() {
      window.location.reload();
    }

    async function estrategiaParametroRota() {
      if (route.query.tokenMultiEmpresas !== undefined) {
        const tokenMultiEmpresas = String(route.query.tokenMultiEmpresas);
        if (UtilitarioGeral.valorValido(tokenMultiEmpresas)) {
          const grupoEmpresa = await servicoEmpresa.obterGrupoEmpresa();
          if (grupoEmpresa !== undefined) {
            if (UtilitarioGeral.validaCodigo(grupoEmpresa.codigo)) {
              return;
            }
          }
          state.tokenGrupoMultiEmpresas = tokenMultiEmpresas;
          state.apresentarModalAdicionarEmpresa = true;
        }
      }
    }

    // Monitorar mudanças na rota
    watch(() => route.fullPath, () => {
      estrategiaParametroRota();
    });

    onBeforeMount(async () => {
      await buscarEmpresas();
      await estrategiaParametroRota();
    });

    return {
      state,
      editarEmpresa,
      editarConfiguracoes,
      UtilitarioGeral,
      UtilitarioMascara,
      abrirPlanoAssinatura,
      apresentarConfiguracoesDocumentosFiscaisEletronicos,
      atualizarTokenFiscal,
      criarGrupo,
      copiarTokenGrupoEmpresa,
      iniciarEdicaoNomeGrupo,
      confirmarEdicaoNomeGrupo,
      cancelarEdicaoNomeGrupo,
      adicionarEmpresaNoGrupo,
      empresaAdicionadaComSucesso,
      iniciarConviteMultiEmpresa,
      cancelarConviteMultiEmpresa,
      enviarConviteMultiEmpresas,
    };
  },
});
