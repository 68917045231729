import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "ant-row",
  style: {"width":"100%","height":"100%"}
}
const _hoisted_2 = {
  class: "ant-col ant-col-xs-12 ant-col-xl-12",
  style: {"height":"100%"}
}
const _hoisted_3 = { style: {"height":"50%"} }
const _hoisted_4 = { style: {"height":"50%"} }
const _hoisted_5 = {
  class: "ant-col ant-col-xs-12 ant-col-xl-12",
  style: {"height":"100%"}
}
const _hoisted_6 = { style: {"height":"50%"} }
const _hoisted_7 = { style: {"height":"50%"} }
const _hoisted_8 = {
  key: 1,
  class: "ant-col ant-col-xs-24 ant-col-xl-24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_widget_usuario = _resolveComponent("widget-usuario")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.state.qtdWidgets > 0)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.verificarSlotOcupado(1) || _ctx.obterQtdWidgetsSubcoluna(1) == 0)
              ? (_openBlock(), _createBlock(_component_widget_usuario, {
                  key: 0,
                  coluna: _ctx.props.coluna,
                  linha: _ctx.props.linha,
                  subcoluna: 1,
                  sublinha: 0,
                  widgets: _ctx.props.widgets
                }, null, 8, ["coluna", "linha", "widgets"]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_widget_usuario, {
                      coluna: _ctx.props.coluna,
                      linha: _ctx.props.linha,
                      subcoluna: 1,
                      sublinha: 1,
                      widgets: _ctx.props.widgets
                    }, null, 8, ["coluna", "linha", "widgets"])
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_widget_usuario, {
                      coluna: _ctx.props.coluna,
                      linha: _ctx.props.linha,
                      subcoluna: 1,
                      sublinha: 2,
                      widgets: _ctx.props.widgets
                    }, null, 8, ["coluna", "linha", "widgets"])
                  ])
                ], 64))
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.verificarSlotOcupado(2) || _ctx.obterQtdWidgetsSubcoluna(2) == 0)
              ? (_openBlock(), _createBlock(_component_widget_usuario, {
                  key: 0,
                  coluna: _ctx.props.coluna,
                  linha: _ctx.props.linha,
                  subcoluna: 2,
                  sublinha: 0,
                  widgets: _ctx.props.widgets
                }, null, 8, ["coluna", "linha", "widgets"]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_widget_usuario, {
                      coluna: _ctx.props.coluna,
                      linha: _ctx.props.linha,
                      subcoluna: 2,
                      sublinha: 1,
                      widgets: _ctx.props.widgets
                    }, null, 8, ["coluna", "linha", "widgets"])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_widget_usuario, {
                      coluna: _ctx.props.coluna,
                      linha: _ctx.props.linha,
                      subcoluna: 2,
                      sublinha: 2,
                      widgets: _ctx.props.widgets
                    }, null, 8, ["coluna", "linha", "widgets"])
                  ])
                ], 64))
          ])
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_widget_usuario, {
            coluna: _ctx.props.coluna,
            linha: _ctx.props.linha,
            subcoluna: 1,
            sublinha: 0,
            style: {"height":"100%","padding-top":"15px"},
            widgets: _ctx.props.widgets
          }, null, 8, ["coluna", "linha", "widgets"])
        ]))
  ]))
}