import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c367438"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ss-card" }
const _hoisted_2 = {
  key: 0,
  class: "ant-row",
  style: {"height":"100%"}
}
const _hoisted_3 = { class: "ant-col ss-div-icone-novo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_widget = _resolveComponent("widget")!
  const _component_widget_usuario_novo_modal = _resolveComponent("widget-usuario-novo-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "ss-div-principal",
      onMouseenter: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.mouseIn && _ctx.mouseIn(...args))),
      onMouseleave: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.mouseOut && _ctx.mouseOut(...args)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.state.indexWidget === -1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("a", {
                  class: "ss-botao-novo",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.abrirInclusaoWidget && _ctx.abrirInclusaoWidget(...args)))
                }, [
                  _createVNode(_component_icone, {
                    nome: "novo",
                    class: "ss-icone-novo"
                  })
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_ctx.state.hover)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    class: "ss-icone ss-botao-excluir",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.excluirWidget && _ctx.excluirWidget(...args)))
                  }, [
                    _createVNode(_component_icone, { nome: "excluir" })
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(), _createBlock(_component_widget, {
                key: _ctx.computedWidgets[_ctx.state.indexWidget].codigo,
                widget: _ctx.computedWidgets[_ctx.state.indexWidget],
                "onUpdate:widget": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedWidgets[_ctx.state.indexWidget]) = $event))
              }, null, 8, ["widget"]))
            ], 64))
      ])
    ], 32),
    _createVNode(_component_widget_usuario_novo_modal, {
      coluna: _ctx.props.coluna,
      linha: _ctx.props.linha,
      subcoluna: _ctx.props.subcoluna,
      sublinha: _ctx.props.sublinha,
      visivel: _ctx.state.exibirInclusao,
      "onUpdate:visivel": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.exibirInclusao) = $event)),
      onConfirmacao: _ctx.incluirWidget
    }, null, 8, ["coluna", "linha", "subcoluna", "sublinha", "visivel", "onConfirmacao"])
  ], 64))
}