
import { defineComponent, onBeforeMount, reactive } from 'vue';
import { Modal } from 'ant-design-vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import { EVinculoEmpresa } from '@/core/models/Enumeradores/EEVinculoEmpresa';
import storeSistema from '@/store/storeSistema';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { IParametro } from '@/models/Entidades/MeuSistema/Parametros/IParametro';
import ServicoParametros from '@/servicos/MeuSistema/ServicoParametros';
import BuscaAvancada from '@/core/components/BuscaAvancada/BuscaAvancada.vue';
import { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { IGrupoParametros } from '@/models/Entidades/MeuSistema/Parametros/IGrupoParametros';
import { ICategoriaParametros } from '@/models/Entidades/MeuSistema/Parametros/ICategoriaParametros';
import ParametrosCampo from '@/components/MeuSistema/Parametros/ParametrosCampo.vue';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';

export default defineComponent({
  name: 'ParametrosIndex',
  components: {
    Card,
    Icone,
    BuscaAvancada,
    ParametrosCampo,
  },
  setup(props) {
    const servicoParametros = new ServicoParametros();

    const {
      telaBase, preencherEmpresasDisponiveis, adicionarAtalho, removerAtalho, obterPermissoes, defineEmpresasSelecionadasComPermissao, apresentarMensagemAlerta, apresentarMensagemErro,
    } = useTelaBase();

    const state = reactive({
      identificadorRecurso: 'CONTROLE_PARAMETROS',
      identificadorPermissao: 'AUT_CONTROLE_PARAMETROS',
      empresaSelecionada: 0,
      tamanhoMobile: false,
      grupos: [] as IGrupoParametros[],
      filtros: [] as IFiltroGenerico[],
      buscaAvancada: {} as IBuscaAvancada,
    });

    function criarGrupo(parametro: IParametro) {
      const grupo = {} as IGrupoParametros;
      grupo.codigo = parametro.codigoGrupoParametros;
      grupo.descricao = parametro.descricaoGrupoParametros;
      grupo.categorias = [] as ICategoriaParametros[];
      state.grupos.push(grupo);
      return state.grupos.length - 1;
    }

    function criarCategoria(indexGrupo: number, parametro: IParametro) {
      const categoria = {} as ICategoriaParametros;
      categoria.codigo = parametro.codigoCategoriaParametros;
      categoria.descricao = parametro.descricaoCategoriaParametros;
      categoria.parametros = [] as IParametro[];
      state.grupos[indexGrupo].categorias.push(categoria);
      return state.grupos[indexGrupo].categorias.length - 1;
    }

    function criarParametro(parametro: IParametro) {
      let indexGrupo = state.grupos.findIndex((g) => g.codigo === parametro.codigoGrupoParametros);
      if (indexGrupo === -1) {
        indexGrupo = criarGrupo(parametro);
      }
      let indexCategoria = state.grupos[indexGrupo].categorias.findIndex((c) => c.codigo === parametro.codigoCategoriaParametros);
      if (indexCategoria === -1) {
        indexCategoria = criarCategoria(indexGrupo, parametro);
      }
      state.grupos[indexGrupo].categorias[indexCategoria].parametros.push(parametro);
    }

    async function buscarParametros() {
      state.filtros = [] as IFiltroGenerico[];
      state.buscaAvancada.filtrosAdicionados.forEach((item) => {
        state.filtros.push(item.filtro);
      });
      const parametrosConsulta = {} as IParametrosConsulta;
      parametrosConsulta.empresas = [state.empresaSelecionada];
      parametrosConsulta.ordenacao = Array<string>();
      parametrosConsulta.ordenacao.push('CodigoGrupo', 'CodigoCategoria', 'Ordem');
      parametrosConsulta.qtdeRegistrosPagina = 1000;
      telaBase.carregando = true;
      const listaPaginada = await servicoParametros.buscaAvancada(parametrosConsulta, state.filtros);
      let parametros = [] as IParametro[];
      parametros = listaPaginada.dados;
      state.grupos = [] as IGrupoParametros[];
      parametros.forEach((p) => {
        criarParametro(p);
      });
      telaBase.carregando = false;
    }

    async function salvar() {
      const parametros = [] as IParametro[];

      state.grupos.forEach((g) => {
        g.categorias.forEach((c) => {
          c.parametros.forEach((p) => {
            parametros.push(p);
          });
        });
      });
      const retorno = await servicoParametros.atualizarValores(state.empresaSelecionada, parametros);
      if (retorno.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retorno.mensagem);
      } else if (retorno.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retorno.mensagem);
      }
    }

    async function confirmarSalvar() {
      Modal.confirm({
        title: 'Salvar alterações',
        content: 'Realmente deseja salvar as alterações dos parâmetros?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          await salvar();
        },
      });
    }

    onBeforeMount(async () => {
      state.tamanhoMobile = UtilitarioDispositivo.larguraTelaMobile();
      state.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      telaBase.identificadorRecurso = state.identificadorRecurso;
      telaBase.identificadorPermissao = state.identificadorPermissao;
      await obterPermissoes(ETipoPermissao.Autorizacoes);
      await preencherEmpresasDisponiveis();
      await defineEmpresasSelecionadasComPermissao();
      telaBase.propriedadesConsulta = await servicoParametros.obterPropriedadesConsulta();
      if (telaBase.propriedadesConsulta.length > 0) {
        state.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
        state.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
      }
      state.buscaAvancada.filtrosAdicionados = [];
      state.buscaAvancada.chaveFiltrosAdicionados = 0;
      buscarParametros();
    });

    return {
      state,
      props,
      window,
      EVinculoEmpresa,
      UtilitarioMascara,
      UtilitarioData,
      storeSistema,
      telaBase,
      adicionarAtalho,
      removerAtalho,
      buscarParametros,
      confirmarSalvar,
    };
  },
});
