
import {
  computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { IWidgetUsuario } from '@/models/Entidades/MeuSistema/IWidget';
import { IDTOWidgetUsuario } from '@/models/DTO/MeuSistema/Usuarios/IDTOWidgetUsuario';
import ServicoMinhaDashboard from '@/servicos/MeuSistema/ServicoMinhaDashboard';
import Icone from '@/core/components/Icone.vue';
import WidgetUsuarioNovoModal from './WidgetUsuarioNovoModal.vue';
import Widget from '../Widget.vue';

export default defineComponent({
  name: 'WidgetUsuario',
  components: {
    Widget,
    Icone,
    WidgetUsuarioNovoModal,
  },
  props: {
    widgets: {
      type: Array as () => IDTOWidgetUsuario[],
      required: true,
    },
    coluna: {
      type: Number,
      required: true,
    },
    linha: {
      type: Number,
      required: true,
    },
    subcoluna: {
      type: Number,
      required: true,
    },
    sublinha: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:widgets'],
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();
    const servicoMinhaDashboard = new ServicoMinhaDashboard();

    const state = reactive({
      indexWidget: -1,
      exibirInclusao: false,
      hover: false,
    });

    const computedWidgets = computed({
      get: () => props.widgets,
      set: (val: IDTOWidgetUsuario[]) => {
        emit('update:widgets', val);
      },
    });

    function mouseIn() {
      state.hover = true;
    }

    function mouseOut() {
      state.hover = false;
    }

    function abrirInclusaoWidget() {
      state.exibirInclusao = true;
    }

    async function incluirWidget(widget: IDTOWidgetUsuario) {
      const widgetUsuario = {} as IWidgetUsuario;
      widgetUsuario.codigoWidget = widget.codigo;
      widgetUsuario.codigoEmpresa = widget.codigoEmpresa;
      widgetUsuario.codigoUsuario = widget.codigoUsuario;
      widgetUsuario.codigoWidgetFiltro = widget.codigoWidgetFiltro;
      widgetUsuario.limite = widget.limite;
      widgetUsuario.coluna = widget.coluna;
      widgetUsuario.linha = widget.linha;
      widgetUsuario.subcoluna = widget.subcoluna;
      widgetUsuario.sublinha = widget.sublinha;
      await servicoMinhaDashboard.incluirWidgetUsuario(widgetUsuario);
      computedWidgets.value.push(widget);
    }

    async function excluirWidget() {
      await servicoMinhaDashboard.excluirWidgetUsuario(computedWidgets.value[state.indexWidget].codigo);
      computedWidgets.value.splice(state.indexWidget, 1);
    }

    onBeforeMount(async () => {
      state.indexWidget = props.widgets.findIndex((w) => w.coluna === props.coluna && w.linha === props.linha && w.subcoluna === props.subcoluna && w.sublinha === props.sublinha);
    });

    return {
      state,
      props,
      telaBase,
      computedWidgets,
      mouseIn,
      mouseOut,
      abrirInclusaoWidget,
      incluirWidget,
      excluirWidget,
    };
  },
});
