
import { defineComponent, reactive, watch } from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import Icone from '@/core/components/Icone.vue';

export default defineComponent({
  name: 'MinhasTarefasEditarModal',
  props: {
    visivel: {
      type: Boolean,
    },
    descricao: {
      type: String,
      required: true,
    },
  },
  components: {
    Icone,
  },
  emits: ['confirmacao'],
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);

    const state = reactive({
      descricao: '',
    });

    async function salvar() {
      emit('confirmacao', state.descricao);
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      if (modalBase.computedVisivel) {
        state.descricao = props.descricao;
      }
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      salvar,
    };
  },
});
