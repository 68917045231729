
import { defineComponent, reactive, watch } from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoControleAutorizacoes from '@/servicos/MeuSistema/ServicoControleAutorizacoes';
import { IRequisicaoAutorizacao, IRequisicaoAutorizacaoDestinatario } from '@/models/Entidades/MeuSistema/IRequisicaoAutorizacao';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import Card from '@/core/components/Tela/Card.vue';
import Icone from '@/core/components/Icone.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { EStatusRequisicaoAutorizacao } from '@/models/Enumeradores/MeuSistema/EStatusRequisicaoAutorizacao';
import SelecionarStatusRequisicaoAutorizacao from '@/components/MeuSistema/ControleAutorizacoes/SelecionarStatusRequisicaoAutorizacao.vue';
import ContaPagarModal from '@/views/Financeiro/ContasPagar/ContaPagarModal.vue';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import RequisicaoAutorizacaoRespostaModal from './RequisicaoAutorizacaoRespostaModal.vue';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';

export default defineComponent({
  name: 'RequisicaoAutorizacaoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    requisicao: {
      type: Object as () => IRequisicaoAutorizacao,
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    MensagemSemDados,
    SelecionarStatusRequisicaoAutorizacao,
    ContaPagarModal,
    RequisicaoAutorizacaoRespostaModal,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const servicoControleAutorizacoes = new ServicoControleAutorizacoes();
    telaBase.identificadorRecurso = 'CONTROLE_AUTORIZACOES';
    telaBase.identificadorPermissao = 'AUT_CONTROLE_AUTORIZACOES';

    const state = reactive({
      operacaoLancamento: {} as ITelaOperacao,
      destinatarios: [] as IRequisicaoAutorizacaoDestinatario[],
      exibirLancamento: false,
      exibirResposta: false,
    });

    async function abrirRegistroRelacionado() {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Alterar;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = props.requisicao.codigoRegistroRelacionado;
      state.operacaoLancamento.empresaSelecionada = props.requisicao.codigoEmpresa;
      state.exibirLancamento = true;
    }

    async function abrirResposta() {
      const retorno = await servicoControleAutorizacoes.validarPermissao(props.requisicao.codigo);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        state.exibirResposta = true;
      } else {
        apresentarMensagemAlerta(retorno.mensagem);
      }
    }

    function confirmarResposta() {
      emit('confirmacao');
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      if (modalBase.computedVisivel) {
        state.destinatarios = await servicoControleAutorizacoes.obterDestinatarios(props.requisicao.codigo);
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      UtilitarioGeral,
      UtilitarioData,
      EStatusRequisicaoAutorizacao,
      servicoControleAutorizacoes,
      abrirRegistroRelacionado,
      abrirResposta,
      confirmarResposta,
    };
  },
});
