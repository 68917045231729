
import {
  defineComponent, onMounted, onUpdated, reactive, watch,
} from 'vue';
import { Dayjs } from 'dayjs';
import { useRoute } from 'vue-router';
import Icone from '@/core/components/Icone.vue';
import storeSistema from '@/store/storeSistema';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { useTelaBase } from '@/core/composables/TelaBase';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import AgendamentoModal from '@/views/MeuSistema/Agendamentos/AgendamentoModal.vue';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import ServicoAgendamento from '@/servicos/MeuSistema/ServicoAgendamento';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { EVisualizacaoAgendamento } from '@/models/Enumeradores/MeuSistema/Agendamentos/EVisualizacaoAgendamento';
import SelecionarMarcador from '@/components/MeuSistema/Agendamentos/SelecionarMarcador.vue';
import ResumoAgendamento from '@/components/MeuSistema/Agendamentos/ResumoAgendamento.vue';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import SelecionarUsuario from '@/components/MeuSistema/Usuarios/SelecionarUsuario.vue';
import SelecionarPessoa from '@/components/Cadastros/Pessoas/SelecionarPessoa.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import { IParametrosAgendamento } from '@/models/DTO/MeuSistema/Agendamentos/IParametrosAgendamento';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { IDTOAgendamento } from '@/models/DTO/MeuSistema/Agendamentos/IDTOAgendamento';
import router from '@/router';

export default defineComponent({
  name: 'Agenda',
  components: {
    TituloPadrao,
    Icone,
    AgendamentoModal,
    SelecionarEmpresa,
    SelecionarMarcador,
    SelecionarUsuario,
    SelecionarPessoa,
    SelecionarData,
    ResumoAgendamento,
  },
  setup() {
    const {
      telaBase, montaOpcoesComListaItemGenerico, obterPermissoes,
    } = useTelaBase();

    const servicoAgendamento = new ServicoAgendamento();
    const servicoSistema = new ServicoSistema();
    telaBase.identificadorRecurso = 'AGENDAMENTO';
    telaBase.identificadorPermissao = 'AUT_CRIAR_AGENDAMENTO_TODOS_USUARIOS';

    const stateAgenda = reactive({
      operacao: {} as ITelaOperacao,
      codigoUsuarioAutenticado: 0,
      codigoAgendamentoVisualizacao: 0,
      acessoVariasEmpresas: false,
      exibirModal: false,
      exibirFiltros: false,
      exibirGradeAgendamentos: false,
      colunasGrade: [] as IColumn[],
      listaAgendamentos: [] as IDTOAgendamento[],
      datasSemana: [] as any[],
      dataAtual: {} as Dayjs,
      dataFiltro: '',
      tipoDataFiltro: 1,
      dataInicialFiltro: {} as Dayjs,
      dataFinalFiltro: {} as Dayjs,
      formatoVisualizacao: 1,
      codigoTipoAgendamento: undefined,
      listaTipoAgendamento: [] as IOption[],
      usuariosSelecionados: [] as number[],
      pessoasSelecionadas: [] as number[],
      marcadores: [] as string[],
    });

    const route = useRoute();

    telaBase.empresasSelecionadas = [];
    telaBase.empresasSelecionadas = storeSistema.getters.empresasUsuarioAutenticado();
    stateAgenda.acessoVariasEmpresas = telaBase.empresasSelecionadas.length > 1;
    stateAgenda.codigoUsuarioAutenticado = storeSistema.getters.codigoUsuarioAutenticado();

    stateAgenda.colunasGrade = [
      {
        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 0, visible: false,
      },
      {
        title: 'Data/Hora', dataIndex: 'dataHoraInicio', key: 'DataHora', position: 1, visible: true, ordering: true, fixed: 'left', align: 'left', width: 130,
      },
      {
        title: 'Título', dataIndex: 'titulo', key: 'Titulo', position: 2, visible: true, ordering: true, align: 'left',
      },
    ];

    (async () => {
      const listaTipoAgendamento = await servicoAgendamento.obterListaTipoAgendamentos();
      stateAgenda.listaTipoAgendamento = montaOpcoesComListaItemGenerico(listaTipoAgendamento);

      await obterPermissoes(ETipoPermissao.Autorizacoes);
    })();

    function filtrarAgendamentosPorDia(value:any) {
      let agendamentos = [];
      agendamentos = stateAgenda.listaAgendamentos.filter((c) => UtilitarioData.converterDataJson(c.dataHoraInicio) === UtilitarioData.converterDataJson(value));
      return agendamentos || [];
    }

    function filtrarAgendamentosPorMes(value:any) {
      let agendamentos = [];
      agendamentos = stateAgenda.listaAgendamentos.filter((c) => UtilitarioData.obterMesInteiroData(c.dataHoraInicio) === (value.month() + 1));
      return agendamentos || [];
    }

    function novoAgendamento() {
      stateAgenda.operacao = { tipoPermissaoDados: EPermissaoDados.Incluir, listaPermissoesDados: telaBase.listaPermissoesDados, codigoRegistro: 0 } as ITelaOperacao;
      stateAgenda.exibirModal = true;
    }
    function editarAgendamento(codigoRegistro: number) {
      stateAgenda.operacao = { tipoPermissaoDados: EPermissaoDados.Visualizar, listaPermissoesDados: telaBase.listaPermissoesDados, codigoRegistro } as ITelaOperacao;
      stateAgenda.exibirModal = true;
    }

    function criaParametrosConsultaPadrao() {
      const parametrosAgendamento: IParametrosAgendamento = {} as IParametrosAgendamento;

      if (!UtilitarioGeral.validaLista(telaBase.empresasSelecionadas)) {
        telaBase.empresasSelecionadas = storeSistema.getters.empresasUsuarioAutenticado();
      }

      parametrosAgendamento.empresas = telaBase.empresasSelecionadas;
      parametrosAgendamento.pessoas = stateAgenda.pessoasSelecionadas;
      parametrosAgendamento.usuarios = stateAgenda.usuariosSelecionados;
      parametrosAgendamento.marcadores = stateAgenda.marcadores;
      parametrosAgendamento.tipoAgendamento = stateAgenda.codigoTipoAgendamento;

      return parametrosAgendamento;
    }
    async function buscarAgendamentosCalendario() {
      telaBase.carregando = true;
      stateAgenda.listaAgendamentos = [];
      const parametrosAgendamento = criaParametrosConsultaPadrao();
      parametrosAgendamento.tipoData = 0;
      parametrosAgendamento.dataInicial = UtilitarioData.converterDataHoraMinutoSegundoJson(stateAgenda.dataInicialFiltro);
      parametrosAgendamento.dataFinal = UtilitarioData.converterDataHoraMinutoSegundoJson(stateAgenda.dataFinalFiltro);
      stateAgenda.listaAgendamentos = await servicoAgendamento.obterAgendamentos(parametrosAgendamento);
      telaBase.carregando = false;
    }

    async function buscarAgendamentosFiltros() {
      stateAgenda.listaAgendamentos = [];
      stateAgenda.exibirFiltros = false;
      stateAgenda.exibirGradeAgendamentos = true;
      telaBase.carregando = true;
      const parametrosAgendamento = criaParametrosConsultaPadrao();

      if (UtilitarioGeral.valorValido(stateAgenda.dataFiltro)) {
        parametrosAgendamento.tipoData = stateAgenda.tipoDataFiltro;
        parametrosAgendamento.dataInicial = stateAgenda.dataFiltro;
        parametrosAgendamento.dataFinal = stateAgenda.dataFiltro;
        stateAgenda.formatoVisualizacao = EVisualizacaoAgendamento.Dia;
        stateAgenda.dataAtual = UtilitarioData.converterDataParaDayJs(parametrosAgendamento.dataInicial);
      } else {
        parametrosAgendamento.dataInicial = UtilitarioData.converterDataHoraMinutoSegundoJson(stateAgenda.dataInicialFiltro);
        parametrosAgendamento.dataFinal = UtilitarioData.converterDataHoraMinutoSegundoJson(stateAgenda.dataFinalFiltro);
      }

      stateAgenda.listaAgendamentos = await servicoAgendamento.obterAgendamentos(parametrosAgendamento);
      telaBase.carregando = false;
    }

    const horarioAgendamentos = [
      '06:00',
      '07:00',
      '08:00',
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00',
      '18:00',
      '19:00',
      '20:00',
      '21:00',
      '22:00',
      '23:00',
      '00:00',
      '01:00',
      '02:00',
      '03:00',
      '04:00',
      '05:00',
    ];

    function montaDiaSemana(data: any) {
      stateAgenda.datasSemana = [];
      stateAgenda.datasSemana.push(UtilitarioData.obterDataComDiaSemana(data, 0));
      stateAgenda.datasSemana.push(UtilitarioData.obterDataComDiaSemana(data, 1));
      stateAgenda.datasSemana.push(UtilitarioData.obterDataComDiaSemana(data, 2));
      stateAgenda.datasSemana.push(UtilitarioData.obterDataComDiaSemana(data, 3));
      stateAgenda.datasSemana.push(UtilitarioData.obterDataComDiaSemana(data, 4));
      stateAgenda.datasSemana.push(UtilitarioData.obterDataComDiaSemana(data, 5));
      stateAgenda.datasSemana.push(UtilitarioData.obterDataComDiaSemana(data, 6));
      stateAgenda.dataInicialFiltro = UtilitarioData.obterDataComDiaSemana(data, 0);
      stateAgenda.dataFinalFiltro = UtilitarioData.obterDataComDiaSemana(data, 6);
    }

    function defineExibicaoFiltros() {
      stateAgenda.exibirFiltros = !stateAgenda.exibirFiltros;
    }

    function verificaVisualizacaoTodosUsuarios(): boolean {
      let todasEmpresasAutorizadas = true;
      const listaEmpresasUsuario = storeSistema.getters.empresasUsuarioAutenticado();
      listaEmpresasUsuario.forEach((codigoEmpresa) => {
        const autorizacaoTodosUsuarios = telaBase.listaPermissoes.find((c) => c.identificadorPermissao === telaBase.identificadorRecurso && c.codigoEmpresa === codigoEmpresa);
        if (!UtilitarioGeral.objetoValido(autorizacaoTodosUsuarios)) { todasEmpresasAutorizadas = false; }
      });

      return todasEmpresasAutorizadas;
    }

    function montaTituloApresentacao():string {
      let titulo = '';
      switch (stateAgenda.formatoVisualizacao) {
        case EVisualizacaoAgendamento.Dia:
          titulo = `${UtilitarioData.obterDiaDaSemana(stateAgenda.dataAtual)} ${UtilitarioData.aplicaFormatoData(stateAgenda.dataAtual)}`;
          break;
        case EVisualizacaoAgendamento.Semana:
          titulo = `${UtilitarioData.obterSemanaMes(stateAgenda.dataAtual)} - ${UtilitarioData.obterMesAno(stateAgenda.dataAtual)}`;
          break;
        case EVisualizacaoAgendamento.Mes:
          titulo = UtilitarioData.obterMesAno(stateAgenda.dataAtual);
          break;
        case EVisualizacaoAgendamento.Ano:
          titulo = `Ano: ${UtilitarioData.obterAnoData(stateAgenda.dataAtual)}`;
          break;
        default:
      }

      return titulo;
    }

    async function navegacaoPeriodo(anterior: boolean, proximo: boolean) {
      stateAgenda.dataFiltro = '';

      switch (stateAgenda.formatoVisualizacao) {
        case EVisualizacaoAgendamento.Dia:
          if (anterior) {
            stateAgenda.dataAtual = UtilitarioData.diminuirDiaNaDataDayJs(stateAgenda.dataAtual, 1);
          } else if (proximo) {
            stateAgenda.dataAtual = UtilitarioData.adicionarDiaNaDataDayJs(stateAgenda.dataAtual, 1);
          }
          stateAgenda.dataInicialFiltro = stateAgenda.dataAtual;
          stateAgenda.dataFinalFiltro = stateAgenda.dataAtual;
          break;
        case EVisualizacaoAgendamento.Semana:
          if (anterior) {
            stateAgenda.dataAtual = UtilitarioData.diminuirSemanaNaDataDayJs(stateAgenda.dataAtual, 1);
          } else if (proximo) {
            stateAgenda.dataAtual = UtilitarioData.adicionarSemanaNaDataDayJs(stateAgenda.dataAtual, 1);
          }
          montaDiaSemana(stateAgenda.dataAtual);
          break;
        case EVisualizacaoAgendamento.Mes:
          if (anterior) {
            stateAgenda.dataAtual = UtilitarioData.diminuirMesNaDataDayJs(stateAgenda.dataAtual, 1);
          } else if (proximo) {
            stateAgenda.dataAtual = UtilitarioData.adicionarMesNaDataDayJs(stateAgenda.dataAtual, 1);
          }
          stateAgenda.dataInicialFiltro = UtilitarioData.definePrimeiroDiaMesNaDataDayJs(stateAgenda.dataAtual);
          stateAgenda.dataFinalFiltro = UtilitarioData.defineUltimoDiaMesNaDataDayJs(stateAgenda.dataAtual);
          break;
        case EVisualizacaoAgendamento.Ano:
          if (anterior) {
            stateAgenda.dataAtual = UtilitarioData.diminuirAnoNaDataDayJs(stateAgenda.dataAtual, 1);
          } else if (proximo) {
            stateAgenda.dataAtual = UtilitarioData.adicionarAnoNaDataDayJs(stateAgenda.dataAtual, 1);
          }
          stateAgenda.dataInicialFiltro = UtilitarioData.criarDataDayJs(UtilitarioData.obterAnoData(stateAgenda.dataAtual), 1, 1);
          stateAgenda.dataFinalFiltro = UtilitarioData.criarDataDayJs(UtilitarioData.obterAnoData(stateAgenda.dataAtual), 12, 31);
          break;
        default:
      }

      stateAgenda.dataInicialFiltro = stateAgenda.dataInicialFiltro.hour(0).minute(0).second(1);
      stateAgenda.dataFinalFiltro = stateAgenda.dataFinalFiltro.hour(23).minute(59).second(59);
      await buscarAgendamentosCalendario();
    }

    async function atualizaInformacoesDataAtual() {
      const dataAtualServidor = await servicoSistema.obterDataAtual();
      stateAgenda.dataAtual = UtilitarioData.converterDataHoraMinutoSegundoParaDayJs(dataAtualServidor);
      stateAgenda.listaAgendamentos = [];
      stateAgenda.formatoVisualizacao = EVisualizacaoAgendamento.Mes;
      stateAgenda.exibirFiltros = false;
      stateAgenda.exibirGradeAgendamentos = false;
      await navegacaoPeriodo(false, false);
    }
    function removerAgendamento(codigoAgendamento: number) {
      let indice = 0;
      indice = stateAgenda.listaAgendamentos.findIndex((c) => c.codigo === codigoAgendamento);
      if (indice >= 0) {
        stateAgenda.listaAgendamentos.splice(indice, 1);
      }
    }

    function limparVisualizacao() {
      if (stateAgenda.codigoAgendamentoVisualizacao > 0) {
        stateAgenda.codigoAgendamentoVisualizacao = 0;
        router.push({ name: 'Agenda' });
      }
    }

    async function sincronizarAgendamento(acao: EPermissaoDados, codigoAgendamento: number) {
      if (acao === EPermissaoDados.Incluir || acao === EPermissaoDados.Alterar) {
        const parametrosAgendamento: IParametrosAgendamento = {} as IParametrosAgendamento;

        if (!UtilitarioGeral.validaLista(telaBase.empresasSelecionadas)) {
          telaBase.empresasSelecionadas = storeSistema.getters.empresasUsuarioAutenticado();
        }

        parametrosAgendamento.empresas = telaBase.empresasSelecionadas;
        parametrosAgendamento.codigosSelecionados = [];
        parametrosAgendamento.codigosSelecionados.push(codigoAgendamento);

        const agendamento = await servicoAgendamento.obterAgendamentos(parametrosAgendamento);
        if (UtilitarioGeral.validaLista(agendamento)) {
          if (acao === EPermissaoDados.Incluir) {
            stateAgenda.listaAgendamentos.push(agendamento[0]);
          } else if (acao === EPermissaoDados.Alterar) {
            removerAgendamento(codigoAgendamento);
            stateAgenda.listaAgendamentos.push(agendamento[0]);
          }
        }
      } else if (acao === EPermissaoDados.Excluir) {
        removerAgendamento(codigoAgendamento);
      }
    }
    function limparFiltros() {
      stateAgenda.pessoasSelecionadas = [];
      stateAgenda.usuariosSelecionados = [];
      stateAgenda.dataFiltro = '';
      stateAgenda.marcadores = [];
      stateAgenda.codigoTipoAgendamento = undefined;
    }
    onMounted(async () => {
      await atualizaInformacoesDataAtual();
    });

    onUpdated(() => {
      if (UtilitarioGeral.valorValido(route.params.codigoAgendamento)) {
        stateAgenda.codigoAgendamentoVisualizacao = Number(route.params.codigoAgendamento);
      } else {
        stateAgenda.codigoAgendamentoVisualizacao = 0;
      }
    });

    watch(async () => stateAgenda.codigoAgendamentoVisualizacao, async () => {
      if (stateAgenda.codigoAgendamentoVisualizacao > 0) {
        editarAgendamento(stateAgenda.codigoAgendamentoVisualizacao);
      }
    });

    return {
      stateAgenda,
      telaBase,
      storeSistema,
      filtrarAgendamentosPorDia,
      filtrarAgendamentosPorMes,
      novoAgendamento,
      editarAgendamento,
      buscarAgendamentosCalendario,
      buscarAgendamentosFiltros,
      UtilitarioData,
      UtilitarioGeral,
      horarioAgendamentos,
      EVisualizacaoAgendamento,
      montaTituloApresentacao,
      navegacaoPeriodo,
      defineExibicaoFiltros,
      atualizaInformacoesDataAtual,
      verificaVisualizacaoTodosUsuarios,
      limparFiltros,
      sincronizarAgendamento,
      limparVisualizacao,
    };
  },
});
