
import {
  computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { IWidgetDado } from '@/models/Entidades/MeuSistema/IWidget';
import { IDTOWidgetUsuario } from '@/models/DTO/MeuSistema/Usuarios/IDTOWidgetUsuario';
import ServicoMinhaDashboard from '@/servicos/MeuSistema/ServicoMinhaDashboard';
import SelecionarGenerico from '@/core/components/Tela/SelecionarGenerico.vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import WidgetLista from './WidgetLista.vue';
import WidgetValor from './WidgetValor.vue';
import { ETipoWidget } from '@/models/Enumeradores/MeuSistema/ETipoWidget';

export default defineComponent({
  name: 'Widget',
  components: {
    WidgetLista,
    WidgetValor,
    SelecionarGenerico,
  },
  props: {
    widget: {
      type: Object as () => IDTOWidgetUsuario,
      required: true,
    },
  },
  emits: ['update:widget'],
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();
    const servicoMinhaDashboard = new ServicoMinhaDashboard();

    const state = reactive({
      filtros: [] as IOption[],
      intervalo: 0,
      carregando: false,
    });

    const computedWidget = computed({
      get: () => props.widget,
      set: (val: IDTOWidgetUsuario) => {
        emit('update:widget', val);
      },
    });

    async function atualizarDados(forcarAtualizacao: boolean) {
      state.carregando = true;
      computedWidget.value.dados = [] as IWidgetDado[];
      computedWidget.value.dados = await servicoMinhaDashboard.obterDadosWidget(props.widget, forcarAtualizacao);
      state.carregando = false;
    }

    function popularFiltros() {
      state.filtros = [] as IOption[];
      props.widget.filtros.forEach((f) => {
        const option = {} as IOption;
        option.label = f.textoIdentificacao;
        option.value = f.codigo;
        state.filtros.push(option);
      });
    }

    onBeforeMount(async () => {
      await atualizarDados(false);
      state.intervalo = setInterval(async () => { await atualizarDados(false); }, props.widget.tempoAtualizacao * 1000);
      popularFiltros();
    });

    return {
      state,
      props,
      telaBase,
      ETipoWidget,
      computedWidget,
      atualizarDados,
    };
  },
});
