
import { defineComponent, onBeforeMount, reactive } from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import ServicoMinhaDashboard from '@/servicos/MeuSistema/ServicoMinhaDashboard';
import { INoticia } from '@/models/Entidades/MeuSistema/INoticia';

export default defineComponent({
  name: 'Noticias',
  components: {
  },
  setup() {
    const { telaBase } = useTelaBase();
    const servicoMinhaDashboard = new ServicoMinhaDashboard();

    const state = reactive({
      noticias: [] as INoticia[],
    });

    onBeforeMount(async () => {
      state.noticias = [] as INoticia[];
      state.noticias = await servicoMinhaDashboard.obterNoticias();
    });

    return {
      state,
      telaBase,
    };
  },
});
