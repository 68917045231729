
import { defineComponent, onBeforeMount, reactive } from 'vue';
import { Modal } from 'ant-design-vue';
import storeSistema from '@/store/storeSistema';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import Card from '@/core/components/Tela/Card.vue';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { useTelaBase } from '@/core/composables/TelaBase';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IControleNumeracao } from '@/models/Entidades/MeuSistema/IControleNumeracao';
import { ETipoControleNumeracao } from '@/models/Enumeradores/MeuSistema/ETipoControleNumeracao';
import { ETipoControleSerie } from '@/models/Enumeradores/MeuSistema/ETipoControleSerie';
import { IControleSerie } from '@/models/Entidades/MeuSistema/IControleSerie';

export default defineComponent({
  name: 'ControleNumeracoesIndex',
  components: {
    TituloPadrao,
    Card,
  },
  setup() {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissao,
      verificaConceitoParaApresentarEmpresas, apresentarMensagemSucesso, apresentarMensagemAlerta,
      adicionarAtalho, removerAtalho,
    } = useTelaBase();

    telaBase.identificadorRecurso = 'CONTROLE_NUMERACOES';
    telaBase.identificadorPermissao = 'AUT_CONTROLE_NUMERACOES';
    telaBase.empresasSelecionadas = [];

    const servicoSistema = new ServicoSistema();

    const state = reactive({
      codigoEmpresa: 0,
      numeracoesDb: [] as IControleNumeracao[],
      numeracoes: [] as IControleNumeracao[],
      seriesDb: [] as IControleSerie[],
      series: [] as IControleSerie[],
    });

    function validarNumeroNegativo(index: number) {
      if (state.numeracoes[index].numero < 0) {
        apresentarMensagemAlerta('Não são permitidas numerações menores que 0!');
        state.numeracoes[index].numero = state.numeracoesDb[index].numero;
      }
    }

    function obterDescricaoNumeracao(controle: ETipoControleNumeracao) {
      let descricao = '';
      switch (controle) {
        case ETipoControleNumeracao.SolcitacoesCompras:
          descricao = 'Solicitações Compras';
          break;
        case ETipoControleNumeracao.Cotacoes:
          descricao = 'Cotações';
          break;
        case ETipoControleNumeracao.PedidosCompras:
          descricao = 'Pedidos Compras';
          break;
        case ETipoControleNumeracao.Recebimentos:
          descricao = 'Recebimento Mercadorias';
          break;
        case ETipoControleNumeracao.DevolucoesCompras:
          descricao = 'Devoluções Compras';
          break;
        case ETipoControleNumeracao.Vendas:
          descricao = 'Vendas';
          break;
        case ETipoControleNumeracao.DevolucoesVendas:
          descricao = 'Devoluções Vendas';
          break;
        case ETipoControleNumeracao.NumeroNFe:
          descricao = 'Número NF-e';
          break;
        case ETipoControleNumeracao.NumeroNFCe:
          descricao = 'Número NFC-e';
          break;
        case ETipoControleNumeracao.NumeroMDFe:
          descricao = 'Número MDF-e';
          break;
        case ETipoControleNumeracao.NumeroNFSe:
          descricao = 'Número RPS';
          break;
        case ETipoControleNumeracao.Produtos:
          descricao = 'Código interno dos Produtos';
          break;
        default:
          break;
      }

      return descricao;
    }

    function obterDescricaoSerie(controle: ETipoControleSerie) {
      let descricao = '';
      switch (controle) {
        case ETipoControleSerie.SerieNFe:
          descricao = 'Série NF-e';
          break;
        case ETipoControleSerie.SerieNFCe:
          descricao = 'Série NFC-e';
          break;
        case ETipoControleSerie.SerieMDFe:
          descricao = 'Série MDF-e';
          break;
        case ETipoControleSerie.SerieNFSe:
          descricao = 'Série RPS';
          break;
        default:
          break;
      }

      return descricao;
    }

    async function salvarNumeracao(tipos: number[], descricao: string) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      Modal.confirm({
        title: `Você confirma alteração das numerações de ${descricao}?`,
        okText: 'Sim',
        okType: 'primary',
        cancelText: 'Não',
        centered: true,
        autoFocusButton: null,
        onOk: async () => {
          const numeracoes = [] as IControleNumeracao[];

          state.numeracoes.forEach((n, indexN) => {
            if (tipos.includes(n.tipo) && n.numero !== state.numeracoesDb[indexN].numero) {
              numeracoes.push(n);
            }
          });

          retorno = await servicoSistema.atualizarControleNumeracao(state.codigoEmpresa, numeracoes);
          if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
            apresentarMensagemSucesso('Numeração atualizada com sucesso!');
          } else {
            apresentarMensagemAlerta('Não foi possível atualizar a numeração.');
          }
        },
      });
    }

    async function salvarSeries(tipos: number[], descricao: string) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      Modal.confirm({
        title: `Você confirma alteração da série de ${descricao}?`,
        okText: 'Sim',
        okType: 'primary',
        cancelText: 'Não',
        centered: true,
        autoFocusButton: null,
        onOk: async () => {
          const series = [] as IControleSerie[];

          state.series.forEach((n, indexN) => {
            if (tipos.includes(n.tipo) && n.numero !== state.seriesDb[indexN].numero) {
              series.push(n);
            }
          });

          retorno = await servicoSistema.atualizarControleSerie(state.codigoEmpresa, series);
          if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
            apresentarMensagemSucesso('Série atualizada com sucesso!');
          } else {
            apresentarMensagemAlerta('Não foi possível atualizar a série.');
          }
        },
      });
    }

    onBeforeMount(async () => {
      telaBase.carregando = true;
      await obterPermissoes(ETipoPermissao.Autorizacoes);
      telaBase.apresentarEmpresas = false;
      await preencherEmpresasComEstrategiaPermissao();
      state.codigoEmpresa = storeSistema.getters.codigoEmpresaOperacao();
      verificaConceitoParaApresentarEmpresas();
      state.numeracoes = await servicoSistema.obterControleNumeracoes(state.codigoEmpresa);
      state.numeracoesDb = UtilitarioGeral.clonarObjeto(state.numeracoes);

      state.series = await servicoSistema.obterControleSeries(state.codigoEmpresa);
      state.seriesDb = UtilitarioGeral.clonarObjeto(state.series);
      telaBase.carregando = false;
    });

    function validarInformacaoCampo(evento:any, informacao: any, tipoControle: ETipoControleSerie) {
      if (tipoControle !== ETipoControleSerie.SerieNFSe) {
        if (!/[0-9]/.test(informacao)) {
          evento.preventDefault();
        }
      }
    }

    function validarInformacaoDigitada(evento:any, tipoControle: ETipoControleSerie) {
      const charCode = String.fromCharCode(evento.keyCode);
      validarInformacaoCampo(evento, charCode, tipoControle);
    }

    function validarInformacaoColada(evento:any, tipoControle: ETipoControleSerie) {
      const informacao = evento.clipboardData.getData('text');
      validarInformacaoCampo(evento, informacao, tipoControle);
    }

    return {
      telaBase,
      storeSistema,
      state,
      ETipoPermissao,
      EPermissaoDados,
      ETipoControleNumeracao,
      ETipoControleSerie,
      UtilitarioGeral,
      UtilitarioMascara,
      validarNumeroNegativo,
      salvarNumeracao,
      adicionarAtalho,
      removerAtalho,
      obterDescricaoNumeracao,
      salvarSeries,
      obterDescricaoSerie,
      validarInformacaoDigitada,
      validarInformacaoColada,
    };
  },
});
