import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d4275602"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ss-texto-titulo" }
const _hoisted_2 = {
  class: "ss-div-conteudo",
  style: {"max-height":"calc(100% - 25px)"}
}
const _hoisted_3 = {
  key: 0,
  class: "ss-div-filtro"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_widget_lista = _resolveComponent("widget-lista")!
  const _component_widget_valor = _resolveComponent("widget-valor")!
  const _component_selecionar_generico = _resolveComponent("selecionar-generico")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.computedWidget.titulo), 1),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.computedWidget.tipo === _ctx.ETipoWidget.Lista)
        ? (_openBlock(), _createBlock(_component_widget_lista, {
            key: 0,
            widget: _ctx.computedWidget
          }, null, 8, ["widget"]))
        : _createCommentVNode("", true),
      (_ctx.computedWidget.tipo === _ctx.ETipoWidget.Valor)
        ? (_openBlock(), _createBlock(_component_widget_valor, {
            key: 1,
            widget: _ctx.computedWidget
          }, null, 8, ["widget"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.state.filtros.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_selecionar_generico, {
            bordas: false,
            tamanho: "small",
            itens: _ctx.state.filtros,
            "limpar-selecao": false,
            "mostrar-busca": false,
            valor: _ctx.computedWidget.codigoWidgetFiltro,
            "onUpdate:valor": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedWidget.codigoWidgetFiltro) = $event)),
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.atualizarDados(true)))
          }, null, 8, ["itens", "valor"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_a_spin, {
      spinning: _ctx.state.carregando
    }, null, 8, ["spinning"])
  ], 64))
}