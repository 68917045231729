
import { defineComponent } from 'vue';
import Icone from '@/core/components/Icone.vue';

export default defineComponent({
  name: 'NavegacaoRegistro',
  components: {
    Icone,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    exibirExcluir: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['primeiro', 'anterior', 'proximo', 'ultimo', 'excluir'],
  setup(props, { emit }) {
    return {
      props,
      emit,
    };
  },
});
