import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_registro_atividade = _resolveComponent("registro-atividade")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      keyboard: false,
      visible: _ctx.modalBase.computedVisivel,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalBase.computedVisivel) = $event)),
      width: 1000,
      centered: true
    }, {
      title: _withCtx(() => [
        _createTextVNode(" Registro de Atividades ")
      ]),
      closeIcon: _withCtx(() => [
        _createVNode(_component_icone, { nome: "voltar" })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_registro_atividade, {
          ref: "refRegistroAtividade",
          apresentarEmpresas: (_ctx.telaBase.empresasSelecionadas.length > 1),
          empresas: _ctx.telaBase.empresasSelecionadas,
          apresentarUsuarios: true,
          largura: 1200,
          altura: 600
        }, null, 8, ["apresentarEmpresas", "empresas"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}