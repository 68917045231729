
import { defineComponent, onBeforeMount, reactive } from 'vue';
import { Modal } from 'ant-design-vue';
import storeSistema from '@/store/storeSistema';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import Card from '@/core/components/Tela/Card.vue';
import BarraProgresso from '@/core/components/Tela/BarraProgresso.vue';
import ServicoUsuario from '@/servicos/MeuSistema/ServicoUsuario';
import ServicoPerfilUsuario from '@/servicos/MeuSistema/ServicoPerfilUsuario';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IOption } from '@/core/models/AntDesign/IOption';
import { useTelaBase } from '@/core/composables/TelaBase';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { IDTOControlePermissoes } from '@/models/DTO/MeuSistema/Usuarios/IDTOControlePermissoes';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { IPermissaoUsuario } from '@/models/Entidades/MeuSistema/Usuarios/Permissoes/IPermissaoUsuario';
import { IPermissaoDados } from '@/models/Entidades/MeuSistema/Usuarios/Permissoes/IPermissaoDados';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDTOControlePermissoesEstrutura } from '@/models/DTO/MeuSistema/Usuarios/IDTOControlePermissoesEstrutura';
import { IDTOModuloPermissoesControleEstrutura } from '@/models/DTO/MeuSistema/Usuarios/IDTOModuloPermissoesControleEstrutura';
import { IDTOCategoriaPermissoesControleEstrutura } from '@/models/DTO/MeuSistema/Usuarios/IDTOCategoriaPermissoesControleEstrutura';
import SelecionarUsuario from '@/components/MeuSistema/Usuarios/SelecionarUsuario.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { useModalBase } from '@/core/composables/ModalBase';

export default defineComponent({
  name: 'ControlePermissoes',
  components: {
    TituloPadrao,
    Card,
    BarraProgresso,
    SelecionarUsuario,
    RequisicaoModal,
  },
  setup(props, { emit }) {
    const {
      telaBase, montaOpcoesComListaConsultaRapida, obterPermissoes, preencherEmpresasComEstrategiaPermissao,
      verificaConceitoParaApresentarEmpresas, apresentarMensagemSucesso, apresentarMensagemAlerta,
      adicionarAtalho, removerAtalho,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);

    telaBase.identificadorRecurso = 'CONTROLE_PERMISSOES_USUARIO';
    telaBase.identificadorPermissao = 'AUT_CONTROLE_PERMISSOES_USUARIO';
    telaBase.empresasSelecionadas = [];

    const servicoUsuario = new ServicoUsuario();
    const servicoPerfilUsuario = new ServicoPerfilUsuario();

    const state = reactive({
      listaUsuarios: [] as IOption[],
      listaPerfis: [] as IOption[],
      listaPermissoes: [] as IOption[],
      listaPermissoesEstrutura: [] as IDTOControlePermissoes[],
      listaControlePermissoes: [] as IDTOControlePermissoesEstrutura[],
      listaModulosControlePermissoes: [] as IDTOModuloPermissoesControleEstrutura[],
      valorAcaoMassa: 1,
      codigoControleAcaoMassa: 0,
      codigoModuloAcaoMassa: 0,
      codigoPermissaoPesquisa: 0,
      codigoTabControle: 1,
      codigoTabModulo: 1,
      codigoEmpresa: 0,
      codigoUsuario: 0,
      codigoPerfilUsuario: 0,
      codigoPerfilUsuarioAtual: 0,
      permiteSalvarPermissoes: false,
      permiteAlteracaoPerfil: false,
      permiteDesativarControlePermissoes: false,
    });

    async function validarControlePermissoes() {
      state.permiteDesativarControlePermissoes = await servicoUsuario.validarControlePermissoes(state.codigoUsuario, state.codigoEmpresa);
    }

    async function obterControlePermissoesUsuario(codigoUsuario: number) {
      telaBase.carregando = true;
      state.codigoUsuario = codigoUsuario;
      state.codigoPerfilUsuario = 0;
      state.codigoPerfilUsuarioAtual = 0;
      state.permiteSalvarPermissoes = false;
      state.permiteAlteracaoPerfil = false;
      state.listaPermissoes = [];

      const perfisUsuario = await servicoUsuario.obterPerfisUsuarios(codigoUsuario, telaBase.empresasSelecionadas[0]);
      const perfilEmpresa = perfisUsuario.find((c) => c.codigoEmpresa === state.codigoEmpresa);
      if (perfilEmpresa !== undefined) {
        const perfilExisteNaLista = state.listaPerfis.find((c) => c.value === perfilEmpresa.codigoPerfilUsuario);
        if (perfilExisteNaLista === undefined) {
          state.codigoPerfilUsuario = 0;
          state.codigoPerfilUsuarioAtual = 0;
        } else {
          state.codigoPerfilUsuario = perfilEmpresa.codigoPerfilUsuario;
          state.codigoPerfilUsuarioAtual = perfilEmpresa.codigoPerfilUsuario;
        }
      }

      state.listaPermissoesEstrutura = await servicoUsuario.obterPermissoesControle(codigoUsuario, state.codigoEmpresa);
      state.listaControlePermissoes = [];
      state.listaModulosControlePermissoes = [];

      const permissoes: IDTOControlePermissoesEstrutura = {} as IDTOControlePermissoesEstrutura;
      permissoes.tipoPermissao = ETipoPermissao.Dados;
      permissoes.descricao = 'Permissões';
      permissoes.modulos = [];
      state.listaControlePermissoes.push(permissoes);
      const autorizacoes: IDTOControlePermissoesEstrutura = {} as IDTOControlePermissoesEstrutura;
      autorizacoes.tipoPermissao = ETipoPermissao.Autorizacoes;
      autorizacoes.descricao = 'Autorizações';
      autorizacoes.modulos = [];
      state.listaControlePermissoes.push(autorizacoes);

      const widgets: IDTOControlePermissoesEstrutura = {} as IDTOControlePermissoesEstrutura;
      widgets.tipoPermissao = ETipoPermissao.Widgets;
      widgets.descricao = 'Widgets';
      widgets.modulos = [];
      state.listaControlePermissoes.push(widgets);

      const relatorios: IDTOControlePermissoesEstrutura = {} as IDTOControlePermissoesEstrutura;
      relatorios.tipoPermissao = ETipoPermissao.Relatorios;
      relatorios.descricao = 'Relatórios';
      relatorios.modulos = [];
      state.listaControlePermissoes.push(relatorios);

      for (let i = 0; i < state.listaPermissoesEstrutura.length; (i += 1)) {
        const permissao = state.listaPermissoesEstrutura[i];

        const controleLocalizado = state.listaControlePermissoes.find((c) => c.tipoPermissao === permissao.tipoPermissao);

        if (controleLocalizado !== undefined) {
          let moduloLocalizado = controleLocalizado.modulos.find((c) => c.codigo === permissao.codigoModulo);

          if (moduloLocalizado === undefined) {
            const modulo: IDTOModuloPermissoesControleEstrutura = {} as IDTOModuloPermissoesControleEstrutura;
            modulo.codigo = permissao.codigoModulo;
            modulo.titulo = permissao.tituloModulo;
            modulo.categorias = [];
            controleLocalizado.modulos.push(modulo);

            const moduloUnico = state.listaModulosControlePermissoes.find((c) => c.codigo === permissao.codigoModulo);
            if (moduloUnico === undefined) {
              state.listaModulosControlePermissoes.push(modulo);
            }
          }

          if (moduloLocalizado === undefined) {
            moduloLocalizado = controleLocalizado.modulos.find((c) => c.codigo === permissao.codigoModulo);
          }

          if (moduloLocalizado !== undefined) {
            let categoriaLocalizada = moduloLocalizado.categorias.find((c) => c.codigo === permissao.codigoCategoriaPermissao);

            if (categoriaLocalizada === undefined) {
              const categoria: IDTOCategoriaPermissoesControleEstrutura = {} as IDTOCategoriaPermissoesControleEstrutura;
              categoria.codigo = permissao.codigoCategoriaPermissao;
              categoria.descricao = permissao.descricaoCategoriaPermissao;
              categoria.visualizar = true;
              categoria.incluir = true;
              categoria.alterar = true;
              categoria.excluir = true;
              categoria.imprimir = true;
              categoria.permissoes = [];
              moduloLocalizado.categorias.push(categoria);
            }

            if (categoriaLocalizada === undefined) {
              categoriaLocalizada = moduloLocalizado.categorias.find((c) => c.codigo === permissao.codigoCategoriaPermissao);
            }

            if (categoriaLocalizada !== undefined) {
              if (permissao.tipoPermissao === ETipoPermissao.Dados) {
                if (!permissao.visualizar) { categoriaLocalizada.visualizar = false; }
                if (!permissao.incluir) { categoriaLocalizada.incluir = false; }
                if (!permissao.alterar) { categoriaLocalizada.alterar = false; }
                if (!permissao.excluir) { categoriaLocalizada.excluir = false; }
                if (!permissao.imprimir) { categoriaLocalizada.imprimir = false; }
              } else {
                permissao.visualizar = permissao.codigoPermissaoLiberada > 0;
                if (!permissao.visualizar) { categoriaLocalizada.visualizar = false; }
              }

              categoriaLocalizada.permissoes.push(permissao);
            }
          }
        }
      }

      state.permiteAlteracaoPerfil = true;
      await validarControlePermissoes();
      telaBase.carregando = false;
    }

    async function atualizarPerfilUsuario(codigoPerfil: number) {
      apresentarBarraProgresso();
      const retornoAlteracaoPerfil = await servicoUsuario.atualizarPerfilUsuarioComPermissoes(state.codigoUsuario, state.codigoEmpresa, codigoPerfil);
      ocultarBarraProgresso();

      if (retornoAlteracaoPerfil.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retornoAlteracaoPerfil.mensagem);
        await obterControlePermissoesUsuario(state.codigoUsuario);
      } else {
        apresentarRetornoRequisicao(retornoAlteracaoPerfil);
      }
    }

    async function confirmeAlteracaoPerfil(codigoPerfil: number) {
      if (codigoPerfil !== state.codigoPerfilUsuarioAtual) {
        Modal.confirm({
          title: 'Você confirma a alteração do perfil do usuário ?',
          content: 'Todas as permissões serão definidas conforme o perfil.',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Não',
          autoFocusButton: null,
          onOk: () => { atualizarPerfilUsuario(codigoPerfil); },
          onCancel: () => { state.codigoPerfilUsuario = state.codigoPerfilUsuarioAtual; },
        });
      }
    }

    async function salvarPermissoes() {
      const listaPermissoes: IPermissaoUsuario[] = [];
      if (state.codigoEmpresa === 0) {
        apresentarMensagemAlerta('Nenhuma empresa foi selecionada!');
        return;
      }
      if (state.codigoUsuario === 0) {
        apresentarMensagemAlerta('Nenhum usuário foi selecionado!');
        return;
      }
      if (!UtilitarioGeral.validaLista(state.listaControlePermissoes)) {
        apresentarMensagemAlerta('Nenhuma permissão foi selecionada ou encontrada!');
        return;
      }

      apresentarBarraProgresso();

      state.listaControlePermissoes.forEach((controle) => {
        controle.modulos.forEach((modulo) => {
          modulo.categorias.forEach((categoria) => {
            categoria.permissoes.forEach((permissao) => {
              const permissaoPersistencia: IPermissaoUsuario = {} as IPermissaoUsuario;
              permissaoPersistencia.codigo = permissao.codigoPermissaoLiberada;
              permissaoPersistencia.codigoEmpresa = state.codigoEmpresa;
              permissaoPersistencia.codigoUsuario = state.codigoUsuario;
              permissaoPersistencia.codigoPermissao = permissao.codigoPermissao;
              permissaoPersistencia.tipoPermissao = permissao.tipoPermissao;

              if (permissao.tipoPermissao === ETipoPermissao.Dados) {
                permissaoPersistencia.permissaoDados = {} as IPermissaoDados;
                permissaoPersistencia.permissaoDados.codigo = permissao.codigoPermissaoDados;
                permissaoPersistencia.permissaoDados.codigoPermissaoUsuario = permissaoPersistencia.codigo;
                permissaoPersistencia.permissaoDados.visualizar = permissao.visualizar;
                permissaoPersistencia.permissaoDados.incluir = permissao.incluir;
                permissaoPersistencia.permissaoDados.alterar = permissao.alterar;
                permissaoPersistencia.permissaoDados.excluir = permissao.excluir;
                permissaoPersistencia.permissaoDados.imprimir = permissao.imprimir;

                listaPermissoes.push(permissaoPersistencia);
              } else if (permissao.visualizar) {
                listaPermissoes.push(permissaoPersistencia);
              }
            });
          });
        });
      });

      if (UtilitarioGeral.validaLista(listaPermissoes)) {
        const retornoRequisicao = await servicoUsuario.atualizarPermissoesUsuario(state.codigoUsuario, state.codigoEmpresa, listaPermissoes);
        ocultarBarraProgresso();
        if (retornoRequisicao.status === EStatusRetornoRequisicao.Sucesso) {
          apresentarMensagemSucesso(retornoRequisicao.mensagem);
        } else {
          apresentarRetornoRequisicao(retornoRequisicao);
        }
      } else {
        ocultarBarraProgresso();
      }
    }

    async function obterTodosPerfisPorEmpresa() {
      state.listaPerfis = [];
      telaBase.empresasSelecionadas = [];
      telaBase.empresasSelecionadas.push(state.codigoEmpresa);
      const listaPerfisUsuariosAPI = await servicoPerfilUsuario.obterTodos(telaBase.empresasSelecionadas, true);
      if (UtilitarioGeral.validaLista(listaPerfisUsuariosAPI)) {
        state.listaPerfis = montaOpcoesComListaConsultaRapida(listaPerfisUsuariosAPI);
      }
    }
    onBeforeMount(async () => {
      await obterPermissoes(ETipoPermissao.Autorizacoes);
      telaBase.apresentarEmpresas = false;
      await preencherEmpresasComEstrategiaPermissao();
      state.codigoEmpresa = storeSistema.getters.codigoEmpresaOperacao();
      verificaConceitoParaApresentarEmpresas();
      await obterTodosPerfisPorEmpresa();
    });

    async function atualizarSelecaoEmpresa() {
      state.listaUsuarios = [];
      state.codigoUsuario = 0;
      state.codigoPerfilUsuario = 0;
      state.permiteSalvarPermissoes = false;
      state.permiteAlteracaoPerfil = false;
      await obterTodosPerfisPorEmpresa();
    }

    async function pesquisarUsuario(valorPesquisa: any) {
      state.listaUsuarios = [];
      clearTimeout(telaBase.debounce);
      telaBase.debounce = setTimeout(async () => {
        const parametrosConsultaRapida: IParametrosConsultaRapida = {
          valor: valorPesquisa,
          recursoAssociado: '',
          apenasAtivos: true,
        };
        parametrosConsultaRapida.empresas = [];
        parametrosConsultaRapida.empresas.push(state.codigoEmpresa);

        const listaUsuariosAPI = await servicoUsuario.consultaRapida(parametrosConsultaRapida, true);
        state.listaUsuarios = montaOpcoesComListaConsultaRapida(listaUsuariosAPI);
      }, 600);
    }

    async function pesquisarPermissao(valorPesquisa: any) {
      clearTimeout(telaBase.debounce);
      telaBase.debounce = setTimeout(async () => {
        const listaPermissoes = state.listaPermissoesEstrutura.filter((c) => c.descricaoPermissao.includes(valorPesquisa));

        state.listaPermissoes = listaPermissoes.map((permissao) => ({
          label: permissao.descricaoPermissao,
          value: permissao.codigoPermissao,
        }));
      }, 600);
    }

    function aplicarValorEmMassaModuloPermissoes(modulo: IDTOModuloPermissoesControleEstrutura, valor: boolean) {
      for (let c = 0; c < modulo.categorias.length; (c += 1)) {
        const categoria = modulo.categorias[c];
        categoria.visualizar = valor;
        categoria.incluir = valor;
        categoria.alterar = valor;
        categoria.excluir = valor;
        categoria.imprimir = valor;

        for (let p = 0; p < categoria.permissoes.length; (p += 1)) {
          const permissao = categoria.permissoes[p];
          if (permissao.tipoPermissao === ETipoPermissao.Dados) {
            permissao.visualizar = valor;
            permissao.incluir = valor;
            permissao.alterar = valor;
            permissao.excluir = valor;
            permissao.imprimir = valor;
          } else if (!state.permiteDesativarControlePermissoes && permissao.identificadorPermissao === 'AUT_CONTROLE_PERMISSOES_USUARIO') {
            permissao.visualizar = true;
          } else {
            permissao.visualizar = valor;
          }
        }
      }
    }

    async function aplicarValorPermissoesEmMassa() {
      const valorAplicar = state.valorAcaoMassa === 1;

      if (state.codigoControleAcaoMassa === 0) {
        for (let i = 0; i < state.listaControlePermissoes.length; (i += 1)) {
          const controle = state.listaControlePermissoes[i];
          if (state.codigoModuloAcaoMassa === 0) {
            for (let m = 0; m < controle.modulos.length; (m += 1)) {
              aplicarValorEmMassaModuloPermissoes(controle.modulos[m], valorAplicar);
            }
          } else {
            const modulo = controle.modulos.find((c) => c.codigo === state.codigoModuloAcaoMassa);
            if (modulo !== undefined) {
              aplicarValorEmMassaModuloPermissoes(modulo, valorAplicar);
            }
          }
        }
      } else {
        const controle = state.listaControlePermissoes.find((c) => c.tipoPermissao === state.codigoControleAcaoMassa);
        if (controle !== undefined) {
          if (state.codigoModuloAcaoMassa === 0) {
            for (let m = 0; m < controle.modulos.length; (m += 1)) {
              aplicarValorEmMassaModuloPermissoes(controle.modulos[m], valorAplicar);
            }
          } else {
            const modulo = controle.modulos.find((c) => c.codigo === state.codigoModuloAcaoMassa);
            if (modulo !== undefined) {
              aplicarValorEmMassaModuloPermissoes(modulo, valorAplicar);
            }
          }
        }
      }
      state.permiteSalvarPermissoes = true;
    }

    async function removerDestaquePermissao(codigoPermissao: number) {
      clearTimeout(telaBase.debounce);
      telaBase.debounce = setTimeout(async () => {
        const permissaoElemento = document.getElementById(`PER-${codigoPermissao}`);
        if (permissaoElemento !== null) {
          permissaoElemento.classList.remove('ss-permissao-encontrada');
        }
      }, 3000);
    }

    async function localizarPermissao(codigoPermissao: number) {
      const permissao = state.listaPermissoesEstrutura.find((c) => c.codigoPermissao === codigoPermissao);
      if (permissao !== undefined) {
        state.codigoTabControle = permissao.tipoPermissao;
        state.codigoTabModulo = (permissao.tipoPermissao + permissao.codigoModulo);

        clearTimeout(telaBase.debounce);
        telaBase.debounce = setTimeout(async () => {
          const permissaoElemento = document.getElementById(`PER-${codigoPermissao}`);
          if (permissaoElemento !== null) {
            permissaoElemento.classList.add('ss-permissao-encontrada');
            permissaoElemento.scrollIntoView();
            state.codigoPermissaoPesquisa = 0;
            // window.location.href = `#PER-${codigoPermissao}`;
            await removerDestaquePermissao(codigoPermissao);
          }
        }, 600);
      }
    }

    async function manipularPermissoesDados(valor: boolean, codigoModulo: number, codigoCategoria: number, tipoPermissaoDados: EPermissaoDados) {
      const controleLocalizado = state.listaControlePermissoes.find((c) => c.tipoPermissao === ETipoPermissao.Dados);
      if (controleLocalizado !== undefined) {
        const moduloLocalizado = controleLocalizado.modulos.find((c) => c.codigo === codigoModulo);
        if (moduloLocalizado !== undefined) {
          const categoriaLocalizada = moduloLocalizado.categorias.find((c) => c.codigo === codigoCategoria);
          if (categoriaLocalizada !== undefined) {
            for (let i = 0; i < categoriaLocalizada.permissoes.length; (i += 1)) {
              const permissao = categoriaLocalizada.permissoes[i];
              switch (tipoPermissaoDados) {
                case EPermissaoDados.Visualizar:
                  permissao.visualizar = valor;
                  break;

                case EPermissaoDados.Incluir:
                  permissao.incluir = valor;
                  break;

                case EPermissaoDados.Alterar:
                  permissao.alterar = valor;
                  break;

                case EPermissaoDados.Excluir:
                  permissao.excluir = valor;
                  break;

                case EPermissaoDados.Imprimir:
                  permissao.imprimir = valor;
                  break;
                default:
              }
            }
          }
        }
        state.permiteSalvarPermissoes = true;
      }
    }

    async function manipularPermissoes(valor: boolean, codigoModulo: number, codigoCategoria: number, tipoPermissao: ETipoPermissao) {
      const controleLocalizado = state.listaControlePermissoes.find((c) => c.tipoPermissao === tipoPermissao);
      if (controleLocalizado !== undefined) {
        const moduloLocalizado = controleLocalizado.modulos.find((c) => c.codigo === codigoModulo);
        if (moduloLocalizado !== undefined) {
          const categoriaLocalizada = moduloLocalizado.categorias.find((c) => c.codigo === codigoCategoria);
          if (categoriaLocalizada !== undefined) {
            for (let i = 0; i < categoriaLocalizada.permissoes.length; (i += 1)) {
              const permissao = categoriaLocalizada.permissoes[i];
              if (!state.permiteDesativarControlePermissoes && permissao.identificadorPermissao === 'AUT_CONTROLE_PERMISSOES_USUARIO') {
                permissao.visualizar = true;
              } else {
                permissao.visualizar = valor;
              }
            }
          }
        }
      }
      state.permiteSalvarPermissoes = true;
    }

    async function checarCategoriaPermissoesDados(codigoModulo: number, codigoCategoria: number, tipoPermissaoDados: EPermissaoDados) {
      const controleLocalizado = state.listaControlePermissoes.find((c) => c.tipoPermissao === ETipoPermissao.Dados);
      if (controleLocalizado !== undefined) {
        const moduloLocalizado = controleLocalizado.modulos.find((c) => c.codigo === codigoModulo);
        if (moduloLocalizado !== undefined) {
          const categoriaLocalizada = moduloLocalizado.categorias.find((c) => c.codigo === codigoCategoria);

          if (categoriaLocalizada !== undefined) {
            if (tipoPermissaoDados === EPermissaoDados.Visualizar) {
              const listaPermissoesDesmarcadas = categoriaLocalizada.permissoes.find((c) => c.visualizar === false);
              categoriaLocalizada.visualizar = listaPermissoesDesmarcadas === undefined;
            } else if (tipoPermissaoDados === EPermissaoDados.Incluir) {
              const listaPermissoesDesmarcadas = categoriaLocalizada.permissoes.find((c) => c.incluir === false);
              categoriaLocalizada.incluir = listaPermissoesDesmarcadas === undefined;
            } else if (tipoPermissaoDados === EPermissaoDados.Alterar) {
              const listaPermissoesDesmarcadas = categoriaLocalizada.permissoes.find((c) => c.alterar === false);
              categoriaLocalizada.alterar = listaPermissoesDesmarcadas === undefined;
            } else if (tipoPermissaoDados === EPermissaoDados.Excluir) {
              const listaPermissoesDesmarcadas = categoriaLocalizada.permissoes.find((c) => c.excluir === false);
              categoriaLocalizada.excluir = listaPermissoesDesmarcadas === undefined;
            } else if (tipoPermissaoDados === EPermissaoDados.Imprimir) {
              const listaPermissoesDesmarcadas = categoriaLocalizada.permissoes.find((c) => c.imprimir === false);
              categoriaLocalizada.imprimir = listaPermissoesDesmarcadas === undefined;
            }
          }
        }
        state.permiteSalvarPermissoes = true;
      }
    }
    async function checarCategoriaPermissoes(codigoModulo: number, codigoCategoria: number, tipoPermissao: ETipoPermissao) {
      const controleLocalizado = state.listaControlePermissoes.find((c) => c.tipoPermissao === tipoPermissao);
      if (controleLocalizado !== undefined) {
        const moduloLocalizado = controleLocalizado.modulos.find((c) => c.codigo === codigoModulo);
        if (moduloLocalizado !== undefined) {
          const categoriaLocalizada = moduloLocalizado.categorias.find((c) => c.codigo === codigoCategoria);
          if (categoriaLocalizada !== undefined) {
            const listaPermissoesDesmarcadas = categoriaLocalizada.permissoes.find((c) => c.visualizar === false);
            categoriaLocalizada.visualizar = listaPermissoesDesmarcadas === undefined;
          }
        }
        state.permiteSalvarPermissoes = true;
      }
    }

    async function reaplicarPerfil() {
      telaBase.carregando = true;
      const permissoes = await servicoPerfilUsuario.obterPermissoesControle(state.codigoPerfilUsuario, [state.codigoEmpresa]);
      permissoes.forEach((p) => {
        const indexP = state.listaPermissoesEstrutura.findIndex((i) => i.identificadorPermissao === p.identificadorPermissao);
        if (p.tipoPermissao === ETipoPermissao.Dados) {
          state.listaPermissoesEstrutura[indexP].incluir = p.incluir;
          state.listaPermissoesEstrutura[indexP].alterar = p.alterar;
          state.listaPermissoesEstrutura[indexP].excluir = p.excluir;
          state.listaPermissoesEstrutura[indexP].visualizar = p.visualizar;
        } else if (!state.permiteDesativarControlePermissoes && state.listaPermissoesEstrutura[indexP].identificadorPermissao === 'AUT_CONTROLE_PERMISSOES_USUARIO') {
          state.listaPermissoesEstrutura[indexP].visualizar = true;
        } else {
          state.listaPermissoesEstrutura[indexP].visualizar = p.codigoPermissaoLiberada > 0;
        }
      });
      telaBase.carregando = false;
      state.permiteSalvarPermissoes = true;
    }

    return {
      telaBase,
      storeSistema,
      state,
      ETipoPermissao,
      EPermissaoDados,
      UtilitarioGeral,
      UtilitarioMascara,
      pesquisarUsuario,
      obterControlePermissoesUsuario,
      manipularPermissoesDados,
      manipularPermissoes,
      checarCategoriaPermissoesDados,
      checarCategoriaPermissoes,
      atualizarSelecaoEmpresa,
      salvarPermissoes,
      pesquisarPermissao,
      aplicarValorPermissoesEmMassa,
      localizarPermissao,
      confirmeAlteracaoPerfil,
      adicionarAtalho,
      removerAtalho,
      reaplicarPerfil,
      modalBase,
    };
  },
});
