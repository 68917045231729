
import { defineComponent, onBeforeMount, reactive } from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import storeSistema from '@/store/storeSistema';
import ServicoMinhaDashboard from '@/servicos/MeuSistema/ServicoMinhaDashboard';
import WidgetsUsuarioColuna from './WidgetsUsuarioColuna.vue';
import { IDTOWidgetUsuario } from '@/models/DTO/MeuSistema/Usuarios/IDTOWidgetUsuario';
import { IWidgetUsuario } from '@/models/Entidades/MeuSistema/IWidget';
import WidgetUsuarioNovoModal from './WidgetUsuarioNovoModal.vue';

export default defineComponent({
  name: 'WidgetsUsuario',
  components: {
    WidgetsUsuarioColuna,
    WidgetUsuarioNovoModal,
  },
  setup() {
    const { telaBase } = useTelaBase();
    const servicoMinhaDashboard = new ServicoMinhaDashboard();

    const state = reactive({
      widgets: [] as IDTOWidgetUsuario[],
      exibirInclusao: false,
    });

    function limparTela() {
      state.widgets = [] as IDTOWidgetUsuario[];
      state.exibirInclusao = false;
    }

    function abrirInclusaoWidget() {
      state.exibirInclusao = true;
    }

    async function incluirWidget(widget: IDTOWidgetUsuario) {
      const widgetUsuario = {} as IWidgetUsuario;
      widgetUsuario.codigoWidget = widget.codigo;
      widgetUsuario.codigoEmpresa = widget.codigoEmpresa;
      widgetUsuario.codigoUsuario = widget.codigoUsuario;
      widgetUsuario.codigoWidgetFiltro = widget.codigoWidgetFiltro;
      widgetUsuario.limite = widget.limite;
      widgetUsuario.coluna = widget.coluna;
      widgetUsuario.linha = widget.linha;
      widgetUsuario.subcoluna = widget.subcoluna;
      widgetUsuario.sublinha = widget.sublinha;
      await servicoMinhaDashboard.incluirWidgetUsuario(widgetUsuario);
      state.widgets.push(widget);
    }

    async function obterMeusWidgets() {
      state.widgets = await servicoMinhaDashboard.obterMeusWidgets(storeSistema.getters.codigoEmpresaOperacao());
    }

    onBeforeMount(async () => {
      limparTela();
      await obterMeusWidgets();
    });

    return {
      state,
      telaBase,
      storeSistema,
      abrirInclusaoWidget,
      incluirWidget,
    };
  },
});
