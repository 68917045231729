
import {
  defineComponent, reactive, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import SelecionarEmpresaCadastroCompartilhado from '@/components/MeuSistema/Empresas/SelecionarEmpresaCadastroCompartilhado.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import Card from '@/core/components/Tela/Card.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import ServicoPerfilUsuario from '@/servicos/MeuSistema/ServicoPerfilUsuario';
import { IOption } from '@/core/models/AntDesign/IOption';
import { IDTOControlePermissoes } from '@/models/DTO/MeuSistema/Usuarios/IDTOControlePermissoes';
import { IDTOControlePermissoesEstrutura } from '@/models/DTO/MeuSistema/Usuarios/IDTOControlePermissoesEstrutura';
import { IDTOModuloPermissoesControleEstrutura } from '@/models/DTO/MeuSistema/Usuarios/IDTOModuloPermissoesControleEstrutura';
import {
  IPerfilUsuario, IPerfilUsuarioEmpresa, IPerfilUsuarioPermissao, IPerfilUsuarioPermissaoDados,
} from '@/models/Entidades/MeuSistema/Usuarios/PerfisUsuario/IPerfilUsuario';
import { IDTOCategoriaPermissoesControleEstrutura } from '@/models/DTO/MeuSistema/Usuarios/IDTOCategoriaPermissoesControleEstrutura';

export default defineComponent({
  name: 'UsuarioModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Card,
    Icone,
    SelecionarEmpresaCadastroCompartilhado,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, defineEmpresasSelecionadasCadastroCompartilhado, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados,
      preencherPermissoesDados, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso, verificaConceitoParaApresentarEmpresas,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, defineTextoPadraoBotoes, apresentarBarraProgresso, ocultarBarraProgresso, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoPerfilUsuario = new ServicoPerfilUsuario();
    telaBase.identificadorRecurso = 'CADASTRO_PERFIS_USUARIOS';
    telaBase.identificadorPermissao = 'PER_CADASTRO_PERFIS_USUARIOS';

    const state = reactive({
      perfilUsuario: {} as IPerfilUsuario,
      listaUsuarios: [] as IOption[],
      listaPerfis: [] as IOption[],
      listaPermissoes: [] as IOption[],
      listaPermissoesEstrutura: [] as IDTOControlePermissoes[],
      listaControlePermissoes: [] as IDTOControlePermissoesEstrutura[],
      listaModulosControlePermissoes: [] as IDTOModuloPermissoesControleEstrutura[],
      valorAcaoMassa: 1,
      codigoControleAcaoMassa: 0,
      codigoModuloAcaoMassa: 0,
      codigoPermissaoPesquisa: 0,
      codigoTabControle: 1,
      codigoTabModulo: 1,
      codigoEmpresa: 0,
      codigoUsuario: 0,
      codigoPerfilUsuario: 0,
      codigoPerfilUsuarioAtual: 0,
      permiteSalvarPermissoes: false,
      permissoesCarregadas: false,
    });

    function objetoInicial() {
      telaBase.empresasSelecionadas = [];
      state.permissoesCarregadas = false;
      state.perfilUsuario = { codigo: 0, ativo: true } as IPerfilUsuario;
      state.perfilUsuario.empresas = [] as IPerfilUsuarioEmpresa[];
      state.perfilUsuario.permissoes = [] as IPerfilUsuarioPermissao[];
      state.codigoTabControle = 1;
      state.codigoTabModulo = 1;
    }

    async function obterControlePermissoesPerfilUsuario() {
      state.permissoesCarregadas = false;
      state.listaPermissoes = [];
      state.listaPermissoesEstrutura = await servicoPerfilUsuario.obterPermissoesControle(state.perfilUsuario.codigo, telaBase.empresasSelecionadas);
      state.listaControlePermissoes = [];
      state.listaModulosControlePermissoes = [];

      const permissoes:IDTOControlePermissoesEstrutura = {} as IDTOControlePermissoesEstrutura;
      permissoes.tipoPermissao = ETipoPermissao.Dados;
      permissoes.descricao = 'Permissões';
      permissoes.modulos = [];
      state.listaControlePermissoes.push(permissoes);
      const autorizacoes:IDTOControlePermissoesEstrutura = {} as IDTOControlePermissoesEstrutura;
      autorizacoes.tipoPermissao = ETipoPermissao.Autorizacoes;
      autorizacoes.descricao = 'Autorizações';
      autorizacoes.modulos = [];
      state.listaControlePermissoes.push(autorizacoes);

      const widgets:IDTOControlePermissoesEstrutura = {} as IDTOControlePermissoesEstrutura;
      widgets.tipoPermissao = ETipoPermissao.Widgets;
      widgets.descricao = 'Widgets';
      widgets.modulos = [];
      state.listaControlePermissoes.push(widgets);

      const relatorios:IDTOControlePermissoesEstrutura = {} as IDTOControlePermissoesEstrutura;
      relatorios.tipoPermissao = ETipoPermissao.Relatorios;
      relatorios.descricao = 'Relatórios';
      relatorios.modulos = [];
      state.listaControlePermissoes.push(relatorios);

      for (let i = 0; i < state.listaPermissoesEstrutura.length; (i += 1)) {
        const permissao = state.listaPermissoesEstrutura[i];

        const controleLocalizado = state.listaControlePermissoes.find((c) => c.tipoPermissao === permissao.tipoPermissao);

        if (controleLocalizado !== undefined) {
          let moduloLocalizado = controleLocalizado.modulos.find((c) => c.codigo === permissao.codigoModulo);

          if (moduloLocalizado === undefined) {
            const modulo:IDTOModuloPermissoesControleEstrutura = {} as IDTOModuloPermissoesControleEstrutura;
            modulo.codigo = permissao.codigoModulo;
            modulo.titulo = permissao.tituloModulo;
            modulo.categorias = [];
            controleLocalizado.modulos.push(modulo);

            const moduloUnico = state.listaModulosControlePermissoes.find((c) => c.codigo === permissao.codigoModulo);
            if (moduloUnico === undefined) {
              state.listaModulosControlePermissoes.push(modulo);
            }
          }

          if (moduloLocalizado === undefined) {
            moduloLocalizado = controleLocalizado.modulos.find((c) => c.codigo === permissao.codigoModulo);
          }

          if (moduloLocalizado !== undefined) {
            let categoriaLocalizada = moduloLocalizado.categorias.find((c) => c.codigo === permissao.codigoCategoriaPermissao);

            if (categoriaLocalizada === undefined) {
              const categoria:IDTOCategoriaPermissoesControleEstrutura = {} as IDTOCategoriaPermissoesControleEstrutura;
              categoria.codigo = permissao.codigoCategoriaPermissao;
              categoria.descricao = permissao.descricaoCategoriaPermissao;
              categoria.visualizar = true;
              categoria.incluir = true;
              categoria.alterar = true;
              categoria.excluir = true;
              categoria.imprimir = true;
              categoria.permissoes = [];
              moduloLocalizado.categorias.push(categoria);
            }

            if (categoriaLocalizada === undefined) {
              categoriaLocalizada = moduloLocalizado.categorias.find((c) => c.codigo === permissao.codigoCategoriaPermissao);
            }

            if (categoriaLocalizada !== undefined) {
              if (permissao.tipoPermissao === ETipoPermissao.Dados) {
                if (!permissao.visualizar) { categoriaLocalizada.visualizar = false; }
                if (!permissao.incluir) { categoriaLocalizada.incluir = false; }
                if (!permissao.alterar) { categoriaLocalizada.alterar = false; }
                if (!permissao.excluir) { categoriaLocalizada.excluir = false; }
                if (!permissao.imprimir) { categoriaLocalizada.imprimir = false; }
              } else {
                permissao.visualizar = permissao.codigoPermissaoLiberada > 0;
                if (!permissao.visualizar) { categoriaLocalizada.visualizar = false; }
              }

              categoriaLocalizada.permissoes.push(permissao);
            }
          }
        }
      }

      state.permissoesCarregadas = true;
    }
    async function atualizarSelecaoEmpresas() {
      await obterControlePermissoesPerfilUsuario();
    }

    async function preparaComportamentoTela() {
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        state.perfilUsuario = await servicoPerfilUsuario.obter(props.operacao.codigoRegistro);
        telaBase.empresasSelecionadas = [];
        state.perfilUsuario.empresas.forEach((perfilUsuarioEmpresa) => {
          telaBase.empresasSelecionadas.push(perfilUsuarioEmpresa.codigoEmpresa);
        });
      } else {
        await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
      }
      await atualizarSelecaoEmpresas();
    }

    function preparaPersistenciaEmpresas() {
      const perfilUsuarioEmpresas : IPerfilUsuarioEmpresa[] = [];
      if (telaBase.empresasSelecionadas.length > 0) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          const empresaExistente = state.perfilUsuario.empresas.find((c) => c.codigoEmpresa === codigoEmpresa);
          if (empresaExistente !== undefined) {
            perfilUsuarioEmpresas.push(empresaExistente);
          } else {
            const perfilUsuarioEmpresa: IPerfilUsuarioEmpresa = { codigo: 0, codigoPerfilUsuario: state.perfilUsuario.codigo, codigoEmpresa };
            perfilUsuarioEmpresas.push(perfilUsuarioEmpresa);
          }
        });
      }
      state.perfilUsuario.empresas = perfilUsuarioEmpresas;
    }

    function preparaPersistenciaPermissoes() {
      const listaPermissoes:IPerfilUsuarioPermissao[] = [];

      state.listaControlePermissoes.forEach((controle) => {
        controle.modulos.forEach((modulo) => {
          modulo.categorias.forEach((categoria) => {
            categoria.permissoes.forEach((permissao) => {
              const permissaoPersistencia:IPerfilUsuarioPermissao = {} as IPerfilUsuarioPermissao;
              permissaoPersistencia.codigo = permissao.codigoPermissaoLiberada;
              permissaoPersistencia.codigoPerfilUsuario = state.perfilUsuario.codigo;
              permissaoPersistencia.codigoPermissao = permissao.codigoPermissao;
              permissaoPersistencia.tipoPermissao = permissao.tipoPermissao;

              if (permissao.tipoPermissao === ETipoPermissao.Dados) {
                permissaoPersistencia.permissaoDados = {} as IPerfilUsuarioPermissaoDados;
                permissaoPersistencia.permissaoDados.codigo = permissao.codigoPermissaoDados;
                permissaoPersistencia.permissaoDados.codigoPerfilUsuarioPermissao = permissaoPersistencia.codigo;
                permissaoPersistencia.permissaoDados.visualizar = permissao.visualizar;
                permissaoPersistencia.permissaoDados.incluir = permissao.incluir;
                permissaoPersistencia.permissaoDados.alterar = permissao.alterar;
                permissaoPersistencia.permissaoDados.excluir = permissao.excluir;
                permissaoPersistencia.permissaoDados.imprimir = permissao.imprimir;

                listaPermissoes.push(permissaoPersistencia);
              } else if (permissao.visualizar) {
                listaPermissoes.push(permissaoPersistencia);
              }
            });
          });
        });
      });

      state.perfilUsuario.permissoes = listaPermissoes;
    }
    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      preparaPersistenciaEmpresas();
      preparaPersistenciaPermissoes();
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        apresentarBarraProgresso('Aguarde por favor, estamos cadastrando o perfil...');
        retorno = await servicoPerfilUsuario.incluir(state.perfilUsuario);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        apresentarBarraProgresso('Aguarde por favor, estamos salvando as informações do perfil de usuário...');
        retorno = await servicoPerfilUsuario.alterar(state.perfilUsuario);
      }

      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          const telaOperacao: ITelaOperacao = props.operacao;
          telaOperacao.codigoRegistro = 0;
          telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
          modalBase.computedOperacao = telaOperacao;
          await preparaComportamentoTela();
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    async function pesquisarPermissao(valorPesquisa: any) {
      clearTimeout(telaBase.debounce);
      telaBase.debounce = setTimeout(async () => {
        const listaPermissoes = state.listaPermissoesEstrutura.filter((c) => c.descricaoPermissao.includes(valorPesquisa));

        state.listaPermissoes = listaPermissoes.map((permissao) => ({
          label: permissao.descricaoPermissao,
          value: permissao.codigoPermissao,
        }));
      }, 600);
    }

    function aplicarValorEmMassaModuloPermissoes(modulo: IDTOModuloPermissoesControleEstrutura, valor:boolean) {
      for (let c = 0; c < modulo.categorias.length; (c += 1)) {
        const categoria = modulo.categorias[c];
        categoria.visualizar = valor;
        categoria.incluir = valor;
        categoria.alterar = valor;
        categoria.excluir = valor;
        categoria.imprimir = valor;

        for (let p = 0; p < categoria.permissoes.length; (p += 1)) {
          const permissao = categoria.permissoes[p];
          if (permissao.tipoPermissao === ETipoPermissao.Dados) {
            permissao.visualizar = valor;
            permissao.incluir = valor;
            permissao.alterar = valor;
            permissao.excluir = valor;
            permissao.imprimir = valor;
          } else {
            permissao.visualizar = valor;
          }
        }
      }
    }

    async function aplicarValorPermissoesEmMassa() {
      const valorAplicar = state.valorAcaoMassa === 1;

      if (state.codigoControleAcaoMassa === 0) {
        for (let i = 0; i < state.listaControlePermissoes.length; (i += 1)) {
          const controle = state.listaControlePermissoes[i];
          if (state.codigoModuloAcaoMassa === 0) {
            for (let m = 0; m < controle.modulos.length; (m += 1)) {
              aplicarValorEmMassaModuloPermissoes(controle.modulos[m], valorAplicar);
            }
          } else {
            const modulo = controle.modulos.find((c) => c.codigo === state.codigoModuloAcaoMassa);
            if (modulo !== undefined) {
              aplicarValorEmMassaModuloPermissoes(modulo, valorAplicar);
            }
          }
        }
      } else {
        const controle = state.listaControlePermissoes.find((c) => c.tipoPermissao === state.codigoControleAcaoMassa);
        if (controle !== undefined) {
          if (state.codigoModuloAcaoMassa === 0) {
            for (let m = 0; m < controle.modulos.length; (m += 1)) {
              aplicarValorEmMassaModuloPermissoes(controle.modulos[m], valorAplicar);
            }
          } else {
            const modulo = controle.modulos.find((c) => c.codigo === state.codigoModuloAcaoMassa);
            if (modulo !== undefined) {
              aplicarValorEmMassaModuloPermissoes(modulo, valorAplicar);
            }
          }
        }
      }

      state.permiteSalvarPermissoes = true;
    }

    async function removerDestaquePermissao(codigoPermissao: number) {
      clearTimeout(telaBase.debounce);
      telaBase.debounce = setTimeout(async () => {
        const permissaoElemento = document.getElementById(`PER-${codigoPermissao}`);
        if (permissaoElemento !== null) {
          permissaoElemento.classList.remove('ss-permissao-encontrada');
        }
      }, 3000);
    }

    async function localizarPermissao(codigoPermissao: number) {
      const permissao = state.listaPermissoesEstrutura.find((c) => c.codigoPermissao === codigoPermissao);
      if (permissao !== undefined) {
        state.codigoTabControle = permissao.tipoPermissao;
        state.codigoTabModulo = (permissao.tipoPermissao + permissao.codigoModulo);

        clearTimeout(telaBase.debounce);
        telaBase.debounce = setTimeout(async () => {
          const permissaoElemento = document.getElementById(`PER-${codigoPermissao}`);
          if (permissaoElemento !== null) {
            permissaoElemento.classList.add('ss-permissao-encontrada');
            permissaoElemento.scrollIntoView();
            state.codigoPermissaoPesquisa = 0;
            await removerDestaquePermissao(codigoPermissao);
          }
        }, 600);
      }
    }

    async function manipularPermissoesDados(valor:boolean, codigoModulo: number, codigoCategoria: number, tipoPermissaoDados: EPermissaoDados) {
      const controleLocalizado = state.listaControlePermissoes.find((c) => c.tipoPermissao === ETipoPermissao.Dados);
      if (controleLocalizado !== undefined) {
        const moduloLocalizado = controleLocalizado.modulos.find((c) => c.codigo === codigoModulo);
        if (moduloLocalizado !== undefined) {
          const categoriaLocalizada = moduloLocalizado.categorias.find((c) => c.codigo === codigoCategoria);
          if (categoriaLocalizada !== undefined) {
            for (let i = 0; i < categoriaLocalizada.permissoes.length; (i += 1)) {
              const permissao = categoriaLocalizada.permissoes[i];
              switch (tipoPermissaoDados) {
                case EPermissaoDados.Visualizar:
                  permissao.visualizar = valor;
                  break;

                case EPermissaoDados.Incluir:
                  permissao.incluir = valor;
                  break;

                case EPermissaoDados.Alterar:
                  permissao.alterar = valor;
                  break;

                case EPermissaoDados.Excluir:
                  permissao.excluir = valor;
                  break;

                case EPermissaoDados.Imprimir:
                  permissao.imprimir = valor;
                  break;
                default:
              }
            }
          }
        }
        state.permiteSalvarPermissoes = true;
      }
    }

    async function manipularPermissoes(valor:boolean, codigoModulo: number, codigoCategoria: number, tipoPermissao: ETipoPermissao) {
      const controleLocalizado = state.listaControlePermissoes.find((c) => c.tipoPermissao === tipoPermissao);
      if (controleLocalizado !== undefined) {
        const moduloLocalizado = controleLocalizado.modulos.find((c) => c.codigo === codigoModulo);
        if (moduloLocalizado !== undefined) {
          const categoriaLocalizada = moduloLocalizado.categorias.find((c) => c.codigo === codigoCategoria);
          if (categoriaLocalizada !== undefined) {
            for (let i = 0; i < categoriaLocalizada.permissoes.length; (i += 1)) {
              const permissao = categoriaLocalizada.permissoes[i];
              permissao.visualizar = valor;
            }
          }
        }
      }
      state.permiteSalvarPermissoes = true;
    }

    async function checarCategoriaPermissoesDados(codigoModulo: number, codigoCategoria: number, tipoPermissaoDados: EPermissaoDados) {
      const controleLocalizado = state.listaControlePermissoes.find((c) => c.tipoPermissao === ETipoPermissao.Dados);
      if (controleLocalizado !== undefined) {
        const moduloLocalizado = controleLocalizado.modulos.find((c) => c.codigo === codigoModulo);
        if (moduloLocalizado !== undefined) {
          const categoriaLocalizada = moduloLocalizado.categorias.find((c) => c.codigo === codigoCategoria);

          if (categoriaLocalizada !== undefined) {
            if (tipoPermissaoDados === EPermissaoDados.Visualizar) {
              const listaPermissoesDesmarcadas = categoriaLocalizada.permissoes.find((c) => c.visualizar === false);
              categoriaLocalizada.visualizar = listaPermissoesDesmarcadas === undefined;
            } else if (tipoPermissaoDados === EPermissaoDados.Incluir) {
              const listaPermissoesDesmarcadas = categoriaLocalizada.permissoes.find((c) => c.incluir === false);
              categoriaLocalizada.incluir = listaPermissoesDesmarcadas === undefined;
            } else if (tipoPermissaoDados === EPermissaoDados.Alterar) {
              const listaPermissoesDesmarcadas = categoriaLocalizada.permissoes.find((c) => c.alterar === false);
              categoriaLocalizada.alterar = listaPermissoesDesmarcadas === undefined;
            } else if (tipoPermissaoDados === EPermissaoDados.Excluir) {
              const listaPermissoesDesmarcadas = categoriaLocalizada.permissoes.find((c) => c.excluir === false);
              categoriaLocalizada.excluir = listaPermissoesDesmarcadas === undefined;
            } else if (tipoPermissaoDados === EPermissaoDados.Imprimir) {
              const listaPermissoesDesmarcadas = categoriaLocalizada.permissoes.find((c) => c.imprimir === false);
              categoriaLocalizada.imprimir = listaPermissoesDesmarcadas === undefined;
            }
          }
        }
        state.permiteSalvarPermissoes = true;
      }
    }
    async function checarCategoriaPermissoes(codigoModulo: number, codigoCategoria: number, tipoPermissao: ETipoPermissao) {
      const controleLocalizado = state.listaControlePermissoes.find((c) => c.tipoPermissao === tipoPermissao);
      if (controleLocalizado !== undefined) {
        const moduloLocalizado = controleLocalizado.modulos.find((c) => c.codigo === codigoModulo);
        if (moduloLocalizado !== undefined) {
          const categoriaLocalizada = moduloLocalizado.categorias.find((c) => c.codigo === codigoCategoria);
          if (categoriaLocalizada !== undefined) {
            const listaPermissoesDesmarcadas = categoriaLocalizada.permissoes.find((c) => c.visualizar === false);
            categoriaLocalizada.visualizar = listaPermissoesDesmarcadas === undefined;
          }
        }
        state.permiteSalvarPermissoes = true;
      }
    }
    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);
        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados);
        await preparaComportamentoTela();
        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        verificaConceitoParaApresentarEmpresas();
        state.codigoTabControle = 1;
        state.codigoTabModulo = state.listaControlePermissoes[0].tipoPermissao + state.listaControlePermissoes[0].modulos[0].codigo;
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      ETipoPermissao,
      salvar,
      objetoInicial,
      EPermissaoDados,
      atualizarSelecaoEmpresas,
      manipularPermissoesDados,
      manipularPermissoes,
      checarCategoriaPermissoesDados,
      checarCategoriaPermissoes,
      pesquisarPermissao,
      aplicarValorPermissoesEmMassa,
      localizarPermissao,
    };
  },
});
