
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import Icone from '@/core/components/Icone.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import BuscaAvancada from '@/core/components/BuscaAvancada/BuscaAvancada.vue';
import { IBuscaAvancada } from '@/core/models/BuscaAvancada/IBuscaAvancada';
import { IListaPaginadaMetaData } from '@/core/models/Consulta/IListaPaginada';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IPagination } from '@/core/models/AntDesign/IPagination';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EVinculoEmpresa } from '@/core/models/Enumeradores/EEVinculoEmpresa';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import storeSistema from '@/store/storeSistema';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { useGradeBase } from '@/core/composables/GradeBase';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import Card from '@/core/components/Tela/Card.vue';
import { IFiltroGenericoAdicionado } from '@/core/models/BuscaAvancada/IFiltroGenericoAdicionado';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { EOperadorLogico } from '@/core/models/Enumeradores/EOperadorLogico';
import { ETipoDado } from '@/core/models/Enumeradores/ETipoDado';
import { ECondicao } from '@/core/models/Enumeradores/ECondicao';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { IRequisicaoAutorizacao } from '@/models/Entidades/MeuSistema/IRequisicaoAutorizacao';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { EStatusRequisicaoAutorizacao } from '@/models/Enumeradores/MeuSistema/EStatusRequisicaoAutorizacao';
import ServicoControleAutorizacoes from '@/servicos/MeuSistema/ServicoControleAutorizacoes';
import RequisicaoAutorizacaoModal from './RequisicaoAutorizacaoModal.vue';
import RequisicaoAutorizacaoRespostaModal from './RequisicaoAutorizacaoRespostaModal.vue';

export default defineComponent({
  name: 'RequisicaoAutorizacaoIndex',
  components: {
    Icone,
    Card,
    MensagemSemDados,
    BuscaAvancada,
    RequisicaoAutorizacaoModal,
    RequisicaoAutorizacaoRespostaModal,
  },
  setup(props) {
    const servicoControleAutorizacoes = new ServicoControleAutorizacoes();
    const {
      telaBase, obterPermissoes, preencherEmpresasDisponiveis,
      defineEmpresasSelecionadasComPermissao, filtrarPermissaoDadosUsuarioMultiEmpresas,
      adicionarAtalho, removerAtalho, apresentarMensagemAlerta,
    } = useTelaBase();
    const {
      gradeBase, preencheOrdenacaoSelecionada, ordenacaoAtiva,
    } = useGradeBase();

    const state = reactive({
      identificadorRecurso: 'CONTROLE_AUTORIZACOES',
      identificadorPermissao: 'AUT_CONTROLE_AUTORIZACOES',
      empresaSelecionada: 0,
      codigosResposta: [] as number[],
      requisicaoSelecionada: {} as IRequisicaoAutorizacao,
      exibirDetalhe: false,
      exibirResposta: false,
      requisicoes: [] as IRequisicaoAutorizacao[],
      buscaAvancada: {} as IBuscaAvancada,
      paginacao: {} as IPagination,
      paginacaoApi: { totalRegistros: 0, paginaAtual: 1 } as IListaPaginadaMetaData,
      timerRegistros: 0,
      totalRegistrosComMascara: '100',
    });

    gradeBase.layoutMobile = UtilitarioDispositivo.larguraTelaMobile();

    function preencherColunas() {
      if (gradeBase.layoutMobile) {
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Requisição', dataIndex: 'requisicaoAutorizacao', key: 'RequisicaoAutorizacao', position: 2, visible: true,
          },
        ];
      } else {
        gradeBase.colunas = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false, fixed: 'left',
          },
          {
            title: 'Status', dataIndex: 'status', key: 'Status', position: 2, visible: true, ordering: true, align: 'center', width: 100, fixed: 'left',
          },
          {
            title: 'Solicitante', dataIndex: 'nomeUsuarioRequerente', key: 'NomeUsuarioRequerente', position: 3, visible: true, width: 120, fixed: 'left',
          },
          {
            title: 'Permissão', dataIndex: 'descricaoPermissao', key: 'DescricaoPermissao', position: 5, visible: true, width: 150, fixed: 'left',
          },
          {
            title: 'Dt Solicitação', dataIndex: 'dataRequisicao', key: 'DataRequisicao', position: 6, visible: true, align: 'center', width: 120, customRenderRow: ECustomRenderRow.MascararData, fixed: 'left',
          },
          {
            title: 'Detalhes', dataIndex: 'motivoRequisicao', key: 'MotivoRequisicao', position: 7, visible: true, ordering: true, width: 350, customRenderRow: ECustomRenderRow.HTMLPersonalizado,
          },
          {
            title: 'Ações', key: 'acoes', position: 8, visible: true, fixed: 'right', width: 50, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
          },
        ];
      }
    }

    async function buscarRequisicoes() {
      state.requisicoes = [];

      gradeBase.filtrosAplicados = [];
      state.buscaAvancada.filtrosAdicionados.forEach((item) => {
        gradeBase.filtrosAplicados.push(item.filtro);
      });

      const parametrosConsulta = {} as IParametrosConsulta;
      parametrosConsulta.empresas = [state.empresaSelecionada];
      parametrosConsulta.numeroPagina = state.paginacao.current;
      parametrosConsulta.qtdeRegistrosPagina = state.paginacao.pageSize;
      parametrosConsulta.qtdeRegistrosTotal = state.paginacao.total;
      parametrosConsulta.ordenacao = Array<string>();

      gradeBase.ordenacaoSelecionada.forEach((item) => {
        parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
      });

      if (!UtilitarioGeral.validaLista(gradeBase.ordenacaoSelecionada)) {
        parametrosConsulta.ordenacao.push('DataRequisicao|ASC');
      }

      telaBase.carregando = true;
      state.codigosResposta = [] as number[];
      gradeBase.codigosSelecionados = [];
      const listaPaginada = await servicoControleAutorizacoes.buscarRequisicoes(parametrosConsulta, gradeBase.filtrosAplicados);
      state.requisicoes = listaPaginada.dados;
      state.paginacaoApi = listaPaginada.metaData;
      telaBase.carregando = false;
    }

    async function aplicarFiltros() {
      state.paginacao.current = 1;
      await buscarRequisicoes();
    }

    function adicionaFiltroPadrao() {
      const filtroStatusPendente = {} as IFiltroGenericoAdicionado;
      state.buscaAvancada.chaveFiltrosAdicionados += 1;
      filtroStatusPendente.codigo = state.buscaAvancada.chaveFiltrosAdicionados;
      filtroStatusPendente.filtro = {} as IFiltroGenerico;
      filtroStatusPendente.filtro.identificador = 'Status';
      filtroStatusPendente.apresentacao = 'Status';
      filtroStatusPendente.filtro.tipoDado = ETipoDado.Inteiro;
      filtroStatusPendente.filtro.operador = EOperadorLogico.E;
      filtroStatusPendente.filtro.condicao = ECondicao.Igual;
      filtroStatusPendente.filtro.valores = [String(EStatusRequisicaoAutorizacao.Pendente)];
      filtroStatusPendente.detalhes = 'igual a: Pendente';
      state.buscaAvancada.filtrosAdicionados.push(filtroStatusPendente);
    }

    async function ordenarDados(key: string, sort: string) {
      await preencheOrdenacaoSelecionada(key, sort);
      await buscarRequisicoes();
    }

    async function alterarQuantidadeRegistroTotal(valor: any) {
      clearTimeout(state.timerRegistros);
      let totalRegistros = 100;
      state.timerRegistros = setTimeout(async () => {
        if (UtilitarioGeral.valorValido(valor)) {
          const limiteRegistros = UtilitarioMascara.removerMascaraNumero(valor);
          if (limiteRegistros > 0) {
            totalRegistros = limiteRegistros;
          }
        }
        state.paginacao.total = totalRegistros;
        state.totalRegistrosComMascara = UtilitarioMascara.mascararNumeroInteiro(totalRegistros);
        await buscarRequisicoes();
      }, 600);
    }

    const mudarSelecao = (selectedRowKeys: any) => {
      gradeBase.codigosSelecionados = selectedRowKeys;
      const codigosResposta = [] as number[];
      gradeBase.codigosSelecionados.forEach((s) => {
        const index = state.requisicoes.findIndex((r) => r.codigo === s);
        if (state.requisicoes[index].status === EStatusRequisicaoAutorizacao.Pendente) {
          codigosResposta.push(s);
        }
      });
      state.codigosResposta = codigosResposta;
    };

    async function mudarPagina(current: number, pageSize: number) {
      state.paginacao.current = current;
      state.paginacao.pageSize = pageSize;
      await buscarRequisicoes();
    }

    async function mostrarNovaPagina(current: number, pageSize: number) {
      state.paginacao.current = 1;
      state.paginacao.pageSize = pageSize;
      await buscarRequisicoes();
    }

    function abrirDetalhe(requisicao: IRequisicaoAutorizacao) {
      state.requisicaoSelecionada = requisicao;
      state.exibirDetalhe = true;
    }

    function abrirResposta() {
      if (state.codigosResposta.length === 0) {
        apresentarMensagemAlerta('Nenhuma requisição pendente selecionada!');
        return;
      }
      state.exibirResposta = true;
    }

    function estilizarMotivoRequisicao(motivoRequisicao: string) {
      let motivoEstilizado = motivoRequisicao;
      // Define os termos que devem ser colocados em negrito
      const termos = ['Conta a Pagar Nº', 'Pessoa:', 'Categoria:', 'Valor:', 'Obs:'];

      // Substitui os termos na string por versões em negrito
      termos.forEach((termo) => {
        const regex = new RegExp(`(${termo})`, 'g');
        motivoEstilizado = motivoEstilizado.replace(regex, '<b>$1</b>');
      });

      return motivoEstilizado;
    }

    onBeforeMount(async () => {
      state.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      telaBase.identificadorRecurso = state.identificadorRecurso;
      telaBase.identificadorPermissao = state.identificadorPermissao;
      await obterPermissoes(ETipoPermissao.Dados);
      await preencherEmpresasDisponiveis();
      await defineEmpresasSelecionadasComPermissao();
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas([state.empresaSelecionada]);
      telaBase.propriedadesConsulta = await servicoControleAutorizacoes.obterPropriedadesConsultaRequisicoes();
      if (telaBase.propriedadesConsulta.length > 0) {
        state.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
        state.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
      }
      state.buscaAvancada.filtrosAdicionados = [];
      state.buscaAvancada.chaveFiltrosAdicionados = 0;
      preencherColunas();
      state.paginacao.total = 100;
      state.paginacao.showSizeChanger = true;
      state.paginacao.pageSize = 100;
      state.paginacao.pageSizeOptions = ['10', '50', '100', '500', '1000'];
      adicionaFiltroPadrao();
      buscarRequisicoes();
    });

    return {
      state,
      props,
      window,
      EVinculoEmpresa,
      ETipoArquivo,
      ECustomRenderRow,
      UtilitarioMascara,
      UtilitarioData,
      storeSistema,
      telaBase,
      gradeBase,
      ordenacaoAtiva,
      aplicarFiltros,
      mudarPagina,
      ordenarDados,
      mostrarNovaPagina,
      alterarQuantidadeRegistroTotal,
      buscarRequisicoes,
      adicionarAtalho,
      removerAtalho,
      abrirDetalhe,
      servicoControleAutorizacoes,
      mudarSelecao,
      abrirResposta,
      estilizarMotivoRequisicao,
    };
  },
});
