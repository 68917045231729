
import {
  computed, defineComponent, reactive, watch,
} from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { useTelaBase } from '@/core/composables/TelaBase';
import ServicoUsuario from '@/servicos/MeuSistema/ServicoUsuario';
import ServicoPerfilUsuario from '@/servicos/MeuSistema/ServicoPerfilUsuario';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import { IDTOUsuarioAgrupadoPerfil } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioAgrupadoPerfil';
import { IParametrosConsultaAgrupadaPerfil } from '@/models/DTO/MeuSistema/Usuarios/IParametrosConsultaAgrupadaPerfil';
import Icone from '@/core/components/Icone.vue';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';

export default defineComponent({
  name: 'SelecionarUsuarioAgrupadoPerfilModal',
  components: {
    Icone,
    SelecionarEmpresa,
  },
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    titulo: {
      type: String,
      default: 'Selecionar usuários',
    },
    apresentarEmpresas: {
      type: Boolean,
      default: true,
    },
    ignorarUsuarioAutenticado: {
      type: Boolean,
      default: true,
    },
    apenasAtivos: {
      type: Boolean,
      default: true,
    },
    empresas: {
      type: Array as () => number[],
      default: () => [],
    },
  },
  emits: ['update:visivel', 'usuariosSelecionados'],
  setup(props, { emit }) {
    const { montaOpcoesComListaConsultaRapida, apresentarMensagemAlerta } = useTelaBase();

    let debounce = 0;
    const servicoUsuario = new ServicoUsuario();
    const servicoPerfilUsuario = new ServicoPerfilUsuario();

    const state = reactive({
      tipoMensagem: 'info',
      mensagem: 'Filtre os usuários através dos campos acima',
      empresasSelecionadas: [] as number[],
      usuariosSelecionadas: [] as number[],
      perfisSelecionados: [] as number[],
      nomeUsuario: '',
      listaPerfis: [] as IOption[],
      listaUsuariosAgrupados: [] as IDTOUsuarioAgrupadoPerfil[],
      selecionarTodosUsuarios: false,
      carregando: false,
      computedVisivel: computed({
        get: () => props.visivel,
        set: (val: boolean) => {
          emit('update:visivel', val);
        },
      }),
    });

    function verificaSelecaoTodosUsuarios() {
      const perfilDesmarcado = state.listaUsuariosAgrupados.find((c) => c.selecionado === false);
      state.selecionarTodosUsuarios = (perfilDesmarcado === undefined);
    }

    function selecionarTodosUsuarios(selecionado:boolean) {
      for (let i = 0; i < state.listaUsuariosAgrupados.length; (i += 1)) {
        state.listaUsuariosAgrupados[i].selecionado = selecionado;
        for (let u = 0; u < state.listaUsuariosAgrupados[i].usuarios.length; (u += 1)) {
          state.listaUsuariosAgrupados[i].usuarios[u].selecionado = selecionado;
        }
      }
    }

    function selecionarUsuariosPerfil(codigoPerfil:number, selecionado:boolean) {
      const perfil = state.listaUsuariosAgrupados.find((c) => c.codigoPerfil === codigoPerfil);
      if (perfil !== undefined) {
        for (let i = 0; i < perfil.usuarios.length; (i += 1)) {
          perfil.usuarios[i].selecionado = selecionado;
        }
      }

      verificaSelecaoTodosUsuarios();
    }

    function checarUsuariosPerfil(codigoPerfil:number) {
      const perfil = state.listaUsuariosAgrupados.find((c) => c.codigoPerfil === codigoPerfil);
      if (perfil !== undefined) {
        const usuarioDesmarcado = perfil.usuarios.find((c) => c.selecionado === false);
        perfil.selecionado = (usuarioDesmarcado === undefined);
      }

      verificaSelecaoTodosUsuarios();
    }

    async function pesquisarUsuario() {
      state.listaUsuariosAgrupados = [];
      state.carregando = true;
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsulta: IParametrosConsultaAgrupadaPerfil = {
          empresas: state.empresasSelecionadas,
          ignorarUsuarioAutenticado: props.ignorarUsuarioAutenticado,
          apenasAtivos: props.apenasAtivos,
        };

        if (UtilitarioGeral.valorValido(state.nomeUsuario)) {
          parametrosConsulta.nomeUsuario = state.nomeUsuario;
        }

        if (UtilitarioGeral.validaLista(state.perfisSelecionados)) {
          parametrosConsulta.perfis = state.perfisSelecionados;
        }

        state.listaUsuariosAgrupados = await servicoUsuario.consultaAgrupadaPorPerfil(
          parametrosConsulta,
        );

        if (state.listaUsuariosAgrupados.length <= 0) {
          state.tipoMensagem = 'warning';
          state.mensagem = 'Nenhum usuário foi econtrado com estes filtros!';
        } else {
          state.tipoMensagem = 'info';
          state.mensagem = 'Filtre os usuários através dos campos acima';
          selecionarTodosUsuarios(false);
          state.carregando = false;
        }
      }, 600);
    }

    async function obterListaPerfilUsuario() {
      state.perfisSelecionados = [];
      state.listaPerfis = [];
      if (UtilitarioGeral.validaLista(state.empresasSelecionadas)) {
        const listaPerfis = await servicoPerfilUsuario.obterTodos(state.empresasSelecionadas, true);
        state.listaPerfis = montaOpcoesComListaConsultaRapida(listaPerfis);
      }
      await pesquisarUsuario();
    }

    async function confirmarSelecaoUsuarios() {
      const usuariosSelecionados:number[] = [];
      state.listaUsuariosAgrupados.forEach((perfil) => {
        perfil.usuarios.forEach((usuario) => {
          if (usuario.selecionado) {
            usuariosSelecionados.push(usuario.codigoUsuario);
          }
        });
      });

      if (UtilitarioGeral.validaLista(usuariosSelecionados)) {
        emit('usuariosSelecionados', usuariosSelecionados);
        state.computedVisivel = false;
      } else {
        apresentarMensagemAlerta('Nenhum usuário foi selecionado!');
      }
    }

    watch(async () => state.computedVisivel, async () => {
      if (state.computedVisivel) {
        state.nomeUsuario = '';
        state.selecionarTodosUsuarios = false;
        state.carregando = false;
        if (UtilitarioGeral.validaLista(props.empresas)) {
          state.empresasSelecionadas = props.empresas;
        } else {
          state.empresasSelecionadas = storeSistema.getters.empresasUsuarioAutenticado();
        }
        state.listaUsuariosAgrupados = [];
        await obterListaPerfilUsuario();
        await pesquisarUsuario();
      }
    });

    return {
      props,
      state,
      pesquisarUsuario,
      obterListaPerfilUsuario,
      confirmarSelecaoUsuarios,
      checarUsuariosPerfil,
      selecionarUsuariosPerfil,
      selecionarTodosUsuarios,
      verificaSelecaoTodosUsuarios,
    };
  },
});
