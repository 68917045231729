export enum ETempoNotificacaoAgendamento {
    NaoNotificar = 1,
    CincoMinutosAntes = 2,
    DezMinutosAntes = 3,
    QuinzeMinutosAntes = 4,
    TrintaMinutosAntes = 5,
    UmaHoraAntes = 6,
    DuasHorasAntes = 7,
    UmDiaAntes = 8,
    Personalizado = 9
}
