
import {
  defineComponent, reactive, onBeforeMount,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { IDTOWidgetUsuario } from '@/models/DTO/MeuSistema/Usuarios/IDTOWidgetUsuario';
import WidgetUsuario from './WidgetUsuario.vue';

export default defineComponent({
  name: 'WidgetsLinha',
  components: {
    WidgetUsuario,
  },
  props: {
    linha: {
      type: Number,
      required: true,
    },
    coluna: {
      type: Number,
      required: true,
    },
    widgets: {
      type: Array as () => IDTOWidgetUsuario[],
      required: true,
    },
  },
  setup(props) {
    const { telaBase } = useTelaBase();

    const state = reactive({
      qtdWidgets: 0,
    });

    function verificarSlotOcupado(subcoluna: number) {
      const index = props.widgets.findIndex((w) => w.coluna === props.coluna && w.linha === props.linha && w.subcoluna === subcoluna && w.sublinha === 0);
      return index > -1;
    }

    function obterIndiceWidget(subcoluna: number, sublinha: number) {
      const index = props.widgets.findIndex((w) => w.coluna === props.coluna && w.linha === props.linha && w.subcoluna === subcoluna && w.sublinha === sublinha);
      return index;
    }

    function obterQtdWidgets() {
      let contador = 0;
      props.widgets.forEach((w) => {
        if (w.coluna === props.coluna && w.linha === props.linha) {
          contador += 1;
        }
      });
      return contador;
    }

    function obterQtdWidgetsSubcoluna(subcoluna: number) {
      let contador = 0;
      props.widgets.forEach((w) => {
        if (w.coluna === props.coluna && w.linha === props.linha && w.subcoluna === subcoluna && w.sublinha > 0) {
          contador += 1;
        }
      });
      return contador;
    }

    onBeforeMount(async () => {
      state.qtdWidgets = obterQtdWidgets();
    });

    return {
      state,
      props,
      telaBase,
      verificarSlotOcupado,
      obterIndiceWidget,
      obterQtdWidgetsSubcoluna,
    };
  },
});
