
import { defineComponent } from 'vue';
import { IDTOAgendamento } from '@/models/DTO/MeuSistema/Agendamentos/IDTOAgendamento';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';

export default defineComponent({
  name: 'ResumoAgendamento',
  props: {
    agendamento: {
      type: Object as () => IDTOAgendamento,
      required: true,
    },
    codigoUsuarioAutenticado: {
      type: Number,
      required: true,
    },
    apresentarEmpresa: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return {
      props,
      UtilitarioGeral,
    };
  },
});
