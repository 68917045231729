
import {
  defineComponent, onMounted, reactive,
} from 'vue';
import { useRoute } from 'vue-router';
import Icone from '@/core/components/Icone.vue';
import storeSistema from '@/store/storeSistema';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import ServicoNotificacao from '@/servicos/MeuSistema/ServicoNotificacao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import router from '@/router';
import { IDTONotificacao } from '@/models/DTO/MeuSistema/Notificacoes/IDTONotificacao';
import { ETipoNotificacao } from '@/models/Enumeradores/MeuSistema/Notificacoes/ETipoNotificacao';
import { IParametrosNotificacao } from '@/models/DTO/MeuSistema/Notificacoes/IParametrosNotificacao';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';

export default defineComponent({
  name: 'Notificacoes',
  components: {
    TituloPadrao,
    Icone,
  },
  setup() {
    const {
      telaBase,
    } = useTelaBase();

    const servicoNotificacao = new ServicoNotificacao();
    telaBase.identificadorRecurso = 'NOTIFICACOES';

    const state = reactive({
      notificacoes: [] as IDTONotificacao[],
      buscandoNotificacoes: false,
      exibirNotificacoes: false,
      tipo: 0,
      lidas: false,
      pendentes: false,
    });

    const route = useRoute();
    state.notificacoes = [];
    telaBase.empresasSelecionadas = [];
    telaBase.empresasSelecionadas = storeSistema.getters.empresasUsuarioAutenticado();

    async function obterNotificacoes() {
      state.exibirNotificacoes = true;
      state.buscandoNotificacoes = true;

      const parametrosNotificacao: IParametrosNotificacao = {} as IParametrosNotificacao;
      parametrosNotificacao.tipo = state.tipo;
      parametrosNotificacao.lidas = state.lidas;
      parametrosNotificacao.pendentes = state.pendentes;
      parametrosNotificacao.quantidadeNotificacoes = 50;
      if (state.notificacoes.length > 0) {
        parametrosNotificacao.codigoUltimaNotificacao = state.notificacoes[state.notificacoes.length - 1].codigo;
      } else {
        parametrosNotificacao.codigoUltimaNotificacao = 0;
      }

      const notificacoes = await servicoNotificacao.obterNotificacoesUsuario(parametrosNotificacao);
      if (notificacoes.length > 0) {
        state.notificacoes.push(...notificacoes);
      }

      state.buscandoNotificacoes = false;
    }

    async function filtrarNotificacoes() {
      state.notificacoes = [];
      await obterNotificacoes();
    }

    async function excluirTodasNotificacoes() {
      await servicoNotificacao.excluirTodasNotificacoes();
      state.notificacoes = [];
      storeSistema.mutations.atualizarNotificacaoNaoLida(0);
      await obterNotificacoes();
    }

    async function acessarLinkNotificacao(notificacao: IDTONotificacao) {
      state.exibirNotificacoes = false;
      if (!notificacao.lida) {
        const retornoConfirmacao = await new ServicoNotificacao().confirmarLeitura(notificacao.codigo);
        if (retornoConfirmacao.status === EStatusRetornoRequisicao.Sucesso) {
          storeSistema.mutations.diminuirNotificacaoNaoLida();
        }
      }
      if (UtilitarioGeral.valorValido(notificacao.parametros)) {
        router.push({ path: `/${notificacao.rotaRecurso}${notificacao.parametros}` });
      } else {
        router.push({ path: `/${notificacao.rotaRecurso}` });
      }
    }
    function obterCorNotificacao(tipo: number): string {
      let cor = '#2196f3';
      if (tipo === ETipoNotificacao.Normal) {
        cor = '#2196f3';
      } else if (tipo === ETipoNotificacao.Importante) {
        cor = '#ffc107';
      } else if (tipo === ETipoNotificacao.Urgente) {
        cor = '#f44336';
      }
      return cor;
    }

    function obterAbreviacaoRecurso(recurso: string): string {
      let abreviacao = '';
      if (UtilitarioGeral.valorValido(recurso)) {
        abreviacao = recurso.length > 3 ? recurso.substring(0, 3) : recurso.substring(0, 1);
      }
      return abreviacao;
    }

    onMounted(async () => {
      await obterNotificacoes();
    });

    return {
      state,
      telaBase,
      storeSistema,
      UtilitarioData,
      UtilitarioGeral,
      obterNotificacoes,
      filtrarNotificacoes,
      excluirTodasNotificacoes,
      obterCorNotificacao,
      obterAbreviacaoRecurso,
      acessarLinkNotificacao,
    };
  },
});
