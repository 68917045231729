import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IAgendamento, IAgendamentoComentario } from '@/models/Entidades/MeuSistema/Agendamentos/IAgendamento';
import { IItemGenerico } from '@/core/models/IItemGenerico';
import { IParametrosAgendamento } from '@/models/DTO/MeuSistema/Agendamentos/IParametrosAgendamento';
import { IDTOAgendamento } from '@/models/DTO/MeuSistema/Agendamentos/IDTOAgendamento';
/**
 * Serviço de Agendamentos
 * Fornece todas regras de negócios e lógicas relacionado aos Agendamentos.
 */

class ServicoAgendamento implements IServicoBase<IAgendamento> {
  endPoint = 'agendamentos';

  apiERP = new ApiERP();

  public requisicaoSistema(): void {
    this.apiERP = new ApiERP(true);
  }

  public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
    const listaTeste = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
    return listaTeste;
  }

  public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
    return this.apiERP.consultaRapida(this.endPoint, parametrosConsultaRapida);
  }

  public async obterAgendamentos(parametrosAgendamento: IParametrosAgendamento): Promise<IDTOAgendamento[]> {
    let parametrosAdicionais = '';
    parametrosAdicionais = this.apiERP.obterParametroEmpresas(parametrosAgendamento.empresas);

    if (parametrosAgendamento.tipoAgendamento !== undefined) {
      parametrosAdicionais += `&TipoAgendamento=${parametrosAgendamento.tipoAgendamento}`;
    }
    if (parametrosAgendamento.codigosSelecionados !== undefined) {
      parametrosAgendamento.codigosSelecionados.forEach((codigo) => {
        parametrosAdicionais += `&CodigosSelecionados=${codigo}`;
      });
    }
    if (parametrosAgendamento.usuarios !== undefined) {
      parametrosAgendamento.usuarios.forEach((usuario) => {
        parametrosAdicionais += `&Usuarios=${usuario}`;
      });
    }
    if (parametrosAgendamento.pessoas !== undefined) {
      parametrosAgendamento.pessoas.forEach((pessoa) => {
        parametrosAdicionais += `&Pessoas=${pessoa}`;
      });
    }
    if (parametrosAgendamento.marcadores !== undefined) {
      parametrosAgendamento.marcadores.forEach((marcador) => {
        parametrosAdicionais += `&Marcadores=${marcador}`;
      });
    }
    if (parametrosAgendamento.tipoData !== undefined) {
      parametrosAdicionais += `&TipoData=${parametrosAgendamento.tipoData}`;
    }

    if (parametrosAgendamento.dataInicial !== undefined) {
      parametrosAdicionais += `&DataInicial=${parametrosAgendamento.dataInicial}`;
    }
    if (parametrosAgendamento.dataFinal !== undefined) {
      parametrosAdicionais += `&DataFinal=${parametrosAgendamento.dataFinal}`;
    }
    const result: any = await this.apiERP.get(`${this.endPoint}${parametrosAdicionais}`);
    return result.data;
  }

  public async consultarMarcadores(codigoUsuario: number, todosUsuarios: boolean, marcardor: string): Promise<string[]> {
    let parametrosAdicionais = '';
    parametrosAdicionais += `?CodigoUsuario=${codigoUsuario}`;
    parametrosAdicionais += `&TodosUsuarios=${todosUsuarios}`;
    parametrosAdicionais += `&Marcador=${marcardor}`;

    const result: any = await this.apiERP.get(`${this.endPoint}/marcadores${parametrosAdicionais}`);
    return result.data;
  }

  public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
    return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
  }

  public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
    return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
  }

  public obterIdentificacaoRegistroGradeCRUD(objeto: IAgendamento): string {
    return objeto.titulo;
  }

  public async incluir(agendamento: IAgendamento, repetir?: boolean, dataFinal?: string, intervaloRepeticao?: number): Promise<IRetornoRequisicao> {
    let parametrosAdicionais = '';

    if (repetir !== undefined) {
      parametrosAdicionais += `?Repetir=${repetir}`;
      if (dataFinal !== undefined) {
        parametrosAdicionais += `&DataFinalRepeticao=${dataFinal}`;
      }
      if (intervaloRepeticao !== undefined) {
        parametrosAdicionais += `&IntervaloRepeticao=${intervaloRepeticao}`;
      }
    }
    const result: any = await this.apiERP.post(`${this.endPoint}${parametrosAdicionais}`, agendamento);
    return result.data;
  }

  public async alterar(agendamento: IAgendamento): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/${agendamento.codigo}`, agendamento);
    return result.data;
  }

  public async obter(codigo: number, navegacao?: number, dataHoraInicio?:string): Promise<IAgendamento> {
    let parametrosAdicionais = '';

    if (navegacao !== undefined) {
      parametrosAdicionais += `?Navegacao=${navegacao}`;
      if (dataHoraInicio !== undefined) {
        parametrosAdicionais += `&DataHoraInicio=${dataHoraInicio}`;
      }
    }

    const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}${parametrosAdicionais}`);
    return result.data;
  }

  public async excluir(codigo: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}`);
    return result.data;
  }

  public async incluirComentario(codigoAgendamento: number, codigoEmpresa: number, comentario: string): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}/${codigoAgendamento}/empresa/${codigoEmpresa}/comentarios`, comentario);
    return result.data;
  }

  public async alterarComentario(codigoAgendamento: number, codigoEmpresa: number, codigoComentario: number, comentario: string): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/${codigoAgendamento}/empresa/${codigoEmpresa}/comentarios/${codigoComentario}`, comentario);
    return result.data;
  }

  public async obterComentario(codigoAgendamento: number, codigoComentario: number): Promise<IAgendamentoComentario> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${codigoAgendamento}/comentarios/${codigoComentario}`);
    return result.data;
  }

  public async excluirComentario(codigoAgendamento: number, codigoEmpresa: number, codigoComentario: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.delete(`${this.endPoint}/${codigoAgendamento}/empresa/${codigoEmpresa}/comentarios/${codigoComentario}`);
    return result.data;
  }

  public async obterListaTipoAgendamentos(): Promise<IItemGenerico[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/tipos`);
    return result.data;
  }

  public async obterListaTempoNotificacoes(): Promise<IItemGenerico[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/tempos-notificacao`);
    return result.data;
  }

  public async obterListaIntervaloRepeticao(): Promise<IItemGenerico[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/intervalo-repeticao`);
    return result.data;
  }

  public async obterListaTipoNotificacoes(): Promise<IItemGenerico[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/tipos-notificacoes`);
    return result.data;
  }
}

export default ServicoAgendamento;
