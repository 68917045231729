
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SelecionarUtilizacaoEmail',
  props: {
    utilizacao: {
      type: Number,
      default: 1,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:utilizacao', 'change'],
  setup(props, { emit }) {
    const computedUtilizacaoEmail = computed({
      get: () => props.utilizacao,
      set: (utilizacao: number) => {
        emit('update:utilizacao', utilizacao);
      },
    });

    function change() {
      emit('change', props.utilizacao);
    }

    return {
      props,
      change,
      computedUtilizacaoEmail,
    };
  },
});
