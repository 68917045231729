import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ant-row" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_4 = { class: "ant-row" }
const _hoisted_5 = { class: "ant-col ant-col-xs-12 ant-col-xl-12" }
const _hoisted_6 = { class: "ant-col ant-col-xs-12 ant-col-xl-12" }
const _hoisted_7 = { class: "ant-row" }
const _hoisted_8 = { class: "ant-col ant-col-xs-12 ant-col-xl-12" }
const _hoisted_9 = { class: "ant-input-wrapper ant-input-group" }
const _hoisted_10 = { class: "ant-input-group-addon" }
const _hoisted_11 = { class: "ant-col ant-col-xs-12 ant-col-xl-12" }
const _hoisted_12 = { class: "ant-input-wrapper ant-input-group" }
const _hoisted_13 = { class: "ant-input-group-addon" }
const _hoisted_14 = { class: "ant-row" }
const _hoisted_15 = { class: "ant-col ant-col-xs-12 ant-col-xl-12" }
const _hoisted_16 = { class: "ant-col ant-col-xs-4 ant-col-xl-4" }
const _hoisted_17 = { class: "ant-col ant-col-xs-4 ant-col-xl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_selecionar_empresa = _resolveComponent("selecionar-empresa")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_utilizacao_email = _resolveComponent("selecionar-utilizacao-email")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_requisicao_modal = _resolveComponent("requisicao-modal")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    keyboard: false,
    centered: "",
    visible: _ctx.modalBase.computedVisivel,
    "onUpdate:visible": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.modalBase.computedVisivel) = $event)),
    "wrap-class-name": "ss-modal-cadastros",
    width: 700
  }, {
    title: _withCtx(() => [
      _createTextVNode("Servidor de E-mail de Notificação")
    ]),
    closeIcon: _withCtx(() => [
      _createVNode(_component_icone, { nome: "voltar" })
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", null, [
        ((_ctx.props.operacao.tipoPermissaoDados === _ctx.EPermissaoDados.Incluir && _ctx.telaBase.permissaoDados.incluir) || (_ctx.props.operacao.tipoPermissaoDados === _ctx.EPermissaoDados.Visualizar && _ctx.telaBase.permissaoDados.alterar))
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("button", {
                type: "button",
                class: "ant-btn ant-btn-lg ant-btn-secondary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.salvar(true)))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvarNovo), 1)
              ]),
              _createElementVNode("button", {
                type: "button",
                class: "ant-btn ant-btn-lg ant-btn-primary ss-btn-acao-destaque",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.salvar(false)))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.modalBase.textoBotaoSalvar), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_skeleton, {
        loading: _ctx.telaBase.carregando
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form, { layout: "vertical" }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_a_form_item, {
                    label: "Empresas",
                    class: "ss-margin-campos",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_selecionar_empresa, {
                        "empresas-especificas": _ctx.telaBase.empresasDisponiveis,
                        "codigo-selecionado": _ctx.state.servidor.codigoEmpresa,
                        "onUpdate:codigoSelecionado": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.servidor.codigoEmpresa) = $event))
                      }, null, 8, ["empresas-especificas", "codigo-selecionado"])
                    ]),
                    _: 1
                  })
                ])
              ], 512), [
                [_vShow, _ctx.telaBase.apresentarEmpresas]
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_a_form_item, {
                    label: "Descrição",
                    class: "ss-margin-campos",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        maxlength: "250",
                        class: "ant-input",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.servidor.descricao) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.state.servidor.descricao]
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_a_form_item, {
                    label: "Servidor",
                    class: "ss-margin-campos",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        maxlength: "250",
                        class: "ant-input",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.servidor.servidor) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.state.servidor.servidor]
                      ])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_a_form_item, {
                    label: "E-mail",
                    class: "ss-margin-campos",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_9, [
                        _createElementVNode("span", _hoisted_10, [
                          _createVNode(_component_icone, {
                            class: "ss-icone",
                            nome: "email"
                          })
                        ]),
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "ant-input",
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.servidor.email) = $event))
                        }, null, 512), [
                          [_vModelText, _ctx.state.servidor.email]
                        ])
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_a_form_item, {
                    label: "Senha",
                    class: "ss-margin-campos",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_12, [
                        _createElementVNode("span", _hoisted_13, [
                          _createVNode(_component_icone, {
                            class: "ss-icone",
                            nome: "senha"
                          })
                        ]),
                        _withDirectives(_createElementVNode("input", {
                          type: "password",
                          class: "ant-input",
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.servidor.senha) = $event))
                        }, null, 512), [
                          [_vModelText, _ctx.state.servidor.senha]
                        ])
                      ])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_a_form_item, {
                    label: "Tipo de Utilização",
                    class: "ss-margin-campos",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_selecionar_utilizacao_email, {
                        utilizacao: _ctx.state.servidor.utilizacao,
                        "onUpdate:utilizacao": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.servidor.utilizacao) = $event))
                      }, null, 8, ["utilizacao"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_a_form_item, {
                    label: "Porta",
                    class: "ss-margin-campos",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        maxlength: "6",
                        class: "ant-input",
                        style: {"text-align":"right"},
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.servidor.porta) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.state.servidor.porta]
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_a_form_item, {
                    label: "Porta",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_checkbox, {
                        checked: _ctx.state.servidor.ssl,
                        "onUpdate:checked": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.servidor.ssl) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("SSL")
                        ]),
                        _: 1
                      }, 8, ["checked"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["loading"]),
      _createVNode(_component_requisicao_modal, {
        visivel: _ctx.modalBase.exibirRequisicaoModal,
        "onUpdate:visivel": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.modalBase.exibirRequisicaoModal) = $event)),
        apresentarBarraProgresso: _ctx.modalBase.apresentarBarraProgresso,
        fraseBarraProgresso: _ctx.modalBase.fraseBarraProgresso,
        retornoRequisicao: _ctx.modalBase.retornoRequisicao
      }, null, 8, ["visivel", "apresentarBarraProgresso", "fraseBarraProgresso", "retornoRequisicao"])
    ]),
    _: 1
  }, 8, ["visible"]))
}