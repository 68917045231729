
import { defineComponent, onBeforeMount, reactive } from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import PersonalizacaoTelaModal from '@/views/MeuSistema/PersonalizacoesTelas/PersonalizacaoTelaModal.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IDTOPersonalizacaoTela } from '@/models/DTO/MeuSistema/IDTOPersonalizacaoTela';
import ServicoPersonalizacaoTela from '@/servicos/MeuSistema/ServicoPersonalizacaoTela';

export default defineComponent({
  name: 'PersonalizacaoTela',
  components: {
    Icone,
    PersonalizacaoTelaModal,
  },
  setup() {
    const { apresentarMensagemSucesso, apresentarMensagemErro } = useTelaBase();
    const servicoPersonalizacaoTela = new ServicoPersonalizacaoTela();

    const state = reactive({
      exibirEditarPersonalizacao: false,
      edicaoPersonalizacao: {} as ITelaOperacao,
      listaPersonalizacoes: [] as IDTOPersonalizacaoTela[],
    });

    onBeforeMount(async () => {
      state.listaPersonalizacoes = await servicoPersonalizacaoTela.obterTodos();
    });

    function editar(codigo: number) {
      state.edicaoPersonalizacao = {} as ITelaOperacao;
      if (codigo === 0) {
        state.edicaoPersonalizacao.tipoPermissaoDados = EPermissaoDados.Incluir;
      } else {
        state.edicaoPersonalizacao.tipoPermissaoDados = EPermissaoDados.Alterar;
      }
      state.edicaoPersonalizacao.codigoRegistro = codigo;
      state.exibirEditarPersonalizacao = true;
    }

    async function confirmarEdicao() {
      state.listaPersonalizacoes = await servicoPersonalizacaoTela.obterTodos();
    }

    async function excluir(codigo: number, descricaoRecurso: string) {
      Modal.confirm({
        title: 'Deseja remover a personalização:',
        content: `${descricaoRecurso}?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          const retorno = await servicoPersonalizacaoTela.excluir(codigo);
          if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
            apresentarMensagemSucesso(retorno.mensagem);
            const indice = state.listaPersonalizacoes.findIndex((p) => p.codigo === codigo);
            state.listaPersonalizacoes.splice(indice, 1);
          } else {
            apresentarMensagemErro(retorno.mensagem);
          }
        },
      });
    }

    return {
      state,
      editar,
      confirmarEdicao,
      excluir,
    };
  },
});
