import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_selecionar_valor_campo_parametro = _resolveComponent("selecionar-valor-campo-parametro")!
  const _component_selecionar_categoria_plano_conta = _resolveComponent("selecionar-categoria-plano-conta")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!

  return (_openBlock(), _createElementBlock("div", {
    key: _ctx.props.parametro.codigo,
    class: _normalizeClass(_ctx.state.classColuna)
  }, [
    (_ctx.props.parametro.tipo !== _ctx.ETipoParametro.Boolean)
      ? (_openBlock(), _createBlock(_component_a_form_item, {
          key: 0,
          class: "ss-margin-campos"
        }, {
          label: _withCtx(() => [
            _createElementVNode("label", null, _toDisplayString(_ctx.props.parametro.nome), 1),
            (_ctx.UtilitarioGeral.valorValido(_ctx.props.parametro.descricao))
              ? (_openBlock(), _createBlock(_component_a_tooltip, {
                  key: 0,
                  placement: "top",
                  title: _ctx.props.parametro.descricao
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_icone, {
                      class: "ss-icone-informacao-campo",
                      nome: "informacao-campo"
                    })
                  ]),
                  _: 1
                }, 8, ["title"]))
              : _createCommentVNode("", true)
          ]),
          default: _withCtx(() => [
            (_ctx.props.parametro.tipo === _ctx.ETipoParametro.Texto)
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 0,
                  class: "ant-input",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedParametro.valor) = $event))
                }, null, 512)), [
                  [_vModelText, _ctx.computedParametro.valor]
                ])
              : (_ctx.props.parametro.tipo === _ctx.ETipoParametro.Inteiro)
                ? (_openBlock(), _createBlock(_component_campo_numerico, {
                    key: 1,
                    quantidadeCasasDecimais: 0,
                    valor: _ctx.state.valorNumerico,
                    "onUpdate:valor": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.valorNumerico) = $event)),
                    onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.atualizarValorNumerico()))
                  }, null, 8, ["valor"]))
                : (_ctx.props.parametro.tipo === _ctx.ETipoParametro.Real)
                  ? (_openBlock(), _createBlock(_component_campo_numerico, {
                      key: 2,
                      quantidadeCasasDecimais: 2,
                      valor: _ctx.state.valorNumerico,
                      "onUpdate:valor": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.valorNumerico) = $event)),
                      onChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.atualizarValorNumerico()))
                    }, null, 8, ["valor"]))
                  : (_ctx.props.parametro.tipo === _ctx.ETipoParametro.Lista)
                    ? (_openBlock(), _createBlock(_component_selecionar_valor_campo_parametro, {
                        key: 3,
                        valores: _ctx.props.parametro.itens,
                        valor: _ctx.computedParametro.valor,
                        "onUpdate:valor": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.computedParametro.valor) = $event))
                      }, null, 8, ["valores", "valor"]))
                    : (_ctx.props.parametro.tipo === _ctx.ETipoParametro.ListaRecursoReferenciado && _ctx.props.parametro.recursoReferenciado === 'CATEGORIA_PLANOS_CONTAS')
                      ? (_openBlock(), _createBlock(_component_selecionar_categoria_plano_conta, {
                          key: 4,
                          codigoSelecionado: _ctx.state.valorNumerico,
                          "onUpdate:codigoSelecionado": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.valorNumerico) = $event)),
                          onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.atualizarValorNumerico()))
                        }, null, 8, ["codigoSelecionado"]))
                      : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_a_checkbox, {
          key: 1,
          class: "ss-margin-campos",
          checked: _ctx.state.valorBoolean,
          "onUpdate:checked": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.valorBoolean) = $event)),
          onChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.atualizarValorBooleano()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.props.parametro.nome) + " ", 1),
            (_ctx.UtilitarioGeral.valorValido(_ctx.props.parametro.descricao))
              ? (_openBlock(), _createBlock(_component_a_tooltip, {
                  key: 0,
                  placement: "top",
                  title: _ctx.props.parametro.descricao
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_icone, {
                      class: "ss-icone-informacao-campo",
                      nome: "informacao-campo"
                    })
                  ]),
                  _: 1
                }, 8, ["title"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["checked"]))
  ], 2))
}