import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "ss-tela-estrutura" }
const _hoisted_2 = { class: "ant-row" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_4 = { class: "ss-tela-conteudo" }
const _hoisted_5 = { class: "ss-card ss-card-space" }
const _hoisted_6 = { class: "ant-row ant-form-vertical" }
const _hoisted_7 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_8 = { class: "ss-card ss-card-space" }
const _hoisted_9 = { class: "ant-row" }
const _hoisted_10 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_11 = { class: "ant-row" }
const _hoisted_12 = {
  key: 0,
  class: "ant-col ant-col-xs-12 ant-col-xl-4"
}
const _hoisted_13 = ["onUpdate:modelValue", "onChange"]
const _hoisted_14 = { class: "ant-row" }
const _hoisted_15 = { class: "ant-row" }
const _hoisted_16 = {
  key: 0,
  class: "ant-col ant-col-xs-12 ant-col-xl-4"
}
const _hoisted_17 = ["onUpdate:modelValue", "onChange"]
const _hoisted_18 = { class: "ant-row" }
const _hoisted_19 = { class: "ant-row" }
const _hoisted_20 = {
  key: 0,
  class: "ant-col ant-col-xs-12 ant-col-xl-4"
}
const _hoisted_21 = ["onUpdate:modelValue", "onChange"]
const _hoisted_22 = { class: "ant-row" }
const _hoisted_23 = { class: "ant-row" }
const _hoisted_24 = {
  key: 0,
  class: "ant-col ant-col-xs-12 ant-col-xl-4"
}
const _hoisted_25 = ["onUpdate:modelValue", "onChange"]
const _hoisted_26 = { class: "ant-row" }
const _hoisted_27 = { class: "ant-row" }
const _hoisted_28 = {
  key: 0,
  class: "ant-col ant-col-xs-12 ant-col-xl-4"
}
const _hoisted_29 = ["onUpdate:modelValue", "onKeypress", "onPaste"]
const _hoisted_30 = { class: "ant-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_titulo_padrao = _resolveComponent("titulo-padrao")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_card = _resolveComponent("card")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_titulo_padrao, {
          identificadorRecurso: _ctx.telaBase.identificadorRecurso,
          tituloRecurso: 'Controle de Numerações',
          exibirAtalhosTela: true,
          exibirHistoricoTela: true,
          empresasSelecionadas: _ctx.telaBase.empresasSelecionadas,
          "onUpdate:empresasSelecionadas": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.telaBase.empresasSelecionadas) = $event))
        }, null, 8, ["identificadorRecurso", "empresasSelecionadas"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_a_form_item, {
                  label: "Empresas",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      class: "ss-tamanho-completo",
                      placeholder: "Selecione uma empresa",
                      value: _ctx.state.codigoEmpresa,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.codigoEmpresa) = $event)),
                      optionFilterProp: "label",
                      options: _ctx.telaBase.empresasDisponiveis
                    }, null, 8, ["value", "options"])
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createVNode(_component_a_form, {
            layout: "vertical",
            style: {"text-align":"left"}
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_a_alert, {
                  message: "As numerações abaixo representam o valor atual de cada movimento/operação no NeoX, atenção ao alterar essas informações.",
                  type: "info",
                  "show-icon": ""
                }),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_a_skeleton, {
                      loading: _ctx.telaBase.carregando
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_card, { titulo: "CADASTROS" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_11, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.numeracoes, (numeracao, index) => {
                                return (_openBlock(), _createElementBlock(_Fragment, {
                                  key: numeracao.codigo
                                }, [
                                  (numeracao.tipo === _ctx.ETipoControleNumeracao.Produtos)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                        _createVNode(_component_a_form_item, {
                                          label: _ctx.obterDescricaoNumeracao(numeracao.tipo),
                                          class: "ss-margin-campos"
                                        }, {
                                          default: _withCtx(() => [
                                            _withDirectives(_createElementVNode("input", {
                                              type: "number",
                                              class: "ant-input",
                                              style: {"text-align":"right"},
                                              "onUpdate:modelValue": ($event: any) => ((numeracao.numero) = $event),
                                              min: "0",
                                              onChange: ($event: any) => (_ctx.validarNumeroNegativo(index))
                                            }, null, 40, _hoisted_13), [
                                              [_vModelText, numeracao.numero]
                                            ])
                                          ]),
                                          _: 2
                                        }, 1032, ["label"])
                                      ]))
                                    : _createCommentVNode("", true)
                                ], 64))
                              }), 128))
                            ]),
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("button", {
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.salvarNumeracao([12], 'cadastros'))),
                                type: "button",
                                class: "ant-btn ant-btn-primary ss-btn-acao-destaque",
                                style: {"margin-top":"5px"}
                              }, "Salvar")
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_card, { titulo: "COMPRAS" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_15, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.numeracoes, (numeracao, index) => {
                                return (_openBlock(), _createElementBlock(_Fragment, {
                                  key: numeracao.codigo
                                }, [
                                  (numeracao.tipo === _ctx.ETipoControleNumeracao.SolcitacoesCompras || numeracao.tipo === _ctx.ETipoControleNumeracao.Cotacoes || numeracao.tipo === _ctx.ETipoControleNumeracao.PedidosCompras || numeracao.tipo === _ctx.ETipoControleNumeracao.Recebimentos || numeracao.tipo === _ctx.ETipoControleNumeracao.DevolucoesCompras)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                        _createVNode(_component_a_form_item, {
                                          label: _ctx.obterDescricaoNumeracao(numeracao.tipo),
                                          class: "ss-margin-campos"
                                        }, {
                                          default: _withCtx(() => [
                                            _withDirectives(_createElementVNode("input", {
                                              type: "number",
                                              class: "ant-input",
                                              style: {"text-align":"right"},
                                              "onUpdate:modelValue": ($event: any) => ((numeracao.numero) = $event),
                                              min: "0",
                                              onChange: ($event: any) => (_ctx.validarNumeroNegativo(index))
                                            }, null, 40, _hoisted_17), [
                                              [_vModelText, numeracao.numero]
                                            ])
                                          ]),
                                          _: 2
                                        }, 1032, ["label"])
                                      ]))
                                    : _createCommentVNode("", true)
                                ], 64))
                              }), 128))
                            ]),
                            _createElementVNode("div", _hoisted_18, [
                              _createElementVNode("button", {
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.salvarNumeracao([1, 2, 3, 4, 5], 'compras'))),
                                type: "button",
                                class: "ant-btn ant-btn-primary ss-btn-acao-destaque",
                                style: {"margin-top":"5px"}
                              }, "Salvar")
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_card, { titulo: "VENDAS" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_19, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.numeracoes, (numeracao, index) => {
                                return (_openBlock(), _createElementBlock(_Fragment, {
                                  key: numeracao.codigo
                                }, [
                                  (numeracao.tipo === _ctx.ETipoControleNumeracao.Vendas || numeracao.tipo === _ctx.ETipoControleNumeracao.DevolucoesVendas)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                        _createVNode(_component_a_form_item, {
                                          label: _ctx.obterDescricaoNumeracao(numeracao.tipo),
                                          class: "ss-margin-campos"
                                        }, {
                                          default: _withCtx(() => [
                                            _withDirectives(_createElementVNode("input", {
                                              type: "number",
                                              class: "ant-input",
                                              style: {"text-align":"right"},
                                              "onUpdate:modelValue": ($event: any) => ((numeracao.numero) = $event),
                                              min: "0",
                                              onChange: ($event: any) => (_ctx.validarNumeroNegativo(index))
                                            }, null, 40, _hoisted_21), [
                                              [_vModelText, numeracao.numero]
                                            ])
                                          ]),
                                          _: 2
                                        }, 1032, ["label"])
                                      ]))
                                    : _createCommentVNode("", true)
                                ], 64))
                              }), 128))
                            ]),
                            _createElementVNode("div", _hoisted_22, [
                              _createElementVNode("button", {
                                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.salvarNumeracao([6, 7], 'vendas'))),
                                type: "button",
                                class: "ant-btn ant-btn-primary ss-btn-acao-destaque",
                                style: {"margin-top":"5px"}
                              }, "Salvar")
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_card, { titulo: "DOCUMENTOS FISCAIS ELETRÔNICOS" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_divider, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Numerações")
                              ]),
                              _: 1
                            }),
                            _createElementVNode("div", _hoisted_23, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.numeracoes, (numeracao, index) => {
                                return (_openBlock(), _createElementBlock(_Fragment, {
                                  key: numeracao.codigo
                                }, [
                                  (numeracao.tipo === _ctx.ETipoControleNumeracao.NumeroNFe || numeracao.tipo === _ctx.ETipoControleNumeracao.NumeroNFCe || numeracao.tipo === _ctx.ETipoControleNumeracao.NumeroMDFe || numeracao.tipo === _ctx.ETipoControleNumeracao.NumeroNFSe)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                        _createVNode(_component_a_form_item, {
                                          label: _ctx.obterDescricaoNumeracao(numeracao.tipo),
                                          class: "ss-margin-campos"
                                        }, {
                                          default: _withCtx(() => [
                                            _withDirectives(_createElementVNode("input", {
                                              type: "number",
                                              class: "ant-input",
                                              style: {"text-align":"right"},
                                              "onUpdate:modelValue": ($event: any) => ((numeracao.numero) = $event),
                                              min: "0",
                                              onChange: ($event: any) => (_ctx.validarNumeroNegativo(index))
                                            }, null, 40, _hoisted_25), [
                                              [_vModelText, numeracao.numero]
                                            ])
                                          ]),
                                          _: 2
                                        }, 1032, ["label"])
                                      ]))
                                    : _createCommentVNode("", true)
                                ], 64))
                              }), 128))
                            ]),
                            _createElementVNode("div", _hoisted_26, [
                              _createElementVNode("button", {
                                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.salvarNumeracao([8, 9, 10, 11], 'documentos fiscais eletrônicos'))),
                                type: "button",
                                class: "ant-btn ant-btn-primary ss-btn-acao-destaque",
                                style: {"margin-top":"5px"}
                              }, "Salvar")
                            ]),
                            _createVNode(_component_a_divider, null, {
                              default: _withCtx(() => [
                                _createTextVNode("Séries")
                              ]),
                              _: 1
                            }),
                            _createElementVNode("div", _hoisted_27, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.series, (serie) => {
                                return (_openBlock(), _createElementBlock(_Fragment, {
                                  key: serie.codigo
                                }, [
                                  (serie.tipo === _ctx.ETipoControleSerie.SerieNFe || serie.tipo === _ctx.ETipoControleSerie.SerieNFCe || serie.tipo === _ctx.ETipoControleSerie.SerieMDFe || serie.tipo === _ctx.ETipoControleSerie.SerieNFSe)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                        _createVNode(_component_a_form_item, {
                                          label: _ctx.obterDescricaoSerie(serie.tipo),
                                          class: "ss-margin-campos"
                                        }, {
                                          default: _withCtx(() => [
                                            _withDirectives(_createElementVNode("input", {
                                              type: "text",
                                              class: "ant-input",
                                              style: {"text-align":"right"},
                                              "onUpdate:modelValue": ($event: any) => ((serie.numero) = $event),
                                              onKeypress: ($event: any) => (_ctx.validarInformacaoDigitada($event,serie.tipo)),
                                              onPaste: ($event: any) => (_ctx.validarInformacaoColada($event,serie.tipo))
                                            }, null, 40, _hoisted_29), [
                                              [_vModelText, serie.numero]
                                            ])
                                          ]),
                                          _: 2
                                        }, 1032, ["label"])
                                      ]))
                                    : _createCommentVNode("", true)
                                ], 64))
                              }), 128))
                            ]),
                            _createElementVNode("div", _hoisted_30, [
                              _createElementVNode("button", {
                                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.salvarSeries([1, 2, 3, 4], 'documentos fiscais eletrônicos'))),
                                type: "button",
                                class: "ant-btn ant-btn-primary ss-btn-acao-destaque",
                                style: {"margin-top":"5px"}
                              }, "Salvar")
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["loading"])
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}