import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0241bb0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ant-btn-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icone = _resolveComponent("icone")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emit('primeiro'))),
      class: "ant-btn ss-botao-navegacao ss-botoes-icones"
    }, [
      _createVNode(_component_icone, { nome: "navegacao-primeiro" })
    ]),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emit('anterior'))),
      class: "ant-btn ss-botao-navegacao ss-botoes-icones"
    }, [
      _createVNode(_component_icone, { nome: "navegacao-anterior" })
    ]),
    _createElementVNode("button", {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.emit('proximo'))),
      class: "ant-btn ss-botao-navegacao ss-botoes-icones"
    }, [
      _createVNode(_component_icone, { nome: "navegacao-proximo" })
    ]),
    _createElementVNode("button", {
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.emit('ultimo'))),
      class: "ant-btn ss-botao-navegacao ss-botoes-icones"
    }, [
      _createVNode(_component_icone, { nome: "navegacao-ultimo" })
    ]),
    _withDirectives(_createElementVNode("button", {
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.emit('excluir'))),
      class: "ant-btn ss-botao-navegacao ss-botoes-icones"
    }, [
      _createVNode(_component_icone, { nome: "excluir" })
    ], 512), [
      [_vShow, _ctx.props.exibirExcluir]
    ])
  ]))
}