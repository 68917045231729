
import {
  computed, defineComponent, onMounted, reactive, onBeforeMount,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IParametro } from '@/models/Entidades/MeuSistema/Parametros/IParametro';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import SelecionarValorCampoParametro from './SelecionarValorCampoParametro.vue';
import { ETipoParametro } from '@/models/Enumeradores/MeuSistema/Parametros/ETipoParametro';
import Icone from '@/core/components/Icone.vue';

export default defineComponent({
  name: 'ParametrosCampo',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    parametro: {
      type: Object as () => IParametro,
      required: true,
    },
  },
  components: {
    CampoNumerico,
    Icone,
    SelecionarCategoriaPlanoConta,
    SelecionarValorCampoParametro,
  },
  emits: ['update:parametro', 'update:valorBoolean', 'update:valores'],
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();

    const state = reactive({
      classColuna: 'ant-col ant-col-xs-24 ant-col-xl-24',
      valorNumerico: 0.0,
      valorBoolean: false,
      itensSelecionados: [] as string[],
      carregando: false,
    });

    const computedParametro = computed({
      get: () => props.parametro,
      set: (valor: IParametro) => {
        emit('update:parametro', valor);
      },
    });

    function atualizarValorNumerico() {
      computedParametro.value.valor = UtilitarioGeral.obterApenasNumeros(String(state.valorNumerico));
    }

    function atualizarValorBooleano() {
      computedParametro.value.valor = String(state.valorBoolean);
    }

    function preencherValor() {
      if (props.parametro.tipo === ETipoParametro.Boolean) {
        state.valorBoolean = props.parametro.valor === 'true';
      } else if (props.parametro.tipo === ETipoParametro.Texto) {
        state.valorBoolean = false;
        state.valorNumerico = 0;
      } else {
        state.valorNumerico = Number(props.parametro.valor);
      }
    }

    function obterTamanhoColuna() {
      let tamanho = '24';
      if (UtilitarioGeral.valorValido(props.parametro.largura) && props.parametro.largura > 0) {
        tamanho = String(props.parametro.largura);
      } else if (props.parametro.tipo === ETipoParametro.Inteiro || props.parametro.tipo === ETipoParametro.Real || props.parametro.tipo === ETipoParametro.Boolean) {
        tamanho = '3';
      } else if (props.parametro.tipo === ETipoParametro.Texto || props.parametro.tipo === ETipoParametro.Lista) {
        tamanho = '6';
      } else {
        tamanho = '12';
      }
      state.classColuna = `ant-col ant-col-xs-${tamanho} ant-col-xl-${tamanho}`;
    }

    onBeforeMount(async () => {
      preencherValor();
    });

    onMounted(async () => {
      obterTamanhoColuna();
    });

    return {
      props,
      state,
      telaBase,
      UtilitarioGeral,
      ETipoParametro,
      computedParametro,
      obterTamanhoColuna,
      atualizarValorNumerico,
      atualizarValorBooleano,
    };
  },
});
