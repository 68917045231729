
import { defineComponent, reactive } from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { useTelaBase } from '@/core/composables/TelaBase';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import ServicoEmpresa from '@/servicos/MeuSistema/ServicoEmpresa';
import { IRecurso } from '@/models/Entidades/MeuSistema/IRecurso';
import { IEmpresaCadastroCompartilhado } from '@/models/Entidades/MeuSistema/Empresas/IEmpresaCadastroCompartilhado';
import { IDTODadosEmpresa } from '@/models/DTO/MeuSistema/Empresas/IDTODadosEmpresa';
import { ECustomRenderRow, IColumn } from '@/core/models/AntDesign/IColumn';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { useModalBase } from '@/core/composables/ModalBase';

export default defineComponent({
  name: 'MultiEmpresasCadastros',
  components: {
    TituloPadrao,
    Card,
    RequisicaoModal,
  },
  setup(props, { emit }) {
    const {
      telaBase, adicionarAtalho, removerAtalho, apresentarMensagemSucesso, apresentarMensagemAlerta,
    } = useTelaBase();

    const {
      modalBase, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);

    const chaveEmpresa = 'EMPRESA-';

    telaBase.identificadorRecurso = 'PAINEL_MULTI_EMPRESAS_CADASTROS';
    telaBase.identificadorPermissao = 'AUT_PAINEL_MULTI_EMPRESAS_CADASTROS';
    telaBase.empresasSelecionadas = [];

    const servicoSistema = new ServicoSistema();
    const servicoEmpresa = new ServicoEmpresa();

    const state = reactive({
      listaEmpresas: [] as IDTODadosEmpresa[],
      listaRecursos: [] as IRecurso[],
      listaEmpresasCompartilhaCadastro: [] as IEmpresaCadastroCompartilhado[],
      colunasGrade: [] as IColumn[],
    });

    state.colunasGrade = [];
    state.listaEmpresasCompartilhaCadastro = [];

    (async () => {
      state.listaEmpresasCompartilhaCadastro = await servicoEmpresa.obterEmpresasCadastrosCompartilhados();
      state.listaEmpresas = await servicoSistema.obterDadosEmpresas(true);
      state.listaRecursos = await servicoSistema.obterRecursosCadastroCompartilhado();

      state.colunasGrade = [
        {
          title: 'Recurso', dataIndex: 'identificador', key: 'identificador', position: 0, visible: false, ellipsis: true,
        },
        {
          title: 'Cadastros', dataIndex: 'descricao', key: 'descricao', position: 2, visible: true, width: 100, ordering: false, align: 'left', fixed: 'left',
        },
      ];
      let position = 3;
      state.listaEmpresas.forEach((empresa) => {
        const colunaEmpresa:IColumn = {} as IColumn;
        colunaEmpresa.title = `${empresa.codigoExibicao} - ${empresa.nomeExibicao}`;
        colunaEmpresa.key = `EMPRESA-${empresa.codigoEmpresa}`;
        colunaEmpresa.visible = true;
        colunaEmpresa.position = position;
        colunaEmpresa.width = 100;
        colunaEmpresa.align = 'left';
        colunaEmpresa.customRenderRow = ECustomRenderRow.HTMLPersonalizado;
        position += 1;
        state.colunasGrade.push(colunaEmpresa);
      });
    })();

    function empresaCompartilhaCadastro(identificadorRecurso: string, valorChaveEmpresa: string):boolean {
      const codigoEmpresa = Number(valorChaveEmpresa.replace(chaveEmpresa, ''));
      const empresaCadastroCompartilhado = state.listaEmpresasCompartilhaCadastro.find((c) => c.recurso === identificadorRecurso && c.codigoEmpresa === codigoEmpresa);

      if (empresaCadastroCompartilhado === undefined) {
        const empresaCadastroCompartilhado:IEmpresaCadastroCompartilhado = {} as IEmpresaCadastroCompartilhado;
        empresaCadastroCompartilhado.codigo = 0;
        empresaCadastroCompartilhado.codigoEmpresa = codigoEmpresa;
        empresaCadastroCompartilhado.recurso = identificadorRecurso;
        empresaCadastroCompartilhado.compartilhar = false;
        state.listaEmpresasCompartilhaCadastro.push(empresaCadastroCompartilhado);
        return false;
      }
      return empresaCadastroCompartilhado.compartilhar;
    }

    function manipularCompartilharCadastro(identificadorRecurso: string, valorChaveEmpresa: string, compartilhar: boolean) {
      const codigoEmpresa = Number(valorChaveEmpresa.replace(chaveEmpresa, ''));
      const index = state.listaEmpresasCompartilhaCadastro.findIndex((c) => c.recurso === identificadorRecurso && c.codigoEmpresa === codigoEmpresa);

      if (index >= 0) {
        state.listaEmpresasCompartilhaCadastro[index].compartilhar = compartilhar;
      }
    }

    async function salvar() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      apresentarBarraProgresso('Aguarde por favor, estamos atualizando os cadastros...');

      retorno = await servicoEmpresa.atualizarCadastrosCompartilhados(state.listaEmpresasCompartilhaCadastro);

      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
      } else {
        apresentarMensagemAlerta(retorno.mensagem);
      }
    }
    return {
      telaBase,
      modalBase,
      state,
      ECustomRenderRow,
      ETipoPermissao,
      EPermissaoDados,
      UtilitarioGeral,
      UtilitarioMascara,
      adicionarAtalho,
      removerAtalho,
      empresaCompartilhaCadastro,
      manipularCompartilharCadastro,
      salvar,
    };
  },
});
