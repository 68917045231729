
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import Card from '@/core/components/Tela/Card.vue';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import ServicoEmpresa from '@/servicos/MeuSistema/ServicoEmpresa';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import storeSistema from '@/store/storeSistema';
import { IDTOGrupoEmpresa } from '@/models/DTO/MeuSistema/Empresas/IDTOGrupoEmpresa';
import { IDTOAdicionarEmpresaGrupo } from '@/models/DTO/MeuSistema/Empresas/IDTOAdicionarEmpresaGrupo';
import { IRecurso } from '@/models/Entidades/MeuSistema/IRecurso';
import { IEmpresaCadastroCompartilhado } from '@/models/Entidades/MeuSistema/Empresas/IEmpresaCadastroCompartilhado';
import GerenteAutenticacao from '@/core/gerentes/GerenteAutenticacao';

export default defineComponent({
  name: 'AdicionarEmpresaGrupoModal',
  emits: ['adicionadaComSucesso'],
  props: {
    visivel: {
      type: Boolean,
    },
    token: {
      type: String,
      default: '',
    },
  },
  components: {
    Icone,
    Card,
    RequisicaoModal,
  },
  setup(props, { emit }) {
    const {
      telaBase, apresentarMensagemSucesso, apresentarMensagemAlerta,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);
    const servicoEmpresa = new ServicoEmpresa();
    const servicoSistema = new ServicoSistema();
    let debounceRecarregar = 0;
    const state = reactive({
      tokenGrupoEmpresa: '',
      etapaAdcionarEmpresaGrupo: 1,
      grupoEmpresaToken: {} as IDTOGrupoEmpresa,
      adicionarEmpresaGrupo: {} as IDTOAdicionarEmpresaGrupo,
      listaRecursos: [] as IRecurso[],
      bloquearRelacionadosProduto: false,
      bloquearRelacionadosColaborador: false,
    });

    async function obterGrupoEmpresaPorToken() {
      const grupoEmpresa = await servicoEmpresa.obterGrupoEmpresaPorToken(state.tokenGrupoEmpresa);
      if (grupoEmpresa !== undefined) {
        if (UtilitarioGeral.validaCodigo(grupoEmpresa.codigo)) {
          state.grupoEmpresaToken = grupoEmpresa;
          state.etapaAdcionarEmpresaGrupo = 2;
        } else {
          apresentarMensagemAlerta('Token inválido!');
        }
      }
    }

    async function obterInformacoesCadastroCompartilhado() {
      state.listaRecursos = await servicoSistema.obterRecursosCadastroCompartilhado();
      state.adicionarEmpresaGrupo.tokenGrupo = state.tokenGrupoEmpresa;
      state.adicionarEmpresaGrupo.cadastrosCompartilhados = [];

      state.listaRecursos.forEach((recurso) => {
        const cadastroCompartilhado:IEmpresaCadastroCompartilhado = {} as IEmpresaCadastroCompartilhado;
        cadastroCompartilhado.codigo = 0;
        cadastroCompartilhado.codigoEmpresa = storeSistema.getters.codigoEmpresaOperacao();
        cadastroCompartilhado.compartilhar = false;
        cadastroCompartilhado.recurso = recurso.identificador;
        if (recurso.identificador === 'CADASTRO_PERFIS_USUARIOS' || recurso.identificador === 'CADASTRO_USUARIOS') {
          cadastroCompartilhado.compartilhar = true;
        }
        state.adicionarEmpresaGrupo.cadastrosCompartilhados.push(cadastroCompartilhado);
      });

      state.etapaAdcionarEmpresaGrupo = 3;
    }

    async function concluirAdicionarEmpresaGrupo() {
      if (!UtilitarioGeral.validaLista(state.adicionarEmpresaGrupo.cadastrosCompartilhados)) {
        apresentarMensagemAlerta('É necessário informar os cadastros que deseja compartilhar!');
        return;
      }

      apresentarBarraProgresso('Aguarde por favor, estamos adicionando sua empresa no Grupo...');
      const retorno = await servicoEmpresa.adicionarEmpresaNoGrupo(state.adicionarEmpresaGrupo);
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        const retornoAutenticacao = await servicoSistema.atualizarToken();
        if (retornoAutenticacao.autenticado) {
          new GerenteAutenticacao().salvarDadosAutenticacao(retornoAutenticacao.token, retornoAutenticacao.dataExpiracao.toString());
        }
        clearTimeout(debounceRecarregar);
        debounceRecarregar = setTimeout(() => {
          emit('adicionadaComSucesso');
          modalBase.computedVisivel = false;
        }, 800);
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }
    function obtemTituloModalAdicionarEmpresa(): string {
      if (state.etapaAdcionarEmpresaGrupo === 1) {
        return 'Informe o Token do Grupo que deseja participar';
      }

      if (state.etapaAdcionarEmpresaGrupo === 2) {
        return `Empresas no Grupo: ${state.grupoEmpresaToken.nome}`;
      }

      if (state.etapaAdcionarEmpresaGrupo === 3) {
        return 'Quais cadastros você deseja compartilhar';
      }

      return '';
    }

    function obterTituloRecurso(identificador:string) {
      const recurso = state.listaRecursos.find((c) => c.identificador === identificador);
      if (recurso !== undefined) {
        return recurso.descricao.replace('Cadastro de', '');
      }
      return '';
    }

    function obtemValor(recurso:string) {
      const index = state.adicionarEmpresaGrupo.cadastrosCompartilhados.findIndex((c) => c.recurso === recurso);
      if (index >= 0) {
        return state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar;
      }
      return false;
    }

    function defineValorFixo(recurso:string, valor:boolean) {
      const index = state.adicionarEmpresaGrupo.cadastrosCompartilhados.findIndex((c) => c.recurso === recurso);
      if (index >= 0) {
        state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar = valor;
      }
    }

    function defineValor(recurso:string) {
      const index = state.adicionarEmpresaGrupo.cadastrosCompartilhados.findIndex((c) => c.recurso === recurso);
      if (index >= 0) {
        state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar = !state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar;
        if (recurso === 'CADASTRO_PRODUTOS') {
          defineValorFixo('CADASTRO_UNIDADES', state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar);
          defineValorFixo('CADASTRO_TIPOS_CUSTO', state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar);
          defineValorFixo('CADASTRO_TIPOS_PRECO', state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar);
          defineValorFixo('CADASTRO_TABELAS_PRECOS', state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar);
          defineValorFixo('CADASTRO_ESTOQUES', state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar);
          defineValorFixo('CADASTRO_MARCAS', state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar);
          defineValorFixo('CADASTRO_CATEGORIAS_PRODUTOS', state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar);
          defineValorFixo('CADASTRO_CARACTERISTICAS', state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar);
          state.bloquearRelacionadosProduto = state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar;
        } else if (recurso === 'CADASTRO_COLABORADORES') {
          if (state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar) {
            defineValorFixo('CADASTRO_FUNCOES_COLABORADORES', true);
            defineValorFixo('CADASTRO_DEPARTAMENTOS_COLABORADORES', true);
            defineValorFixo('CADASTRO_TIPOS_CONTATOS', true);
            state.bloquearRelacionadosColaborador = true;
          } else {
            state.bloquearRelacionadosColaborador = false;
          }
        } else if (recurso === 'CADASTRO_TABELAS_PRECOS') {
          defineValorFixo('CADASTRO_PRODUTOS', state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar);
          defineValorFixo('CADASTRO_UNIDADES', state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar);
          defineValorFixo('CADASTRO_TIPOS_CUSTO', state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar);
          defineValorFixo('CADASTRO_TIPOS_PRECO', state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar);
          defineValorFixo('CADASTRO_TABELAS_PRECOS', state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar);
          defineValorFixo('CADASTRO_ESTOQUES', state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar);
          defineValorFixo('CADASTRO_MARCAS', state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar);
          defineValorFixo('CADASTRO_CATEGORIAS_PRODUTOS', state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar);
          defineValorFixo('CADASTRO_CARACTERISTICAS', state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar);
          state.bloquearRelacionadosProduto = state.adicionarEmpresaGrupo.cadastrosCompartilhados[index].compartilhar;
        }
      }
    }
    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      if (modalBase.computedVisivel) {
        state.etapaAdcionarEmpresaGrupo = 1;
        state.tokenGrupoEmpresa = '';
        if (UtilitarioGeral.valorValido(props.token)) {
          state.tokenGrupoEmpresa = props.token;
          await obterGrupoEmpresaPorToken();
        }
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      UtilitarioMascara,
      UtilitarioGeral,
      UtilitarioData,
      obterTituloRecurso,
      obtemTituloModalAdicionarEmpresa,
      concluirAdicionarEmpresaGrupo,
      obterInformacoesCadastroCompartilhado,
      obterGrupoEmpresaPorToken,
      obtemValor,
      defineValor,
    };
  },
});
