
import { defineComponent, reactive, watch } from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import Icone from '@/core/components/Icone.vue';
import { IDTOWidgetUsuario } from '@/models/DTO/MeuSistema/Usuarios/IDTOWidgetUsuario';
import ServicoMinhaDashboard from '@/servicos/MeuSistema/ServicoMinhaDashboard';
import { IWidget } from '@/models/Entidades/MeuSistema/IWidget';
import storeSistema from '@/store/storeSistema';
import { ETipoWidget } from '@/models/Enumeradores/MeuSistema/ETipoWidget';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IOption } from '@/core/models/AntDesign/IOption';
import SelecionarGenerico from '@/core/components/Tela/SelecionarGenerico.vue';

export default defineComponent({
  name: 'WidgetUsuarioNovoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    coluna: {
      type: Number,
      required: true,
    },
    linha: {
      type: Number,
      required: true,
    },
    subcoluna: {
      type: Number,
      required: true,
    },
    sublinha: {
      type: Number,
      required: true,
    },
  },
  components: {
    Icone,
    SelecionarGenerico,
  },
  emits: ['confirmacao'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const servicoMinhaDashboard = new ServicoMinhaDashboard();

    const state = reactive({
      disponiveis: [] as IWidget[],
      tamanhosDisponiveis: [] as number[],
      limites: [] as IOption[],
      tamanhos: [] as IOption[],
      indexSelecionado: -1,
      limite: 10,
      tamanho: 1,
      carregando: false,
    });

    function preencherTamanhosDisponiveis() {
      state.tamanhosDisponiveis = [] as number[];

      if (props.sublinha === 0) {
        state.tamanhosDisponiveis.push(1);
      }

      state.tamanhosDisponiveis.push(2);

      if (props.linha === 0) {
        state.tamanhosDisponiveis.push(3);
      }
    }

    function preencherTamanhos(disponiveis: number[]) {
      state.tamanhos = [] as IOption[];
      state.tamanho = disponiveis[0];

      disponiveis.forEach((t) => {
        const padrao = {} as IOption;
        if (t === 1) {
          padrao.label = 'Padrão';
        } else if (t === 2) {
          padrao.label = 'Pequeno';
        } else {
          padrao.label = 'Grande';
        }
        padrao.value = t;
        state.tamanhos.push(padrao);
      });
    }

    function preencherLimites() {
      state.limites = [] as IOption[];
      state.limite = 10;

      const limite10 = {} as IOption;
      limite10.label = '10 Registros';
      limite10.value = 10;
      state.limites.push(limite10);

      const limite20 = {} as IOption;
      limite20.label = '20 Registros';
      limite20.value = 20;
      state.limites.push(limite20);

      const limite30 = {} as IOption;
      limite30.label = '30 Registros';
      limite30.value = 30;
      state.limites.push(limite30);

      const limite40 = {} as IOption;
      limite40.label = '40 Registros';
      limite40.value = 40;
      state.limites.push(limite40);

      const limite50 = {} as IOption;
      limite50.label = '50 Registros';
      limite50.value = 50;
      state.limites.push(limite50);
    }

    function limparTela() {
      state.disponiveis = [] as IWidget[];
      state.indexSelecionado = -1;
      state.carregando = false;
      if (modalBase.computedVisivel) {
        preencherLimites();
        preencherTamanhosDisponiveis();
        preencherTamanhos(state.tamanhosDisponiveis);
      }
    }

    function selecionar(index: number, tipo: ETipoWidget) {
      if (state.indexSelecionado === index) {
        state.indexSelecionado = -1;
        preencherTamanhos(state.tamanhosDisponiveis);
      } else {
        if (tipo === ETipoWidget.Lista && !state.tamanhosDisponiveis.includes(1)) {
          apresentarMensagemAlerta('Você não tem espaço para um widget do tipo lista!');
          return;
        }

        state.indexSelecionado = index;
        if (tipo === ETipoWidget.Valor) {
          state.tamanho = 2;
          preencherTamanhos([2]);
        } else if (tipo === ETipoWidget.Lista) {
          state.tamanho = 1;
          if (state.tamanhosDisponiveis.includes(3)) {
            preencherTamanhos([1, 3]);
          } else {
            preencherTamanhos([1]);
          }
        }
      }
    }

    function preencherLocalizacao(widget: IDTOWidgetUsuario) {
      const widgetInterno = widget;

      widgetInterno.coluna = props.coluna;
      if (props.linha > 0 || state.tamanho === 3) {
        widgetInterno.linha = props.linha;
      } else {
        widgetInterno.linha = 1;
      }

      if (props.subcoluna === 0 && state.tamanho !== 3) {
        widgetInterno.subcoluna = 1;
      } else {
        widgetInterno.subcoluna = props.subcoluna;
      }

      if (props.sublinha === 0 && state.tamanho === 2) {
        widgetInterno.sublinha = 1;
      } else {
        widgetInterno.sublinha = props.sublinha;
      }
      return widgetInterno;
    }

    async function obterWidget() {
      const widget = state.disponiveis[state.indexSelecionado];
      const widgetUsuario = {} as IDTOWidgetUsuario;
      widgetUsuario.codigo = widget.codigo;
      widgetUsuario.codigoEmpresa = storeSistema.getters.codigoEmpresaOperacao();
      widgetUsuario.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      widgetUsuario.codigoModulo = widget.codigoModulo;
      if (widget.filtros.length > 0) {
        widgetUsuario.codigoWidgetFiltro = widget.filtros[0].codigo;
      }
      widgetUsuario.limite = state.limite;
      widgetUsuario.tempoAtualizacao = 1800;
      widgetUsuario.tipo = widget.tipo;
      widgetUsuario.titulo = widget.titulo;
      widgetUsuario.valoresMonetarios = widget.valoresMonetarios;
      widgetUsuario.filtros = [] as IItemConsultaRapida[];
      widgetUsuario.dados = await servicoMinhaDashboard.obterDadosWidget(widgetUsuario, false);
      widget.filtros.forEach((f) => {
        const filtro = {} as IItemConsultaRapida;
        filtro.codigo = f.codigo;
        filtro.textoIdentificacao = f.descricao;
        widgetUsuario.filtros.push(filtro);
      });

      return preencherLocalizacao(widgetUsuario);
    }

    async function confirmar() {
      state.carregando = true;
      const widgetSelecionado = await obterWidget();
      state.carregando = false;
      emit('confirmacao', widgetSelecionado);
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      limparTela();
      if (modalBase.computedVisivel) {
        telaBase.carregando = true;
        state.disponiveis = await servicoMinhaDashboard.obterWidgetsDisponiveis(storeSistema.getters.codigoEmpresaOperacao());
        telaBase.carregando = false;
      }
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      ETipoWidget,
      selecionar,
      preencherLocalizacao,
      confirmar,
    };
  },
});
