
import {
  defineComponent, ref, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import RegistroAtividade from '@/components/MeuSistema/Usuarios/RegistroAtividade.vue';
import storeSistema from '@/store/storeSistema';

export default defineComponent({
  name: 'UsuarioRegistroAtividadesModal',
  props: {
    visivel: {
      type: Boolean,
    },
  },
  components: {
    RegistroAtividade,
    Icone,
  },
  setup(props, { emit }) {
    const { telaBase } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);

    const refRegistroAtividade = ref<InstanceType<typeof RegistroAtividade>>();
    telaBase.empresasSelecionadas = storeSistema.getters.empresasUsuarioAutenticado();

    watch(async () => modalBase.computedVisivel, async () => {
      if (modalBase.computedVisivel) {
        if (refRegistroAtividade.value !== undefined) {
          refRegistroAtividade.value.limparDados();
        }
      }
    });

    return {
      telaBase,
      props,
      modalBase,
      refRegistroAtividade,
    };
  },
});
