
import { defineComponent, onBeforeMount, reactive } from 'vue';
import { Modal } from 'ant-design-vue';
import draggable from 'vuedraggable';
import { useTelaBase } from '@/core/composables/TelaBase';
import { ITarefaPessoal } from '@/models/Entidades/MeuSistema/Usuarios/ITarefaPessoal';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { EStatusTarefaPessoal } from '@/models/Enumeradores/MeuSistema/Usuarios/EStatusTarefaPessoal';
import Icone from '@/core/components/Icone.vue';
import storeSistema from '@/store/storeSistema';
import ServicoMinhaDashboard from '@/servicos/MeuSistema/ServicoMinhaDashboard';
import MinhasTarefasEditarModal from './MinhasTarefasEditarModal.vue';
import { IOrdenacaoRegistro } from '@/core/models/IOrdenacaoRegistro';

export default defineComponent({
  name: 'MinhasTarefas',
  components: {
    Icone,
    MinhasTarefasEditarModal,
    draggable,
  },
  setup() {
    const { telaBase, apresentarMensagemErro } = useTelaBase();
    const servicoMinhaDashboard = new ServicoMinhaDashboard();

    const state = reactive({
      tarefas: [] as ITarefaPessoal[],
      novaTarefa: '',
      exibirEdicao: false,
      indexSelecionada: -1,
      descricaoSelecionada: '',
      qtdConcluidas: 0,
      exibirConcluidas: false,
      incluindoTarefa: false,
    });

    async function adicionarTarefa() {
      if (state.novaTarefa === undefined || state.novaTarefa === '') {
        return;
      }
      const tarefa = {} as ITarefaPessoal;
      tarefa.codigoEmpresa = storeSistema.getters.codigoEmpresaOperacao();
      tarefa.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      tarefa.status = EStatusTarefaPessoal.Aberta;
      tarefa.descricao = state.novaTarefa;
      state.incluindoTarefa = true;
      const retorno = await servicoMinhaDashboard.incluirTarefa(tarefa);
      if (retorno.status !== EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemErro(retorno.mensagem);
      } else {
        state.novaTarefa = '';
        tarefa.codigo = retorno.codigoRegistro;
        state.tarefas.push(tarefa);
      }
      state.incluindoTarefa = false;
    }

    function abrirEdicaoTarefa(index: number) {
      state.indexSelecionada = index;
      state.descricaoSelecionada = state.tarefas[index].descricao;
      state.exibirEdicao = true;
    }

    async function editarTarefa(descricao: string) {
      state.tarefas[state.indexSelecionada].descricao = descricao;
      const retorno = await servicoMinhaDashboard.alterarDescricaoTarefa(state.tarefas[state.indexSelecionada]);
      if (retorno.status !== EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemErro(retorno.mensagem);
      }
    }

    async function excluirTarefa(index: number) {
      const tarefa = state.tarefas[index];
      Modal.confirm({
        title: `Realmente deseja excluir a tarefa ${tarefa.descricao}?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => {
          state.tarefas.splice(index, 1);
          const retorno = await servicoMinhaDashboard.excluirTarefa(tarefa.codigo, tarefa.codigoEmpresa);
          if (retorno.status !== EStatusRetornoRequisicao.Sucesso) {
            apresentarMensagemErro(retorno.mensagem);
          }
        },
      });
    }

    async function concluirTarefa(index: number) {
      if (state.tarefas[index].status === EStatusTarefaPessoal.Aberta) {
        state.tarefas[index].status = EStatusTarefaPessoal.Concluida;
        state.qtdConcluidas += 1;
      } else if (state.tarefas[index].status === EStatusTarefaPessoal.Concluida) {
        state.tarefas[index].status = EStatusTarefaPessoal.Aberta;
        let qtdConcluidas = 0;
        state.tarefas.forEach((t) => {
          if (t.status === EStatusTarefaPessoal.Concluida) {
            qtdConcluidas += 1;
          }
          state.qtdConcluidas = qtdConcluidas;
        });
      }
      const retorno = await servicoMinhaDashboard.alterarStatusTarefa(state.tarefas[index]);
      if (retorno.status !== EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemErro(retorno.mensagem);
      }
    }

    async function atualizarOrdemTarefas() {
      const ordenacaoRegistros = [] as IOrdenacaoRegistro[];

      state.tarefas.forEach((t) => {
        const ordenacao = {} as IOrdenacaoRegistro;
        ordenacao.codigoRegistro = t.codigo;
        ordenacao.ordem = t.ordem;
        ordenacaoRegistros.push(ordenacao);
      });
      const retorno = await servicoMinhaDashboard.atualizarOrdenacaoTarefas(storeSistema.getters.codigoEmpresaOperacao(), ordenacaoRegistros);
      if (retorno.status !== EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemErro(retorno.mensagem);
      }
    }

    function limparTela() {
      state.exibirEdicao = false;
      state.qtdConcluidas = 0;
      state.exibirConcluidas = false;
      state.indexSelecionada = -1;
      state.tarefas = [] as ITarefaPessoal[];
      state.descricaoSelecionada = '';
      state.novaTarefa = '';
    }

    async function obterTarefas() {
      state.tarefas = await servicoMinhaDashboard.obterTarefas(storeSistema.getters.codigoEmpresaOperacao());
      state.qtdConcluidas = 0;
      state.tarefas.forEach((t) => {
        if (t.status === EStatusTarefaPessoal.Concluida) {
          state.qtdConcluidas += 1;
        }
      });
    }

    onBeforeMount(async () => {
      state.incluindoTarefa = true;
      limparTela();
      await obterTarefas();
      state.incluindoTarefa = false;
    });

    return {
      state,
      telaBase,
      storeSistema,
      EStatusTarefaPessoal,
      adicionarTarefa,
      excluirTarefa,
      abrirEdicaoTarefa,
      editarTarefa,
      concluirTarefa,
      atualizarOrdemTarefas,
    };
  },
});
