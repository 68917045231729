
import { defineComponent, reactive } from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { IDTOWidgetUsuario } from '@/models/DTO/MeuSistema/Usuarios/IDTOWidgetUsuario';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';

export default defineComponent({
  name: 'WidgetLista',
  props: {
    widget: {
      type: Object as () => IDTOWidgetUsuario,
      required: true,
    },
  },
  setup(props) {
    const { telaBase } = useTelaBase();
    const state = reactive({});

    return {
      state,
      props,
      telaBase,
      UtilitarioMascara,
    };
  },
});
