import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.props.nfse)
      ? (_openBlock(), _createBlock(_component_a_select, {
          key: 0,
          value: _ctx.computedValor,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValor) = $event)),
          onChange: _ctx.defineValorSelecionado,
          class: "ss-tamanho-completo"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select_option, { value: "@NOME_EMITENTE" }, {
              default: _withCtx(() => [
                _createTextVNode("Nome da Empresa")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "@LOGO_EMITENTE" }, {
              default: _withCtx(() => [
                _createTextVNode("Logo da Empresa")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "@NUMERO_DOCUMENTO_FISCAL" }, {
              default: _withCtx(() => [
                _createTextVNode("Nº Documento Fiscal")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "@CODIGO_VERIFICACAO" }, {
              default: _withCtx(() => [
                _createTextVNode("Código Verificação")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "@DATA_EMISSAO" }, {
              default: _withCtx(() => [
                _createTextVNode("Data de Emissão")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "@VALOR_TOTAL" }, {
              default: _withCtx(() => [
                _createTextVNode("Valor Total")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "@NOME_TOMADOR" }, {
              default: _withCtx(() => [
                _createTextVNode("Nome do Tomador")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "@CPF_CNPJ_TOMADOR" }, {
              default: _withCtx(() => [
                _createTextVNode("CPF/CNPJ do Tomador")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "@LINK_PDF" }, {
              default: _withCtx(() => [
                _createTextVNode("Link PDF")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "@LINK_COBRANCA" }, {
              default: _withCtx(() => [
                _createTextVNode("Link Cobrança")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "onChange"]))
      : (_openBlock(), _createBlock(_component_a_select, {
          key: 1,
          value: _ctx.computedValor,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedValor) = $event)),
          onChange: _ctx.defineValorSelecionado,
          class: "ss-tamanho-completo"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select_option, { value: "@NOME_EMITENTE" }, {
              default: _withCtx(() => [
                _createTextVNode("Nome da Empresa")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "@LOGO_EMITENTE" }, {
              default: _withCtx(() => [
                _createTextVNode("Logo da Empresa")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "@NUMERO_DOCUMENTO_FISCAL" }, {
              default: _withCtx(() => [
                _createTextVNode("Nº Documento Fiscal")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "@CHAVE_ACESSO" }, {
              default: _withCtx(() => [
                _createTextVNode("Chave de Acesso")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "@DATA_EMISSAO" }, {
              default: _withCtx(() => [
                _createTextVNode("Data de Emissão")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "@VALOR_TOTAL" }, {
              default: _withCtx(() => [
                _createTextVNode("Valor Total")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "@NOME_DESTINATARIO" }, {
              default: _withCtx(() => [
                _createTextVNode("Nome do Destinatário")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "@CPF_CNPJ_DESTINATARIO" }, {
              default: _withCtx(() => [
                _createTextVNode("CPF/CNPJ do Destinatário")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "@LINK_XML" }, {
              default: _withCtx(() => [
                _createTextVNode("Link XML")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "@LINK_PDF" }, {
              default: _withCtx(() => [
                _createTextVNode("Link PDF")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "onChange"]))
  ]))
}