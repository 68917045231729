
import { defineComponent, reactive, ref } from 'vue';
import { useCrudBase } from '@/core/composables/CrudBase';
import Icone from '@/core/components/Icone.vue';
import TelaPadraoCrud from '@/core/components/TelaPadraoCrud.vue';
import ServicoUsuario from '@/servicos/MeuSistema/ServicoUsuario';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import UsuarioModal from '@/views/MeuSistema/Usuarios/UsuarioModal.vue';
import RegistroAtividadesModal from '@/views/MeuSistema/Usuarios/RegistroAtividadesModal.vue';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';

export default defineComponent({
  name: 'Usuario',
  components: {
    TelaPadraoCrud,
    UsuarioModal,
    RegistroAtividadesModal,
    Icone,
  },
  setup() {
    const {
      crudBase, preencherEstrutura, exibirModal, sincronizaTelaCrud,
    } = useCrudBase();
    const servicoUsuario = new ServicoUsuario();
    const refCrudUsuario = ref<InstanceType<typeof TelaPadraoCrud>>();
    const stateUsuario = reactive({
      apresentarRegistroAtividade: false,
    });

    crudBase.estrutura = preencherEstrutura();
    crudBase.estrutura.apresentarEmpresas = true;
    crudBase.estrutura.verificarCadastroCompartilhado = true;
    crudBase.estrutura.botaoPrincipalAcao = 'Novo usuário';
    crudBase.estrutura.mensagemExclusao = 'Você confirma a exclusão do usuário';
    crudBase.estrutura.colunasGrade = [
      {
        title: 'Código', dataIndex: 'codigo', key: 'CodigoUsuario', position: 0, visible: false, width: 100,
      },
      {
        title: 'Nome', dataIndex: 'nome', key: 'NomeUsuario', position: 1, visible: true, ordering: true, fixed: 'left', align: 'left', customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Login', dataIndex: 'login', key: 'LoginUsuario', position: 2, visible: false, ordering: true, align: 'left',
      },
      {
        title: 'E-mail', dataIndex: 'email', key: 'EmailUsuario', position: 3, visible: true, ordering: true, align: 'left',
      },
      {
        title: 'Status', dataIndex: 'ativo', key: 'ativo', position: 5, visible: true, align: 'center', width: 100, customRenderRow: ECustomRenderRow.TagAtivo,
      },
      {
        title: 'Ações', key: 'acoes', position: 6, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
      },
    ];
    crudBase.estrutura.ordenacaoPadraoGrade.push({ identificador: 'CodigoUsuario', ordem: 'DESC' } as IOrdenacao);

    async function sincronizarRegistro(acao: EPermissaoDados, codigoRegistro: number) {
      if (refCrudUsuario.value !== undefined) {
        await refCrudUsuario.value.sincronizarRegistro(acao, codigoRegistro);
      }
    }
    function apresentarRegistroAtividade() {
      stateUsuario.apresentarRegistroAtividade = !stateUsuario.apresentarRegistroAtividade;
    }

    return {
      stateUsuario,
      crudBase,
      servicoUsuario,
      refCrudUsuario,
      exibirModal,
      sincronizaTelaCrud,
      apresentarRegistroAtividade,
      sincronizarRegistro,
    };
  },
});
