import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ITarefaPessoal } from '@/models/Entidades/MeuSistema/Usuarios/ITarefaPessoal';
import { IOrdenacaoRegistro } from '@/core/models/IOrdenacaoRegistro';
import { INoticia } from '@/models/Entidades/MeuSistema/INoticia';
import { IWidget, IWidgetDado, IWidgetUsuario } from '@/models/Entidades/MeuSistema/IWidget';
import { IDTOWidgetUsuario } from '@/models/DTO/MeuSistema/Usuarios/IDTOWidgetUsuario';

/**
 * Serviço da Minha Dashboard
 * Centraliza e organiza regras do funcionamento da tela inicial.
 */

class ServicoMinhaDashboard {
    private endPoint = 'minha-dashboard';

    private apiERP = new ApiERP();

    public async obterNoticias(): Promise<INoticia[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/noticias`);
      return result.data;
    }

    public async obterTarefas(empresa: number): Promise<ITarefaPessoal[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/minhas-tarefas/empresa/${empresa}`);
      return result.data;
    }

    public async incluirTarefa(tarefa: ITarefaPessoal): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/minhas-tarefas`, tarefa);
      return result.data;
    }

    public async alterarDescricaoTarefa(tarefa: ITarefaPessoal): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/minhas-tarefas/${tarefa.codigo}/descricao/${tarefa.descricao}/empresa/${tarefa.codigoEmpresa}`);
      return result.data;
    }

    public async alterarStatusTarefa(tarefa: ITarefaPessoal): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/minhas-tarefas/${tarefa.codigo}/status/${tarefa.status}/empresa/${tarefa.codigoEmpresa}`);
      return result.data;
    }

    public async atualizarOrdenacaoTarefas(empresa: number, ordenacaoRegistros: IOrdenacaoRegistro[]): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/minhas-tarefas/ordenacao/empresa/${empresa}`, ordenacaoRegistros);
      return result.data;
    }

    public async excluirTarefa(tarefa: number, empresa: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/minhas-tarefas/${tarefa}/empresa/${empresa}`);
      return result.data;
    }

    public async obterWidgetsDisponiveis(empresa: number): Promise<IWidget[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/widgets/empresa/${empresa}`);
      return result.data;
    }

    public async obterMeusWidgets(empresa: number): Promise<IDTOWidgetUsuario[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/meus-widgets/empresa/${empresa}`);
      return result.data;
    }

    public async obterDadosWidget(widgetUsuario: IDTOWidgetUsuario, forcarAtualizacao: boolean): Promise<IWidgetDado[]> {
      const result: any = await this.apiERP.post(`${this.endPoint}/dados-widget/${forcarAtualizacao}`, widgetUsuario);
      return result.data;
    }

    public async incluirWidgetUsuario(widgetUsuario: IWidgetUsuario): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.post(`${this.endPoint}/meus-widgets`, widgetUsuario);
      return result.data;
    }

    public async atualizarWidgetUsuario(widgetUsuario: IWidgetUsuario): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.put(`${this.endPoint}/meus-widgets`, widgetUsuario);
      return result.data;
    }

    public async excluirWidgetUsuario(widget: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/meus-widgets/${widget}`);
      return result.data;
    }
}

export default ServicoMinhaDashboard;
