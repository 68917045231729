
import { defineComponent, onBeforeMount, reactive } from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import WidgetsUsuarioLinha from './WidgetsUsuarioLinha.vue';
import { IDTOWidgetUsuario } from '@/models/DTO/MeuSistema/Usuarios/IDTOWidgetUsuario';
import WidgetUsuario from './WidgetUsuario.vue';

export default defineComponent({
  name: 'WidgetsUsuarioColuna',
  components: {
    WidgetsUsuarioLinha,
    WidgetUsuario,
  },
  props: {
    coluna: {
      type: Number,
      required: true,
    },
    widgets: {
      type: Array as () => IDTOWidgetUsuario[],
      required: true,
    },
  },
  setup(props) {
    const { telaBase } = useTelaBase();

    const state = reactive({
      qtdWidgets: 0,
    });

    function obterQtdWidgets() {
      let contador = 0;
      props.widgets.forEach((w) => {
        if (w.coluna === props.coluna) {
          contador += 1;
        }
      });
      return contador;
    }

    function obterIndiceWidget() {
      const index = props.widgets.findIndex((w) => w.coluna === props.coluna);
      return index;
    }

    onBeforeMount(async () => {
      state.qtdWidgets = obterQtdWidgets();
    });

    return {
      state,
      props,
      telaBase,
      obterQtdWidgets,
      obterIndiceWidget,
    };
  },
});
