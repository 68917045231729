
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import Card from '@/core/components/Tela/Card.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import storeSistema from '@/store/storeSistema';
import ServicoPlanoAssinatura from '@/servicos/PainelAdministrativo/ServicoPlanoAssinatura';
import { IDTOPlanoAssinatura } from '@/models/DTO/PainelAdministrativo/IDTOPlanoAssinatura';
import SelecionarTipoPlanoAssinatura from '@/components/PainelAdministrativo/SelecionarTipoPlanoAssinatura.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarNivelAtendimentoAssinatura from '@/components/PainelAdministrativo/SelecionarNivelAtendimentoAssinatura.vue';
import SelecionarStatusAssinatura from '@/components/PainelAdministrativo/SelecionarStatusAssinatura.vue';
import { IColumn } from '@/core/models/AntDesign/IColumn';

export default defineComponent({
  name: 'PlanoAssinaturaEmpresaModal',
  props: {
    visivel: {
      type: Boolean,
    },
  },
  components: {
    Icone,
    Card,
    SelecionarTipoPlanoAssinatura,
    SelecionarData,
    SelecionarNivelAtendimentoAssinatura,
    SelecionarStatusAssinatura,
  },
  setup(props, { emit }) {
    const {
      telaBase
      ,
    } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const servicoPlanoAssinatura = new ServicoPlanoAssinatura();
    servicoPlanoAssinatura.requisicaoSistema();

    telaBase.identificadorRecurso = 'PAINEL_EMPRESAS';
    telaBase.identificadorPermissao = 'AUT_PAINEL_EMPRESAS';

    const state = reactive({
      planoAssinatura: {} as IDTOPlanoAssinatura,
      colunasModulos: [] as IColumn[],
      colunasLimites: [] as IColumn[],
    });

    state.colunasModulos = [
      {
        dataIndex: 'tituloModulo', key: 'TituloModulo', position: 0, visible: true, ellipsis: true,
      },
    ];

    state.colunasLimites = [
      {
        dataIndex: 'titulo', key: 'Titulo', position: 0, visible: true, ellipsis: true,
      },
      {
        dataIndex: 'limite', key: 'Limite', position: 1, visible: true, ellipsis: true, align: 'right',
      },
    ];

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      if (modalBase.computedVisivel) {
        state.planoAssinatura = await servicoPlanoAssinatura.obterPlanoAssinaturaEmpresa(storeSistema.getters.codigoEmpresaOperacao());
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      UtilitarioMascara,
      UtilitarioGeral,
      UtilitarioData,
    };
  },
});
