
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import Card from '@/core/components/Tela/Card.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import ServicoConfiguracaoDocumentoFiscalEletronico from '@/servicos/MeuSistema/ServicoConfiguracaoDocumentoFiscalEletronico';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import EditorHtml from '@/core/components/Tela/EditorHtml.vue';
import SelecionarVariavelDocumentoFiscalEletronico from '@/components/Fiscal/SelecionarVariavelDocumentoFiscalEletronico.vue';
import {
  IDTOConfiguracaoMDFe, IDTOConfiguracaoNFCe, IDTOConfiguracaoNFe, IDTOConfiguracaoNFSe, IDTOContratante, IDTOContratanteConfiguracao, IDTOResponsavelTecnico, IDTOServidorSmtp,
} from '@/models/DTO/Integracoes/NeoXFiscal/IDTOContratanteConfiguracao';
import SelecionarAmbienteDFe from '@/components/Fiscal/SelecionarAmbienteDFe.vue';
import SelecionarTipoEmissaoNFe from '@/components/Fiscal/SelecionarTipoEmissaoNFe.vue';
import SelecionarTipoEmissaoNFCe from '@/components/Fiscal/SelecionarTipoEmissaoNFCe.vue';
import SelecionarTipoEmitenteMDFe from '@/components/Fiscal/SelecionarTipoEmitenteMDFe.vue';
import CampoCnpj from '@/core/components/Tela/CampoCnpj.vue';
import CampoTelefone from '@/core/components/Tela/CampoTelefone.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { IEmpresa } from '@/models/Entidades/MeuSistema/Empresas/IEmpresa';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import ServicoCidade from '@/servicos/Cadastros/Localizacoes/ServicoCidade';
import { IDTOEmpresaLimiteDocumentoFiscalEletronico } from '@/models/DTO/MeuSistema/IDTOEmpresaLimiteDocumentoFiscalEletronico';
import { IDTONovoContratante } from '@/models/DTO/Integracoes/NeoXFiscal/IDTONovoContratante';
import { IDTORetornoCadastroContratante } from '@/models/DTO/Integracoes/NeoXFiscal/IDTORetornoCadastroContratante';

export default defineComponent({
  name: 'ConfiguracaoDocumentosFiscaisEletronicosModal',
  props: {
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Object as () => IEmpresa,
      required: true,
    },
    limitesDocumentosFiscais: {
      type: Object as () => IDTOEmpresaLimiteDocumentoFiscalEletronico,
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    MensagemSemDados,
    SelecionarAmbienteDFe,
    SelecionarTipoEmissaoNFe,
    SelecionarTipoEmissaoNFCe,
    SelecionarTipoEmitenteMDFe,
    SelecionarData,
    EditorHtml,
    SelecionarVariavelDocumentoFiscalEletronico,
    CampoCnpj,
    CampoTelefone,
    SelecionarSimNao,
    RequisicaoModal,
  },
  emits: ['update:visivel', 'confirmacao', 'atualizarTokenFiscal'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta, apresentarMensagemSucesso } = useTelaBase();

    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);

    const servicoConfiguracaoDocumentoFiscalEletronico = new ServicoConfiguracaoDocumentoFiscalEletronico();
    const servicoCidade = new ServicoCidade();

    const state = reactive({
      configuracao: {} as IDTOContratanteConfiguracao,
      mensagem: '',
      variavelSelecionada: '@NUMERO_DOCUMENTO_FISCAL',
      enviandoLogo: false,
      enviandoCertificado: false,
      exibirRestaurarTemplate: false,
      arquivoCertificado: {} as any,
      senhaCertificado: '',
      abaAtiva: '',
      apresentarModalSenha: false,
      keyCertificados: 1,
    });

    function limparTela() {
      state.configuracao = {} as IDTOContratanteConfiguracao;
      state.configuracao.certificados = [];
      state.configuracao.contratante = {} as IDTOContratante;
      state.configuracao.contratante.codigo = 0;
      state.configuracao.contratante.cpfCnpj = props.empresa.cpfCnpj;
      state.configuracao.contratante.nomeRazaoSocial = props.empresa.razaoSocial;
      state.configuracao.contratante.nomeFantasia = props.empresa.nomeFantasia;
      state.configuracao.contratante.uf = '';
      state.configuracao.contratante.codigoUf = 0;
      state.configuracao.contratante.status = 1;

      state.configuracao.configuracaoNFe = {} as IDTOConfiguracaoNFe;
      state.configuracao.configuracaoNFe.codigo = 0;
      state.configuracao.configuracaoNFe.codigoContratante = 0;
      state.configuracao.configuracaoNFe.ambiente = 2;
      state.configuracao.configuracaoNFe.tipoEmissao = 1;
      state.configuracao.configuracaoNFe.tipoImpressao = 1;
      state.configuracao.configuracaoNFe.imprimirNomeFantasiaEmitente = false;
      state.configuracao.configuracaoNFe.imprimirValorLiquidoDanfe = false;
      state.configuracao.configuracaoNFe.quebrarLinhaProduto = false;
      state.configuracao.configuracaoNFe.enviarEmail = true;
      state.configuracao.configuracaoNFe.assuntoEmail = 'NF-e:  @NUMERO_DOCUMENTO_FISCAL emitida com sucesso!';
      state.configuracao.configuracaoNFe.corpoEmail = '';

      state.configuracao.configuracaoNFCe = {} as IDTOConfiguracaoNFCe;
      state.configuracao.configuracaoNFCe.codigo = 0;
      state.configuracao.configuracaoNFCe.codigoContratante = 0;
      state.configuracao.configuracaoNFCe.ambiente = 2;
      state.configuracao.configuracaoNFCe.tipoEmissao = 1;
      state.configuracao.configuracaoNFCe.tipoImpressao = 4;
      state.configuracao.configuracaoNFCe.enviarEmail = true;
      state.configuracao.configuracaoNFCe.assuntoEmail = 'NFC-e:  @NUMERO_DOCUMENTO_FISCAL emitida com sucesso!';
      state.configuracao.configuracaoNFCe.corpoEmail = '';

      state.configuracao.configuracaoMDFe = {} as IDTOConfiguracaoMDFe;
      state.configuracao.configuracaoMDFe.codigo = 0;
      state.configuracao.configuracaoMDFe.codigoContratante = 0;
      state.configuracao.configuracaoMDFe.ambiente = 2;
      state.configuracao.configuracaoMDFe.tipoEmissao = 1;
      state.configuracao.configuracaoMDFe.tipoEmitente = 1;

      state.configuracao.configuracaoNFSe = {} as IDTOConfiguracaoNFSe;
      state.configuracao.configuracaoNFSe.codigo = 0;
      state.configuracao.configuracaoNFSe.codigoContratante = 0;
      state.configuracao.configuracaoNFSe.ambiente = 1;
      state.configuracao.configuracaoNFSe.enviarEmail = true;
      state.configuracao.configuracaoNFSe.assuntoEmail = 'NFS-e:  @NUMERO_DOCUMENTO_FISCAL emitida com sucesso!';
      state.configuracao.configuracaoNFSe.corpoEmail = '';

      state.configuracao.responsavelTecnico = {} as IDTOResponsavelTecnico;
      state.configuracao.responsavelTecnico.codigo = 0;
      state.configuracao.responsavelTecnico.codigoContratante = 0;
      state.configuracao.responsavelTecnico.cnpj = '';
      state.configuracao.responsavelTecnico.contato = '';
      state.configuracao.responsavelTecnico.email = '';
      state.configuracao.responsavelTecnico.telefone = '';
      state.configuracao.responsavelTecnico.identificadorCsrt = 0;
      state.configuracao.responsavelTecnico.hashCsrt = '';

      state.configuracao.servidorSmtp = {} as IDTOServidorSmtp;
      state.configuracao.servidorSmtp.codigo = 0;
      state.configuracao.servidorSmtp.codigoContratante = 0;
      state.configuracao.servidorSmtp.servidor = '';
      state.configuracao.servidorSmtp.email = '';
      state.configuracao.servidorSmtp.senha = '';
      state.configuracao.servidorSmtp.porta = '465';
      state.configuracao.servidorSmtp.ssl = true;

      state.mensagem = '';
      state.variavelSelecionada = '@NUMERO_DOCUMENTO_FISCAL';
      state.exibirRestaurarTemplate = false;
    }

    function restaurarTemplateEmailNFe() {
      state.configuracao.configuracaoNFe.corpoEmail = servicoConfiguracaoDocumentoFiscalEletronico.obterTemplateEmailNFe();
    }

    function restaurarTemplateEmailNFCe() {
      state.configuracao.configuracaoNFCe.corpoEmail = servicoConfiguracaoDocumentoFiscalEletronico.obterTemplateEmailNFCe();
    }

    function restaurarTemplateEmailNFSe() {
      state.configuracao.configuracaoNFSe.corpoEmail = servicoConfiguracaoDocumentoFiscalEletronico.obterTemplateEmailNFSe();
    }

    function adicionarVariavelAssuntoNFe() {
      state.configuracao.configuracaoNFe.assuntoEmail += ` ${state.variavelSelecionada}`;
      state.variavelSelecionada = '@NUMERO_DOCUMENTO_FISCAL';
    }

    function adicionarVariavelCorpoNFe() {
      state.configuracao.configuracaoNFe.corpoEmail += ` ${state.variavelSelecionada}`;
      state.variavelSelecionada = '@NUMERO_DOCUMENTO_FISCAL';
    }

    function adicionarVariavelAssuntoNFCe() {
      state.configuracao.configuracaoNFCe.assuntoEmail += ` ${state.variavelSelecionada}`;
      state.variavelSelecionada = '@NUMERO_DOCUMENTO_FISCAL';
    }

    function adicionarVariavelCorpoNFCe() {
      state.configuracao.configuracaoNFCe.corpoEmail += ` ${state.variavelSelecionada}`;
      state.variavelSelecionada = '@NUMERO_DOCUMENTO_FISCAL';
    }

    function adicionarVariavelAssuntoNFSe() {
      state.configuracao.configuracaoNFSe.assuntoEmail += ` ${state.variavelSelecionada}`;
      state.variavelSelecionada = '@NUMERO_DOCUMENTO_FISCAL';
    }

    function adicionarVariavelCorpoNFSe() {
      state.configuracao.configuracaoNFSe.corpoEmail += ` ${state.variavelSelecionada}`;
      state.variavelSelecionada = '@NUMERO_DOCUMENTO_FISCAL';
    }

    async function salvar() {
      state.configuracao.contratante.emiteNFe = (props.limitesDocumentosFiscais.limiteQuantidadeNFe > 0);
      state.configuracao.contratante.limiteNFe = props.limitesDocumentosFiscais.limiteQuantidadeNFe;

      state.configuracao.contratante.emiteNFCe = (props.limitesDocumentosFiscais.limiteQuantidadeNFCe > 0);
      state.configuracao.contratante.limiteNFCe = props.limitesDocumentosFiscais.limiteQuantidadeNFCe;

      state.configuracao.contratante.emiteMDFe = (props.limitesDocumentosFiscais.limiteQuantidadeMDFe > 0);
      state.configuracao.contratante.limiteMDFe = props.limitesDocumentosFiscais.limiteQuantidadeMDFe;

      state.configuracao.contratante.emiteNFSe = (props.limitesDocumentosFiscais.limiteQuantidadeNFSe > 0);
      state.configuracao.contratante.limiteNFSe = props.limitesDocumentosFiscais.limiteQuantidadeNFSe;

      apresentarBarraProgresso('Aguarde por favor, estamos salvando as suas informações...');

      if (state.configuracao.contratante.codigo === 0 && !UtilitarioGeral.valorValido(props.limitesDocumentosFiscais.tokenFiscal)) {
        const retornoCadastroContratante = await servicoConfiguracaoDocumentoFiscalEletronico.salvar(props.empresa.codigo, state.configuracao);
        ocultarBarraProgresso();
        if (retornoCadastroContratante.sucesso) {
          apresentarMensagemSucesso('Informações atualizadas com sucesso!');
          emit('atualizarTokenFiscal', retornoCadastroContratante.token);
          modalBase.computedVisivel = false;
        }
      } else {
        const retornoRequisicao = await servicoConfiguracaoDocumentoFiscalEletronico.atualizar(props.empresa.codigo, state.configuracao);
        ocultarBarraProgresso();
        if (retornoRequisicao.status === EStatusRetornoRequisicao.Sucesso) {
          apresentarMensagemSucesso('Informações atualizadas com sucesso!');
          emit('confirmacao', state.configuracao);
          modalBase.computedVisivel = false;
        } else {
          apresentarRetornoRequisicao(retornoRequisicao);
        }
      }
    }

    async function novoContratante(): Promise<IDTORetornoCadastroContratante> {
      let retorno:IDTORetornoCadastroContratante = {} as IDTORetornoCadastroContratante;
      retorno.sucesso = false;
      const novoContratante:IDTONovoContratante = {} as IDTONovoContratante;

      novoContratante.cpfCnpj = props.empresa.cpfCnpj;
      novoContratante.nomeRazaoSocial = props.empresa.razaoSocial;
      novoContratante.nomeFantasia = props.empresa.nomeFantasia;
      novoContratante.aplicacaoApi = 'NeoX';

      if (UtilitarioGeral.validaCodigo(props.empresa.endereco.codigoCidade)) {
        const cidade = await servicoCidade.obterCidadeDetalhada(props.empresa.endereco.codigoCidade);
        novoContratante.uf = cidade.uf;
        novoContratante.codigoUf = Number(cidade.codigoEstado);
      } else {
        retorno.mensagem = 'Cidade da empresa não informada ou inválida.';
        return retorno;
      }

      if (UtilitarioGeral.valorValido(props.empresa.email)) {
        novoContratante.email = props.empresa.email;
      } else {
        novoContratante.email = `adm.${props.empresa.cpfCnpj}@fiscal.neox.app.br`;
      }

      novoContratante.emiteNFe = (props.limitesDocumentosFiscais.limiteQuantidadeNFe > 0);
      novoContratante.limiteNFe = props.limitesDocumentosFiscais.limiteQuantidadeNFe;

      novoContratante.emiteNFCe = (props.limitesDocumentosFiscais.limiteQuantidadeNFCe > 0);
      novoContratante.limiteNFCe = props.limitesDocumentosFiscais.limiteQuantidadeNFCe;

      novoContratante.emiteMDFe = (props.limitesDocumentosFiscais.limiteQuantidadeMDFe > 0);
      novoContratante.limiteMDFe = props.limitesDocumentosFiscais.limiteQuantidadeMDFe;

      novoContratante.emiteNFSe = (props.limitesDocumentosFiscais.limiteQuantidadeNFSe > 0);
      novoContratante.limiteNFSe = props.limitesDocumentosFiscais.limiteQuantidadeNFSe;

      retorno = await servicoConfiguracaoDocumentoFiscalEletronico.novoContratante(props.empresa.codigo, novoContratante);
      return retorno;
    }

    function selecionarLogoUpload() {
      const elemento = document.getElementById('selecionarLogoDocumentoFiscalEletronico');
      if (elemento !== null) {
        elemento.click();
      }
    }

    async function enviarImagens(event: any) {
      state.enviandoLogo = true;
      const retornoLogo = await servicoConfiguracaoDocumentoFiscalEletronico.enviarLogo(props.empresa.codigo, event.target.files[0]);
      if (UtilitarioGeral.valorValido(retornoLogo)) {
        state.configuracao.contratante.logo = retornoLogo;
      }
      state.enviandoLogo = false;
    }

    async function dropImagem(event: any) {
      state.enviandoLogo = true;

      const retornoLogo = await servicoConfiguracaoDocumentoFiscalEletronico.enviarLogo(props.empresa.codigo, event.target.files[0]);
      if (UtilitarioGeral.valorValido(retornoLogo)) {
        state.configuracao.contratante.logo = retornoLogo;
      }
      state.enviandoLogo = false;
    }

    async function excluirLogo() {
      state.configuracao.contratante.logo = '';
    }

    function selecionarCertificadoUpload() {
      const elemento = document.getElementById('selecionarCertificadoDocumentoFiscalEletronico');
      if (elemento !== null) {
        elemento.click();
      }
    }

    async function preparaEnvioCeritificado(event: any) {
      state.arquivoCertificado = event.target.files[0];
      state.senhaCertificado = '';
      state.apresentarModalSenha = true;
    }

    async function enviarCertificado() {
      state.apresentarModalSenha = false;
      state.enviandoCertificado = true;
      const certificadoEnviado = await servicoConfiguracaoDocumentoFiscalEletronico.enviarCertificado(props.empresa.codigo, state.arquivoCertificado, state.senhaCertificado);
      state.keyCertificados += 1;
      if (UtilitarioGeral.objetoValido(certificadoEnviado)) {
        if (UtilitarioGeral.valorValido(certificadoEnviado.numeroSerie)) {
          if (state.configuracao.certificados.some((c) => c.numeroSerie === certificadoEnviado.numeroSerie)) {
            apresentarMensagemAlerta('Certificado já foi adicionado!');
          } else {
            state.configuracao.certificados.push(certificadoEnviado);
          }
        } else {
          apresentarMensagemAlerta('Certificado ou senha inválida!');
        }
      }
      state.enviandoCertificado = false;
    }

    async function dropCertificado(event: any) {
      state.enviandoCertificado = true;

      const certificadoEnviado = await servicoConfiguracaoDocumentoFiscalEletronico.enviarCertificado(props.empresa.codigo, event.dataTransfer.files[0]);
      state.keyCertificados += 1;
      if (UtilitarioGeral.objetoValido(certificadoEnviado)) {
        if (UtilitarioGeral.valorValido(certificadoEnviado.numeroSerie)) {
          if (state.configuracao.certificados.some((c) => c.numeroSerie === certificadoEnviado.numeroSerie)) {
            apresentarMensagemAlerta('Certificado já foi adicionado!');
          } else {
            state.configuracao.certificados.push(certificadoEnviado);
          }
        }
      }
      state.enviandoCertificado = false;
    }

    function removerCertificado(index: number) {
      state.configuracao.certificados.splice(index, 1);
      state.keyCertificados += 1;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();
      if (modalBase.computedVisivel) {
        const retornoVerificarCredenciamento = await servicoConfiguracaoDocumentoFiscalEletronico.verificarCredenciamento(props.empresa.cpfCnpj);
        if (retornoVerificarCredenciamento.semResposta) {
          apresentarMensagemAlerta('Não foi possível as configurações de Documentos Fiscais! Aguarde um pouco e tente novamente.');
          telaBase.carregando = false;
          return;
        }

        if (!retornoVerificarCredenciamento.existeCredenciamento) {
          const retornoNovoContratante = await novoContratante();
          if (retornoNovoContratante.sucesso) {
            emit('atualizarTokenFiscal', retornoNovoContratante.token);
          } else {
            if (UtilitarioGeral.valorValido(retornoNovoContratante.mensagem)) {
              apresentarMensagemAlerta(retornoNovoContratante.mensagem);
            }
            telaBase.carregando = false;
          }
        }

        const configuracoesDocumentoFiscalEletronico = await servicoConfiguracaoDocumentoFiscalEletronico.obterConfiguracoes(props.empresa.codigo);
        if (configuracoesDocumentoFiscalEletronico != null && configuracoesDocumentoFiscalEletronico.contratante.codigo > 0) {
          state.configuracao = configuracoesDocumentoFiscalEletronico;
        }
        if (UtilitarioGeral.validaCodigo(props.empresa.endereco.codigoCidade)) {
          const cidade = await servicoCidade.obterCidadeDetalhada(props.empresa.endereco.codigoCidade);

          state.configuracao.contratante.uf = cidade.uf;
          state.configuracao.contratante.codigoUf = Number(cidade.codigoEstado);
        }

        if (!UtilitarioGeral.valorValido(state.configuracao.contratante.logo) && UtilitarioGeral.valorValido(props.empresa.logo)) {
          state.configuracao.contratante.logo = props.empresa.logo;
        }

        if (!UtilitarioGeral.valorValido(state.configuracao.configuracaoNFe.corpoEmail)) {
          restaurarTemplateEmailNFe();
        }

        if (!UtilitarioGeral.valorValido(state.configuracao.configuracaoNFCe.corpoEmail)) {
          restaurarTemplateEmailNFCe();
        }

        if (!UtilitarioGeral.valorValido(state.configuracao.configuracaoNFSe.corpoEmail)) {
          restaurarTemplateEmailNFSe();
        }
        if (props.limitesDocumentosFiscais.limiteQuantidadeNFe > 0) {
          state.abaAtiva = '1';
        } else if (props.limitesDocumentosFiscais.limiteQuantidadeNFCe > 0) {
          state.abaAtiva = '2';
        } else if (props.limitesDocumentosFiscais.limiteQuantidadeMDFe > 0) {
          state.abaAtiva = '3';
        } else if (props.limitesDocumentosFiscais.limiteQuantidadeNFSe > 0) {
          state.abaAtiva = '4';
        }
      }
      telaBase.carregando = false;
    });

    function validarInformacaoCampo(evento:any, informacao: any) {
      if (!/[0-9]/.test(informacao)) {
        evento.preventDefault();
      }
    }

    function validarInformacaoDigitada(evento:any) {
      const charCode = String.fromCharCode(evento.keyCode);
      validarInformacaoCampo(evento, charCode);
    }

    function validarInformacaoColada(evento:any) {
      const informacao = evento.clipboardData.getData('text');
      validarInformacaoCampo(evento, informacao);
    }

    return {
      props,
      state,
      telaBase,
      modalBase,
      UtilitarioGeral,
      UtilitarioData,
      restaurarTemplateEmailNFe,
      restaurarTemplateEmailNFCe,
      restaurarTemplateEmailNFSe,
      adicionarVariavelAssuntoNFe,
      adicionarVariavelCorpoNFe,
      adicionarVariavelAssuntoNFCe,
      adicionarVariavelCorpoNFCe,
      adicionarVariavelAssuntoNFSe,
      adicionarVariavelCorpoNFSe,
      salvar,
      selecionarLogoUpload,
      enviarImagens,
      dropImagem,
      excluirLogo,
      preparaEnvioCeritificado,
      selecionarCertificadoUpload,
      enviarCertificado,
      dropCertificado,
      removerCertificado,
      validarInformacaoDigitada,
      validarInformacaoColada,
    };
  },
});
