
import { defineComponent, reactive, watch } from 'vue';
import { Modal } from 'ant-design-vue';
import NavegacaoRegistro from '@/core/components/Tela/NavegacaoRegistro.vue';
import EditorHtml from '@/core/components/Tela/EditorHtml.vue';
import SelecionarCor from '@/core/components/Tela/SelecionarCor.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import SelecionarUsuario from '@/components/MeuSistema/Usuarios/SelecionarUsuario.vue';
import SelecionarUsuarioAgrupadoPerfilModal from '@/components/MeuSistema/Usuarios/SelecionarUsuarioAgrupadoPerfilModal.vue';
import SelecionarEmpresa from '@/components/MeuSistema/Empresas/SelecionarEmpresa.vue';
import SelecionarPessoa from '@/components/Cadastros/Pessoas/SelecionarPessoa.vue';
import SelecionarMarcador from '@/components/MeuSistema/Agendamentos/SelecionarMarcador.vue';
import Card from '@/core/components/Tela/Card.vue';

import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { IOption } from '@/core/models/AntDesign/IOption';
import {
  IAgendamento, IAgendamentoComentario, IAgendamentoMarcador, IAgendamentoPessoa, IAgendamentoUsuario,
} from '@/models/Entidades/MeuSistema/Agendamentos/IAgendamento';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { ETipoAgendamento } from '@/models/Enumeradores/MeuSistema/Agendamentos/ETipoAgendamento';
import { ETempoNotificacaoAgendamento } from '@/models/Enumeradores/MeuSistema/Agendamentos/ETempoNotificacaoAgendamento';
import { ETipoNotificacao } from '@/models/Enumeradores/MeuSistema/Notificacoes/ETipoNotificacao';
import { EIntervaloRepeticao } from '@/models/Enumeradores/MeuSistema/Agendamentos/EIntervaloRepeticao';
import storeSistema from '@/store/storeSistema';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import ServicoAgendamento from '@/servicos/MeuSistema/ServicoAgendamento';
import ServicoUsuario from '@/servicos/MeuSistema/ServicoUsuario';
import { ENavegacaoRegistro } from '@/core/models/Enumeradores/ENavegacaoRegistro';
import BarraProgresso from '@/core/components/Tela/BarraProgresso.vue';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';

export default defineComponent({
  name: 'AgendamentoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
    autorizacaoTodosUsuarios: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RequisicaoModal,
    Card,
    Icone,
    NavegacaoRegistro,
    BarraProgresso,
    EditorHtml,
    SelecionarData,
    SelecionarCor,
    SelecionarEmpresa,
    SelecionarUsuario,
    SelecionarUsuarioAgrupadoPerfilModal,
    SelecionarPessoa,
    SelecionarMarcador,
  },
  emits: ['sincronizarAgendamento', 'limparVisualizacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, preencherEmpresasDisponiveis, montaOpcoesComListaItemGenerico,
      apresentarMensagemSucesso, apresentarMensagemAlerta,
    } = useTelaBase();

    const { modalBase, apresentarRetornoRequisicao } = useModalBase(props, emit);
    const servicoAgendamento = new ServicoAgendamento();
    const servicoUsuario = new ServicoUsuario();

    telaBase.empresasSelecionadas = [];
    const stateModal = reactive({
      agendamento: {} as IAgendamento,
      adicionarParticipante: false,
      listaTipoAgendamento: [] as IOption[],
      listaTipoNotificacoes: [] as IOption[],
      listaTempoNotificacoes: [] as IOption[],
      listaIntervaloRepeticao: [] as IOption[],
      listaIntervaloRepeticaoFiltrada: [] as IOption[],
      pessoasSelecionadas: [] as number[],
      participantesSelecionados: [] as number[],
      marcadoresSelecionados: [] as string[],
      usuarios: [] as IItemConsultaRapida[],
      comentario: '',
      repetirAgendamento: false,
      dataInicioRepeticao: '',
      dataFimRepeticao: '',
      intervaloRepeticao: undefined,
      codigoComentarioEmAlteracao: 0,
    });

    (async () => {
      await preencherEmpresasDisponiveis();

      const listaTipoAgendamento = await servicoAgendamento.obterListaTipoAgendamentos();
      stateModal.listaTipoAgendamento = montaOpcoesComListaItemGenerico(listaTipoAgendamento);

      const listaTipoNotificacoes = await servicoAgendamento.obterListaTipoNotificacoes();
      stateModal.listaTipoNotificacoes = montaOpcoesComListaItemGenerico(listaTipoNotificacoes);

      const listaTempoNotificacoes = await servicoAgendamento.obterListaTempoNotificacoes();
      stateModal.listaTempoNotificacoes = montaOpcoesComListaItemGenerico(listaTempoNotificacoes);

      const listaIntervaloRepeticao = await servicoAgendamento.obterListaIntervaloRepeticao();
      stateModal.listaIntervaloRepeticao = montaOpcoesComListaItemGenerico(listaIntervaloRepeticao);
    })();

    function preparaTela() {
      stateModal.agendamento = {} as IAgendamento;
      stateModal.agendamento.codigo = 0;
      stateModal.agendamento.concluido = false;
      stateModal.agendamento.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      stateModal.agendamento.codigoEmpresa = storeSistema.getters.codigoEmpresaOperacao();
      stateModal.agendamento.cor = '#fbbd08';
      stateModal.agendamento.tipoAgendamento = ETipoAgendamento.Compromisso;
      stateModal.agendamento.tipoNotificacao = ETipoNotificacao.Normal;
      stateModal.agendamento.tempoNotificacao = ETempoNotificacaoAgendamento.DezMinutosAntes;
      stateModal.agendamento.participantes = [] as IAgendamentoUsuario[];
      stateModal.agendamento.pessoas = [] as IAgendamentoPessoa[];
      stateModal.agendamento.marcadores = [] as IAgendamentoMarcador[];
      stateModal.agendamento.comentarios = [] as IAgendamentoComentario[];
      stateModal.pessoasSelecionadas = [];
      stateModal.participantesSelecionados = [];
      stateModal.marcadoresSelecionados = [];

      stateModal.comentario = '';
      stateModal.usuarios = [];
      stateModal.codigoComentarioEmAlteracao = 0;
      stateModal.listaIntervaloRepeticaoFiltrada = [];
      stateModal.repetirAgendamento = false;
      stateModal.dataInicioRepeticao = '';
      stateModal.dataFimRepeticao = '';
      stateModal.intervaloRepeticao = undefined;
    }

    function cancelarEdicaoComentario() {
      stateModal.comentario = '';
      stateModal.codigoComentarioEmAlteracao = 0;
    }

    async function definirDataHoraConclusao() {
      if (stateModal.agendamento.concluido) {
        const servicoSistema = new ServicoSistema();
        const dataAtual = await servicoSistema.obterDataAtual();
        if (UtilitarioGeral.valorValido(dataAtual)) {
          stateModal.agendamento.dataHoraConclusao = dataAtual;
        }
      } else {
        stateModal.agendamento.dataHoraConclusao = '';
      }
    }
    function defineTempoNotificacao() {
      stateModal.agendamento.tempoNotificacao = ETempoNotificacaoAgendamento.Personalizado;
    }
    function defineDataHorarioNotificacao() {
      stateModal.dataInicioRepeticao = UtilitarioData.converterDataJson(stateModal.agendamento.dataHoraInicio);
      if (!UtilitarioGeral.valorValido(stateModal.agendamento.dataHoraInicio) && stateModal.agendamento.tempoNotificacao !== ETempoNotificacaoAgendamento.NaoNotificar) { return; }

      switch (stateModal.agendamento.tempoNotificacao) {
        case ETempoNotificacaoAgendamento.NaoNotificar:
          stateModal.agendamento.dataHoraNotificacao = '';
          break;
        case ETempoNotificacaoAgendamento.CincoMinutosAntes:
          stateModal.agendamento.dataHoraNotificacao = UtilitarioData.diminuirMinutosNaData(stateModal.agendamento.dataHoraInicio, 5);
          break;

        case ETempoNotificacaoAgendamento.DezMinutosAntes:
          stateModal.agendamento.dataHoraNotificacao = UtilitarioData.diminuirMinutosNaData(stateModal.agendamento.dataHoraInicio, 10);
          break;

        case ETempoNotificacaoAgendamento.QuinzeMinutosAntes:
          stateModal.agendamento.dataHoraNotificacao = UtilitarioData.diminuirMinutosNaData(stateModal.agendamento.dataHoraInicio, 15);
          break;

        case ETempoNotificacaoAgendamento.TrintaMinutosAntes:
          stateModal.agendamento.dataHoraNotificacao = UtilitarioData.diminuirMinutosNaData(stateModal.agendamento.dataHoraInicio, 30);
          break;

        case ETempoNotificacaoAgendamento.UmaHoraAntes:
          stateModal.agendamento.dataHoraNotificacao = UtilitarioData.diminuirHorasNaData(stateModal.agendamento.dataHoraInicio, 1);
          break;

        case ETempoNotificacaoAgendamento.DuasHorasAntes:
          stateModal.agendamento.dataHoraNotificacao = UtilitarioData.diminuirHorasNaData(stateModal.agendamento.dataHoraInicio, 2);
          break;

        case ETempoNotificacaoAgendamento.UmDiaAntes:
          stateModal.agendamento.dataHoraNotificacao = UtilitarioData.diminuirDiaNaDataHoraMinutoSegundo(stateModal.agendamento.dataHoraInicio, 1);
          break;
        default:
      }
    }
    async function adicionarComentario() {
      if (!UtilitarioGeral.valorValido(stateModal.comentario)) {
        apresentarMensagemAlerta('Digite o seu comentário!');
        return;
      }

      if (stateModal.codigoComentarioEmAlteracao > 0) {
        const retornoComentario = await servicoAgendamento.alterarComentario(stateModal.agendamento.codigo, stateModal.agendamento.codigoEmpresa, stateModal.codigoComentarioEmAlteracao, stateModal.comentario);
        if (retornoComentario.status === EStatusRetornoRequisicao.Sucesso) {
          const comentarioApi = await servicoAgendamento.obterComentario(stateModal.agendamento.codigo, stateModal.codigoComentarioEmAlteracao);
          const comentarioLancado = stateModal.agendamento.comentarios.find((c) => c.codigo === stateModal.codigoComentarioEmAlteracao);
          if (comentarioLancado !== undefined) {
            comentarioLancado.comentario = comentarioApi.comentario;
            comentarioLancado.dataHora = comentarioApi.dataHora;
          }
          cancelarEdicaoComentario();
        } else {
          apresentarMensagemAlerta(retornoComentario.mensagem);
        }
      } else {
        const retornoComentario = await servicoAgendamento.incluirComentario(stateModal.agendamento.codigo, stateModal.agendamento.codigoEmpresa, stateModal.comentario);
        if (retornoComentario.status === EStatusRetornoRequisicao.Sucesso) {
          const comentario = await servicoAgendamento.obterComentario(stateModal.agendamento.codigo, retornoComentario.codigoRegistro);
          comentario.nomeUsuario = storeSistema.getters.nomeUsuarioAutenticado();
          stateModal.agendamento.comentarios.unshift(comentario);
          stateModal.comentario = '';
        } else {
          apresentarMensagemAlerta(retornoComentario.mensagem);
        }
      }
    }

    async function editarComentario(codigoComentario:number, comentario: string) {
      stateModal.comentario = comentario;
      stateModal.codigoComentarioEmAlteracao = codigoComentario;
    }

    async function excluirComentario(codigoComentario: number, indice: number) {
      const retornoComentario = await servicoAgendamento.excluirComentario(stateModal.agendamento.codigo, stateModal.agendamento.codigoEmpresa, codigoComentario);
      if (retornoComentario.status === EStatusRetornoRequisicao.Sucesso) {
        stateModal.agendamento.comentarios.splice(indice, 1);
      } else {
        apresentarMensagemAlerta(retornoComentario.mensagem);
      }
    }

    function adicionaPessoa(codigoPessoa:number) {
      const agendamentoPessoa:IAgendamentoPessoa = {} as IAgendamentoPessoa;
      agendamentoPessoa.codigoAgendamento = stateModal.agendamento.codigo;
      agendamentoPessoa.codigoPessoa = codigoPessoa;
      stateModal.agendamento.pessoas.push(agendamentoPessoa);
    }

    function verificaPessoaJaAdicionada(codigoPessoa:number): boolean {
      if (UtilitarioGeral.validaLista(stateModal.agendamento.pessoas)) {
        const pessoaExistente = stateModal.agendamento.pessoas.find((c) => c.codigoPessoa === codigoPessoa);
        if (pessoaExistente !== undefined) {
          return true;
        }
      }
      return false;
    }

    function preparaPersistenciaPessoas(): boolean {
      try {
        const removerIndexPessoa: Array<number> = [];
        // Verifica se tem pessoas removidas
        for (let i = 0; i < stateModal.agendamento.pessoas.length; (i += 1)) {
          if (!stateModal.pessoasSelecionadas.includes(stateModal.agendamento.pessoas[i].codigoPessoa)) {
            removerIndexPessoa.push(i);
          }
        }

        // Remove do objeto principal as pessoas removidas
        removerIndexPessoa.forEach(async (index) => {
          if (index !== -1) {
            stateModal.agendamento.pessoas.splice(index, 1);
          }
        });

        // Adiciona se tiver novas pessoas
        if (UtilitarioGeral.validaLista(stateModal.pessoasSelecionadas)) {
          stateModal.pessoasSelecionadas.forEach((codigoPessoa) => {
            if (!verificaPessoaJaAdicionada(codigoPessoa)) {
              adicionaPessoa(codigoPessoa);
            }
          });
        }

        return true;
      } catch (error) {
        apresentarMensagemAlerta('Desculpe-nos não conseguimos salvar o agendamento no momento!');
      }
      return false;
    }

    function adicionaParticipante(codigoUsuario:number) {
      const agendamentoUsuario:IAgendamentoUsuario = {} as IAgendamentoUsuario;
      agendamentoUsuario.codigoAgendamento = stateModal.agendamento.codigo;
      agendamentoUsuario.codigoUsuario = codigoUsuario;
      stateModal.agendamento.participantes.push(agendamentoUsuario);
    }

    function verificaParticipanteJaAdicionado(codigoUsuario:number): boolean {
      if (UtilitarioGeral.validaLista(stateModal.agendamento.participantes)) {
        const participanteExistente = stateModal.agendamento.participantes.find((c) => c.codigoUsuario === codigoUsuario);
        if (participanteExistente !== undefined) {
          return true;
        }
      }
      return false;
    }

    function preparaPersistenciaParticipantes(): boolean {
      try {
        const removerIndexParticipante: Array<number> = [];
        // Verifica se tem participantes removidos
        for (let i = 0; i < stateModal.agendamento.participantes.length; (i += 1)) {
          if (!stateModal.participantesSelecionados.includes(stateModal.agendamento.participantes[i].codigoUsuario)) {
            removerIndexParticipante.push(i);
          }
        }

        // Remove do objeto principal os participantes removidos
        removerIndexParticipante.forEach(async (index) => {
          if (index !== -1) {
            stateModal.agendamento.participantes.splice(index, 1);
          }
        });

        // Adiciona se tiver novos participantes
        if (UtilitarioGeral.validaLista(stateModal.participantesSelecionados)) {
          stateModal.participantesSelecionados.forEach((codigoParticipante) => {
            if (!verificaParticipanteJaAdicionado(codigoParticipante)) {
              adicionaParticipante(codigoParticipante);
            }
          });
        }

        return true;
      } catch (error) {
        apresentarMensagemAlerta('Desculpe-nos não conseguimos salvar o agendamento no momento!');
      }
      return false;
    }

    function adicionaMarcador(marcador: string) {
      const agendamentoMarcador:IAgendamentoMarcador = {} as IAgendamentoMarcador;
      agendamentoMarcador.codigoAgendamento = stateModal.agendamento.codigo;
      agendamentoMarcador.marcador = marcador;
      stateModal.agendamento.marcadores.push(agendamentoMarcador);
    }

    function verificaMarcadorJaAdicionado(marcador:string): boolean {
      if (UtilitarioGeral.validaLista(stateModal.agendamento.marcadores)) {
        const marcadorExistente = stateModal.agendamento.marcadores.find((c) => c.marcador === marcador);
        if (marcadorExistente !== undefined) {
          return true;
        }
      }
      return false;
    }

    function preparaPersistenciaMarcadores(): boolean {
      try {
        const removerIndexMarcador: Array<number> = [];
        // Verifica se tem marcadores removidos
        for (let i = 0; i < stateModal.agendamento.marcadores.length; (i += 1)) {
          if (!stateModal.marcadoresSelecionados.includes(stateModal.agendamento.marcadores[i].marcador)) {
            removerIndexMarcador.push(i);
          }
        }

        // Remove do objeto principal os marcadores removidos
        removerIndexMarcador.forEach(async (index) => {
          if (index !== -1) {
            stateModal.agendamento.marcadores.splice(index, 1);
          }
        });

        // Adiciona se tiver novos marcadores
        if (UtilitarioGeral.validaLista(stateModal.marcadoresSelecionados)) {
          stateModal.marcadoresSelecionados.forEach((marcador) => {
            if (!verificaMarcadorJaAdicionado(marcador)) {
              adicionaMarcador(marcador);
            }
          });
        }

        return true;
      } catch (error) {
        apresentarMensagemAlerta('Desculpe-nos não conseguimos salvar o agendamento no momento!');
      }
      return false;
    }

    async function salvar() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      const retornoPreparacaoPessoas = await preparaPersistenciaPessoas();
      if (!retornoPreparacaoPessoas) return;

      const retornoPreparacaoParticipantes = await preparaPersistenciaParticipantes();
      if (!retornoPreparacaoParticipantes) return;

      const retornoPreparacaoMarcadores = await preparaPersistenciaMarcadores();
      if (!retornoPreparacaoMarcadores) return;

      telaBase.apresentarBarraProgresso = true;
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        if (stateModal.repetirAgendamento) {
          if (stateModal.intervaloRepeticao === undefined) {
            apresentarMensagemSucesso('É necessário selecionar um intervalo para repetir o agendamento!');
            return;
          }
        }
        retorno = await servicoAgendamento.incluir(stateModal.agendamento, stateModal.repetirAgendamento, stateModal.dataFimRepeticao, stateModal.intervaloRepeticao);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoAgendamento.alterar(stateModal.agendamento);
      }
      telaBase.apresentarBarraProgresso = false;
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          stateModal.agendamento.codigo = retorno.codigoRegistro;
          emit('sincronizarAgendamento', EPermissaoDados.Incluir, stateModal.agendamento.codigo);
        } else {
          emit('sincronizarAgendamento', EPermissaoDados.Alterar, stateModal.agendamento.codigo);
        }
        modalBase.computedVisivel = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    async function verificarAlteracaoEmpresa() {
      telaBase.empresasSelecionadas = [];
      telaBase.empresasSelecionadas.push(stateModal.agendamento.codigoEmpresa);
    }

    function adicionaParticipantesSelecionados(participantes: number[]) {
      participantes.forEach((participante) => {
        const existeParticipante = stateModal.participantesSelecionados.find((c) => c === participante);
        if (existeParticipante === undefined) {
          stateModal.participantesSelecionados.push(participante);
        }
      });
    }

    function verificaDataMinimaRepeticao(): boolean {
      stateModal.listaIntervaloRepeticaoFiltrada = [];
      stateModal.intervaloRepeticao = undefined;
      if (!stateModal.repetirAgendamento) { return true; }

      if (!UtilitarioGeral.valorValido(stateModal.dataInicioRepeticao)) {
        apresentarMensagemAlerta('Data/Hora Início não foi definida ainda!');
        return true;
      }

      if (UtilitarioGeral.valorValido(stateModal.dataFimRepeticao)) {
        const diferencaDatas = UtilitarioData.verificaDiferencaDiasEntreDatas(stateModal.dataInicioRepeticao, stateModal.dataFimRepeticao);
        if (diferencaDatas < 7) {
          apresentarMensagemAlerta('O período informado deve ter no mínimo 7 dias de diferença.');
          return true;
        }

        if (diferencaDatas <= 15) {
          stateModal.listaIntervaloRepeticaoFiltrada = stateModal.listaIntervaloRepeticao.filter((c) => c.value === EIntervaloRepeticao.Semanal);
        } else if (diferencaDatas <= 30) {
          stateModal.listaIntervaloRepeticaoFiltrada = stateModal.listaIntervaloRepeticao.filter((c) => c.value <= EIntervaloRepeticao.Quinzenal);
        } else if (diferencaDatas <= 60) {
          stateModal.listaIntervaloRepeticaoFiltrada = stateModal.listaIntervaloRepeticao.filter((c) => c.value <= EIntervaloRepeticao.Mensal);
        } else if (diferencaDatas <= 90) {
          stateModal.listaIntervaloRepeticaoFiltrada = stateModal.listaIntervaloRepeticao.filter((c) => c.value <= EIntervaloRepeticao.Bimestral);
        } else if (diferencaDatas <= 180) {
          stateModal.listaIntervaloRepeticaoFiltrada = stateModal.listaIntervaloRepeticao.filter((c) => c.value <= EIntervaloRepeticao.Trimestral);
        } else if (diferencaDatas <= 360) {
          stateModal.listaIntervaloRepeticaoFiltrada = stateModal.listaIntervaloRepeticao.filter((c) => c.value < EIntervaloRepeticao.Anual);
        } else {
          stateModal.listaIntervaloRepeticaoFiltrada = stateModal.listaIntervaloRepeticao;
        }
      }

      return false;
    }
    function obtemDadosUsuario(codigoUsuario:number): string {
      if (codigoUsuario === storeSistema.getters.codigoUsuarioAutenticado()) {
        return `${codigoUsuario} - ${storeSistema.getters.nomeUsuarioAutenticado()}`;
      }
      if (UtilitarioGeral.validaLista(stateModal.usuarios)) {
        const usuario = stateModal.usuarios.find((c) => c.codigo === codigoUsuario);
        if (usuario !== undefined) {
          return `${codigoUsuario} - ${usuario.textoIdentificacao}`;
        }
      }

      return '';
    }
    async function obterAgendamento(codigoAgendamento:number, navegacao?:number, dataHoraInicio?:string) {
      telaBase.carregando = true;
      preparaTela();

      if (navegacao !== undefined) {
        stateModal.agendamento = await servicoAgendamento.obter(codigoAgendamento, navegacao, dataHoraInicio);
      } else {
        stateModal.agendamento = await servicoAgendamento.obter(codigoAgendamento);
      }
      await verificarAlteracaoEmpresa();
      stateModal.agendamento.pessoas.forEach((pessoa) => {
        stateModal.pessoasSelecionadas.push(pessoa.codigoPessoa);
      });

      stateModal.agendamento.participantes.forEach((participante) => {
        stateModal.participantesSelecionados.push(participante.codigoUsuario);
      });

      stateModal.agendamento.marcadores.forEach((marcador) => {
        stateModal.marcadoresSelecionados.push(marcador.marcador);
      });

      if (UtilitarioGeral.validaLista(stateModal.agendamento.comentarios)) {
        const codigoUsuarios = stateModal.agendamento.comentarios.map((comentario) => comentario.codigoUsuario);
        if (codigoUsuarios.some((codigo) => codigo !== storeSistema.getters.codigoUsuarioAutenticado())) {
          stateModal.usuarios = await servicoUsuario.obterUsuariosPorCodigosConsultaRapida(codigoUsuarios);
        }
      }

      telaBase.carregando = false;
    }

    async function excluirRegistro(codigo: number) {
      const retorno = await servicoAgendamento.excluir(codigo);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        emit('sincronizarAgendamento', EPermissaoDados.Excluir, stateModal.agendamento.codigo);
        modalBase.computedVisivel = false;
      }
    }

    function confirmaExclusao() {
      Modal.confirm({
        title: 'Confirma a exclusão do agendamento',
        content: `${stateModal.agendamento.codigo} - ${stateModal.agendamento.titulo} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: () => { excluirRegistro(stateModal.agendamento.codigo); },
      });
    }

    watch(async () => modalBase.computedVisivel, async () => {
      preparaTela();
      await verificarAlteracaoEmpresa();
      if (modalBase.computedVisivel) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          await obterAgendamento(props.operacao.codigoRegistro);
        }
      } else {
        emit('limparVisualizacao');
      }
    });

    return {
      telaBase,
      props,
      modalBase,
      stateModal,
      salvar,
      verificarAlteracaoEmpresa,
      apresentarRetornoRequisicao,
      preparaTela,
      EPermissaoDados,
      ETempoNotificacaoAgendamento,
      ENavegacaoRegistro,
      adicionarComentario,
      editarComentario,
      excluirComentario,
      cancelarEdicaoComentario,
      UtilitarioGeral,
      UtilitarioData,
      obtemDadosUsuario,
      definirDataHoraConclusao,
      defineDataHorarioNotificacao,
      defineTempoNotificacao,
      adicionaParticipantesSelecionados,
      verificaDataMinimaRepeticao,
      obterAgendamento,
      confirmaExclusao,
    };
  },
});
