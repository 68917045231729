
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import storeSistema from '@/store/storeSistema';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import MinhasTarefas from '@/components/MeuSistema/MinhaDashboard/MinhasTarefas/MinhasTarefas.vue';
import Noticias from '@/components/MeuSistema/MinhaDashboard/Noticias.vue';
import WidgetsUsuario from '@/components/MeuSistema/MinhaDashboard/Widgets/WidgetsUsuario/WidgetsUsuario.vue';
import ServicoConfiguracaoInicial from '@/servicos/MeuSistema/ServicoConfiguracaoInicial';
import router from '@/router';

export default defineComponent({
  name: 'MinhaDashboard',
  components: {
    MinhasTarefas,
    Noticias,
    WidgetsUsuario,
  },
  setup() {
    const { telaBase } = useTelaBase();
    const servicoConfiguracaoInicial = new ServicoConfiguracaoInicial();

    const state = reactive({
      nomeUsuario: '',
    });

    async function verificarConfiguracaoInicial() {
      const configuracao = await servicoConfiguracaoInicial.obterConfiguracaoInicial(storeSistema.getters.codigoEmpresaOperacao());
      if (!configuracao.concluido) {
        router.push({ name: 'ConfiguracaoInicial' });
      }
    }

    onBeforeMount(async () => {
      state.nomeUsuario = storeSistema.getters.nomeUsuarioAutenticado();
      verificarConfiguracaoInicial();
    });

    return {
      state,
      telaBase,
      storeSistema,
      UtilitarioData,
      UtilitarioGeral,
    };
  },
});
